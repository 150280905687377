import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { User } from "../../../Types/Entites/User";
import axios from "axios";
import { UserDataType } from "../../../Types/Forms/UserDataType";

export const findVendeurs = createAsyncThunk(
  "user/findvendeurs",
  async (idOrganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`user/findvendeurs/${idOrganisation}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findvendeursBySocieteApi = createAsyncThunk(
  "user/findvendeursBySocieteApi",
  async (idsociete: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`user/findvendeursBySocieteApi/${idsociete}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
const initialState: User[] = [];

export const vendeurSlice = createSlice({
  name: "vendeurs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findVendeurs.fulfilled, (state, action) => {
      return (state = action.payload);
    });
    builder.addCase(findvendeursBySocieteApi.fulfilled, (state, action) => {
      console.log(action.payload)
      return (state = action.payload);
    });
  },
  
});

export const Vendeurs = (state: RootState) => state.vendeurs;

export default vendeurSlice.reducer;
