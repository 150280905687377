import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Filtre from "./Filtre";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { Spinner } from "react-bootstrap";
import { stockfilterApi } from "../../_App/Redux/Slices/article/articleSlice";

export default function StockTableArticle({
  t,
  listDepots,
  stock,
  stock_Organisation,
  index,
  setindex,
  liststock,
  handleSearch,
  setkeyword,
  spinner,
  setspinner,
  setdatafilter,
  dispatch
}: any) {
  const [Filtredlist_article, setFiltredlist_article] = useState<any[]>([]);
  const [list_article, setlist_article] = useState<any[]>([]);

  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);

  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  interface SelectionsState {
    [key: string]: any[];
  }
  const [selections, setSelections] = useState<SelectionsState>({});
  const list_organisation = useAppSelector(SelectedOrganisations);
  useEffect(() => {
    if (stock) {
      let nouveauTableau =stock? stock.map((element: any) =>element ?element["article_id"] :null):[];
      function removeDuplicates(objects: any[]) {
        let seen = new Set();
        return objects.filter((obj) => {
          if (seen.has(obj?._id)) {
            return false;
          }
          seen.add(obj?._id);
          return true;
        });
      }

      // Appeler la fonction pour supprimer les doublons
      if (nouveauTableau.length !== 0) {
        let objectsWithoutDuplicates = removeDuplicates(nouveauTableau);

        setFiltredlist_article(objectsWithoutDuplicates);
        setlist_article(objectsWithoutDuplicates);
      }
    }
  }, [stock]);


  const searchfiltre = () => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
     const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: 1,
    };
    setindex(1);
    setdatafilter(data);

    dispatch(stockfilterApi(data));
  };
  return (
    <div>
      <div className="row layout-spacing">
        <Filtre
          spinner={spinner}
          handleSearch={handleSearch}
          setkeyword={setkeyword}
          t={t}
          setspinner={setspinner}
          setdatafilter={setdatafilter}
          setindex={setindex}
          searchfiltre={searchfiltre}
          selectedCarte={selectedCarte}
          setSelectedCarte={setSelectedCarte}
          selectedCategorie ={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          selectedSousCategorie={selectedSousCategorie}
          setSelectedSousCategorie={setSelectedSousCategorie}
          categoriesOptions={categoriesOptions}
          setcategoriesdOptions={setcategoriesdOptions}
          souscategoriesOptions={souscategoriesOptions}
          setsouscategoriesdOptions={setsouscategoriesdOptions}
          selections={selections}
          setSelections={setSelections}
        />
      </div>

      <div className="row layout-spacing">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th></th>
                <th></th>
                <th
                  style={{
                    borderRight: "1px solid black",
                  }}
                ></th>

                {list_organisation &&
                  list_organisation?.map((item, index) => (
                    <>
                      <th
                        className="text-center "
                        colSpan={listDepots[item._id]?.length}
                      >
                        {
                          list_organisation?.find((org) => org._id === item._id)
                            ?.nom
                        }
                      </th>

                      <th
                        style={{
                          borderRight: "1px solid black",
                        }}
                      ></th>
                    </>
                  ))}
                <th>{t("societe")}</th>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <strong>{t("Article")}</strong>
                  </td>

                  <td
                    style={{
                      borderRight: "1px solid black",
                    }}
                  >
                    <strong>{t("Prix")}</strong>
                  </td>

                  {list_organisation?.map((item, index) => (
                    <>
                      {listDepots[item._id] ? (
                        listDepots[item._id]?.map((dep: any) => (
                          <td key={dep._id}>{dep.libelle}</td>
                        ))
                      ) : (
                        <td></td>
                      )}
                      <td
                        style={{
                          borderRight: "1px solid black",
                        }}
                      ></td>
                    </>
                  ))}
                </tr>
                {spinner ? (
               <tr>
               {" "}
               <Spinner animation="border" />
             </tr>
              ) : (
                stock!==null &&stock.map((itemdata: any, dataIndex: any) => (
                  <tr key={dataIndex}>
                    <td>
                      {itemdata?.article?.image &&
                      itemdata?.article?.image !== null &&
                      itemdata?.article?.image?.length !== 0 ? (
                        <>
                          <Zoom>
                            <img
                              alt="image"
                              src={
                                process.env.REACT_APP_API_PUBLIC_URL +
                                "article/" +
                                itemdata?.article.image
                              }
                              width="30"
                              height="30"
                            />
                          </Zoom>
                        </>
                      ) : (
                        <>
                          <img
                            src="assets/assets/img/tshirt.png"
                            className="card-img-top"
                            alt="..."
                            style={{ width: "50px", height: "30px" }}
                          />
                        </>
                      )}
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>
                        {itemdata?.article?.titre?.fr}
                      </p>
                    </td>
                    <td
                      style={{
                        borderRight: "1px solid black",
                      }}
                    >
                      {itemdata?.article?.prix_vente_ttc}
                    </td>
                    {list_organisation?.map((itemdepo, index) => (
                      <React.Fragment key={index}>
                        {listDepots[itemdepo._id] ? (
                          listDepots[itemdepo._id]?.map(
                            (dep: any, index: any) => (
                              <React.Fragment key={index}>
                                <td>
                                  {itemdata?.stock[itemdepo._id] !== undefined
                                    ? itemdata?.stock[itemdepo._id].find(
                                        (e: any) => e.depots_id._id === dep._id
                                      ) !== undefined
                                      ? itemdata?.stock[itemdepo._id].find(
                                          (e: any) =>
                                            e.depots_id._id === dep._id
                                        )?.stock
                                      : "0"
                                    : "0"}
                                </td>
                              </React.Fragment>
                            )
                          )
                        ) : (
                          <td></td>
                        )}
                        <td
                          style={{
                            borderRight: "1px solid black",
                          }}
                        ></td>
                      </React.Fragment>
                    ))}
                  </tr>
                )))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      
    </div>
  );
}
/*   <td key={key}> {/* Ensure each cell has a unique key */
//{stock.find((st: any) => (
//  st.depots_id._id === key && // Check if depots_id matches the current key
//st.article_id !== null && // Ensure article_id is not null
// st.article_id._id === item.article_id._id// Check if article_id matches the specific ID
//))?.stock || key}
//</td>*/
/*          {lists.map((item , indexl)=> (
    <tr key={indexl}> 
    <td>{item.article._id}</td>
    {list_depots.map((element:any , index:number) => (
        <td key={index}>{item[element]}</td>
    ))}
</tr>
))}*/
