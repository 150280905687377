import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { ProductCaracteristiqueSchema } from "../../../_App/Schemas/ProductCaracteristiqueSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  createProductsCaracteristiquesApi,
  updateProductsCaracteristiquesApi,
} from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ProductCaracteristiqueType } from "../../../_App/Types/Entites/ProductCaracteristiqueType";

export default function ProductCaracteristiquesForm({
  ShowModal,
  setShowModal,
  ProductCaracteristique,
  organisation_id,
  societe,
}: any) {
  const dispatch = useAppDispatch();
  const [selectedOrganisation, setselectedOrganisation] = useState(true);
  const [listvalue, setlistvalue] = useState<any[]>([]);
  const [couleur, setcouleur] = useState(false);
  const [organisation, setorganisation] = useState(null);
  // init form hooks
  const formOptions = {
    resolver: yupResolver(
      ProductCaracteristiqueSchema
    ) as unknown as Resolver<ProductCaracteristiqueType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ProductCaracteristiqueType>(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (ProductCaracteristique) {
      reset({
        _id: ProductCaracteristique._id,
        organisation_id: ProductCaracteristique.organisation_id,
        libelle: ProductCaracteristique.libelle,
      });
      setlistvalue(ProductCaracteristique.valeurs);
      setcouleur(ProductCaracteristique.color);
      ProductCaracteristique.organisation_id !== null
        ? setselectedOrganisation(true)
        : setselectedOrganisation(false);
    } else {
      reset({
        libelle: "",
        societe_id: societe,
      });
      setlistvalue([]);
    }
  }, [ProductCaracteristique]);

  const onSubmit = async (data: any) => {
    let response;
    if (selectedOrganisation) {
      data.organisation_id = organisation;
    } else {
      data.organisation_id = null;
    }
    if (societe) {
      data.societe_id = societe;
    }
    data.valeurs = listvalue;
    data.color = couleur;
    if (data._id) {
      response = await dispatch(updateProductsCaracteristiquesApi(data));
    } else {
      response = await dispatch(createProductsCaracteristiquesApi(data));
    }
    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        libelle: "",
      });
      setlistvalue([]);
      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };
  const handleAddInput = () => {
    setlistvalue((prev) => {
      const updatedList = [...prev, ""];
      return updatedList;
    });

  };

  const removeitem=(index:number)=>{
    const newListValue = [...listvalue];

    newListValue.splice(index, 1);
    
    setlistvalue(newListValue); 
   }
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-2"
      dialogClassName="modal-20w"
      backdropClassName="modal-backdrop-2"
    >
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">
          Ajouter des Caracteristiques
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
          
            <div className="col-md-6 mt-3">
            <div>
              <input
                type="radio"
                checked={couleur}
                onClick={() => setcouleur(!couleur)}
              />{" "}
              <Form.Label>couleur </Form.Label>
            </div>
              <Form.Label>libelle</Form.Label>
              <div className="col-auto">
                <input
                  type="text"
                  className={`form-control mb-2 ${
                    errors?.libelle ? "is-invalid" : ""
                  }`}
                  {...register("libelle")}
                />
                <div className="invalid-feedback">
                  {errors?.libelle &&
                    errors.libelle.message &&
                    errors.libelle.message.toString()}
                </div>{" "}
              </div>
            </div>
            <div className="col-md-6">
            
              {listvalue?.map((value, index) => (
                <div className="mt-3">
              <Form.Label><i onClick={()=>removeitem(index)}  style={{color:'red'}}className="fas fa-trash-alt"></i>{` Valeur ${index + 1}`}</Form.Label>
                  {!couleur ? (
                    <input
                      type="text"
                      className={`form-control mb-2 ${
                        errors?.libelle ? "is-invalid" : ""
                      }`}
                      value={value}
                      onChange={(e) =>
                        setlistvalue((prev) => {
                          const updatedList = [...prev];
                          updatedList[index] = e.target.value;
                          return updatedList;
                        })
                      }
                    />
                  ) : (
                    <>
                      <input
                        type="color"
                        className={`form-control mb-2 ${
                          errors?.libelle ? "is-invalid" : ""
                        }`}
                        value={value.valeur}
                        onChange={(e) =>
                          setlistvalue((prev) => {
                            const updatedList = [...prev];
                            updatedList[index] = {
                              ...updatedList[index],
                              valeur: e.target.value,
                            };
                            return updatedList;
                          })
                        }
                      />
                      <input
                        type="text"
                        className={`form-control mb-2 ${
                          errors?.libelle ? "is-invalid" : ""
                        }`}
                        value={value.libelle}
                        onChange={(e) =>
                          setlistvalue((prev) => {
                            const updatedList = [...prev];
                            updatedList[index] = {
                              ...updatedList[index],
                              libelle: e.target.value,
                            };
                            return updatedList;
                          })
                        }
                      />
                    </>
                  )}
                </div>
              ))}
                <span className="c-primary float-right" onClick={handleAddInput}>
                Ajouter des valeurs <i className="fas fa-plus fa-lg"></i>
              </span>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="n-chk">
                <label className="new-control new-radio radio-classic-primary">
                  <input
                    type="radio"
                    checked={selectedOrganisation}
                    onChange={(e) => {
                      setselectedOrganisation(true);
                      setorganisation(organisation_id);
                    }}
                    value="connectedOnly"
                    className="new-control-input"
                    name="custom-radio-2"
                  />
                  <span className="new-control-indicator" />
                  <p className="fs-15">{"l'organisation connecté seulement"}</p>
                </label>
              </div>
              <div className="n-chk">
                <label className="new-control new-radio radio-classic-primary">
                  <input
                    type="radio"
                    checked={!selectedOrganisation}
                    onChange={(e) => {
                      setselectedOrganisation(false);
                      setorganisation(null);
                    }}
                    value="allOrganizations"
                    className="new-control-input"
                    name="custom-radio-2"
                  />
                  <span className="new-control-indicator" />
                  <p className="fs-15">
                    {"Tous les organisations de la societe"}
                  </p>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => setShowModal(false)}
            className="btn btn-primary-app"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
