import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { ProductMarqueType } from "../../../Types/Entites/ProductMarqueType";
import _ from "lodash";

/**
 * Get list of products marques.
 * @public
 */
export const findProductsMarquesApi = createAsyncThunk(
  "productmarque/findProductsMarquesApi",
  async (idOrgansation: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productmarque/findProductsMarquesApi/${idOrgansation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of products marques of one societe
 * @public
 */
export const findProductsMarquesApiBySociete = createAsyncThunk(
  "productmarque/findProductsMarquesApiBySociete",
  async (idsociete: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productmarque/findProductsMarquesApiBySociete/${idsociete}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Add New PRODUCTS_MARQUE.
 * @private with autorisation {CAN_CREATE_PRODUCTS_MARQUE}
 */
export const createProductMarqueApi = createAsyncThunk(
  "productmarque/createProductMarqueApi",
  async (data: ProductMarqueType, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { libelle, organisation_id, image, societe_id } = data;
      var body = new FormData();
      const organisation =
        organisation_id !== null ? organisation_id.toString() : organisation_id;
      body.append("organisation_id", organisation.toString());

      body.append("libelle", JSON.stringify(libelle));
      body.append("societe_id", societe_id.toString());

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }
      const response = await axios.post(
        "productmarque/createProductMarqueApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Update  PRODUCTS_MARQUE.
 * @private with autorisation {CAN_UPDATE_PRODUCTS_MARQUE}
 */
export const updateProductMarqueApi = createAsyncThunk(
  "productmarque/updateProductMarqueApi",
  async (data: ProductMarqueType, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { libelle, organisation_id, image, _id, societe_id } = data;
      var body = new FormData();
      body.append("_method", "put");
      body.append("_id", _id.toString());
      body.append("libelle", JSON.stringify(libelle));
      body.append("societe_id", societe_id.toString());
      const organisation =
        organisation_id !== null ? organisation_id.toString() : organisation_id;
      body.append("organisation_id", organisation.toString());

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }

      const response = await axios.put(
        "productmarque/updateProductMarqueApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Delete  PRODUCTS_MARQUE.
 * @private with autorisation {CAN_DELETE_PRODUCTS_MARQUE}
 */
export const deleteProductMarqueApi = createAsyncThunk(
  "productmarque/deleteProductMarqueApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `productmarque/deleteProductMarqueApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface MarqueState {
  listMarque: ProductMarqueType[];
  listMarqueParOrganisation: { [organisation: string]: ProductMarqueType[] };
}

const initialState: MarqueState = {
  listMarque: [],
  listMarqueParOrganisation: {},
};

export const ProductMarqueSlice = createSlice({
  name: "productmarque",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findProductsMarquesApi.fulfilled, (state, action) => {
      return (state = action.payload);
    });

    builder.addCase(createProductMarqueApi.fulfilled, (state, action) => {
      state.listMarque.splice(0, 0, action.payload.data);
      const marqueOrganisationNotNull = state.listMarque.filter(
        (item: any) => item.organisation_id !== null
      );
      const groupedByOrganisation = _.groupBy(
        marqueOrganisationNotNull,
        "organisation_id"
      );

      state.listMarque = action.payload;
      state.listMarqueParOrganisation = groupedByOrganisation;
    });

    builder.addCase(updateProductMarqueApi.fulfilled, (state, action) => {
      const updated_marque = action.payload.data;
      var index = _.findIndex(state.listMarque, { _id: updated_marque.id });
      // Replace item at index using native splice
      state.listMarque.splice(index, 1, updated_marque);
    });

    builder.addCase(deleteProductMarqueApi.fulfilled, (state, action) => {
      const deleted_marque = action.payload.data;
      state.listMarque = _.remove(state.listMarque, { _id: deleted_marque.id });
    });
    builder.addCase(
      findProductsMarquesApiBySociete.fulfilled,
      (state, action) => {
        const marqueOrganisationNotNull = action.payload.filter(
          (item: any) => item.organisation_id !== null
        );
        const groupedByOrganisation = _.groupBy(
          marqueOrganisationNotNull,
          "organisation_id"
        );

        state.listMarque = action.payload;
        state.listMarqueParOrganisation = groupedByOrganisation;
      }
    );
  },
});

// export const {  } = marqueSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const ListProductMarques = (state: RootState) =>
  state.productmarques.listMarque;
export const ListMarqueParOrganisation = (state: RootState) =>
  state.productmarques.listMarqueParOrganisation;

export default ProductMarqueSlice.reducer;
