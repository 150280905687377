import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  ListSousCategories,
  findSousCategorieBySocieteApi,
} from "../../../_App/Redux/Slices/souscategories/souscategorieSlice";
import { findProductsCaracteristiquesApiBySociete } from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import {
  findCarteBySocieteApi,
  ListCarteParOrganisation,
  ListCartes,
} from "../../../_App/Redux/Slices/cartes/carteSlice";
import {
  ListCategories,
  findCategorieBySocieteApi,
} from "../../../_App/Redux/Slices/categories/categorieSlice";
import ProductCaracteristiquesForm from "../ProductCaracteristiques/ProductCaracteristiquesForm";
import ProductMarquesForm from "../ProductMarques/ProductMarquesForm";
import CategorieForm from "../../Categories/CategorieForm";
import SousCategorieForm from "../../SousCategories/SousCategorieForm";
import CarteForm from "../../Cartes/CarteForm";
import {
  findFourinsseurBySocieteApi,
  ListFournisseurs,
} from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import FournisseurForm from "../Fournisseur/FournisseurForm";
import { CarteType } from "../../../_App/Types/Entites/CarteType";
import { FournisseurType } from "../../../_App/Types/Entites/FournisseurType";
import {
  SelectedOrganisationId,
  SelectedOrganisations,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  findProductsMarquesApiBySociete,
  ListMarqueParOrganisation,
  ListProductMarques,
} from "../../../_App/Redux/Slices/product_marque/product_marque";
import { ProductMarqueType } from "../../../_App/Types/Entites/ProductMarqueType";
import { ArticleType } from "../../../_App/Types/Entites/ArticleType";
import ProductFormCaracteristiquesItem from "../Products/ProductsForm/ProductFormCaracteristiquesItem";
import { ArticleSchema } from "../../../_App/Schemas/ArticleSchema";
import {
  createArticleApi,
  createListArticleApi,
  updateArticleApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import * as XLSX from "xlsx";
import {
  ListProduits,
  ListProduitsParOrganisation,
  findProductBySocieteApi,
} from "../../../_App/Redux/Slices/produits/produitSlice";
import { Produit } from "../../../_App/Types/Entites/Produit";
import {
  ListDepots,
  findDepotsBySocieteApi,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import ProduitReferenceForm from "./ProduitReferenceForm";
import {
  calculHt,
  calculTTc,
  handleKeyPress,
} from "../../../_App/Helpers/helpers";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import {
  ListTaxTva,
  findTaxTvaApi,
} from "../../../_App/Redux/Slices/tax/taxSlice";
import {
  findSoucheArticleApi,
  souchearticle,
} from "../../../_App/Redux/Slices/souche/soucheSlice";

interface ProdcutFormType {
  ShowModal: any;
  setShowModal: any;
  Article: any;
}
export default function ArticleForm({
  ShowModal,
  setShowModal,
  Article,
}: ProdcutFormType) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  //init state
  const [produitrefModal, setproduitrefModal] = useState(false);
  const [barcodeInputs, setBarcodeInputs] = useState<any[]>([]);
  const [updateall, setupdateall] = useState(true);

  const [idcarte, setidcarte] = useState("");
  const [idcategorie, setidcategorie] = useState("");
  const [selectedOrganisation, setselectedOrganisation] = useState(true);
  const [ShowModalProductCaracteristique, setShowModalProductCaracteristique] =
    useState(false);
  const [ShowModalProductMarque, setShowModalProductMarque] = useState(false);
  const [showModalCategorie, setShowModalCategorie] = useState(false);
  const [showModalSousCategorie, setShowModalSousCategorie] = useState(false);
  const [showModalFournisseur, setshowModalFournisseur] = useState(false);
  const [showModalCarte, setShowModalCarte] = useState(false);

  /****state lists */
  const [organisation, setorganisation] = useState("");

  const [list_cartes, setlist_cartes] = useState<CarteType[]>([]);
  const [list_marque, setlist_marque] = useState<ProductMarqueType[]>([]);
  const [list_fournisseur, setlist_fournisseur] = useState<FournisseurType[]>(
    []
  );
  const [indexcat, setindexindexcat] = useState(0);

  const [eanValue, setEanValue] = useState("");
  const organisation_id = useAppSelector(SelectedOrganisationId) || "";
  const societe = useAppSelector(societeId);

  const dispatch = useAppDispatch();

  // useAppSelector
  const soucheArticle = useAppSelector(souchearticle);
  const listOrgaisations = useAppSelector(SelectedOrganisations);
  const ListProductMarque = useAppSelector(ListProductMarques);
  const ListCarte = useAppSelector(ListCartes);
  const ListCategorie = useAppSelector(ListCategories);
  const listSousCategories = useAppSelector(ListSousCategories);
  const listtaxtva = useAppSelector(ListTaxTva);

  const ListFournisseur = useAppSelector(ListFournisseurs);
  const listCarteParOrganisation = useAppSelector(ListCarteParOrganisation);
  const listProductParOrganisaton = useAppSelector(ListProduitsParOrganisation);
  const listMarqueParOrganisation = useAppSelector(ListMarqueParOrganisation);

  // inti form
  const formOptions = {
    resolver: yupResolver(ArticleSchema) as unknown as Resolver<ArticleType>,
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState,
  } = useForm<ArticleType>(formOptions);
  const { errors } = formState;
  useEffect(() => {
    if (Article) {
      setidcarte(Article.carte?._id);
      setidcategorie(Article.categorie?._id);
      setEanValue(Article.code_a_barre[0]);
      setBarcodeInputs(Article.code_a_barre.slice(1));
      reset({
        _id: Article._id,
        produit_ref: Article.produit_ref,
        organisation_id: Article.organisation_id,
        titre: {
          fr: Article.titre?.fr,
          en: Article.titre?.en,
          ar: Article.titre?.ar,
        },
        description: {
          fr: Article.description?.fr,
          en: Article.description?.en,
          ar: Article.description?.ar,
        },
        code_a_barre: [Article.code_a_barre[0]],
        caracteristiques: Article.caracteristiques,
        carte: Article.carte !== null ? Article.carte : "null",
        categorie: Article.categorie !== null ? Article.categorie : "null",
        souscategorie:
          Article.souscategorie !== null ? Article.souscategorie: "null",
        prix_vente_ttc: Article.prix_vente_ttc,
        prix_vente_ht: Article.prix_vente_ht,
        marque: Article.marque !== null ? Article.marque : "null",
        code_article: Article.code_article,
        tva:Article.tva
      });

      Article.organisation_id !== null
        ? setselectedOrganisation(true)
        : setselectedOrganisation(false);
      setorganisation(Article.organisation_id);
    } else {
      setBarcodeInputs([]);
      setselectedOrganisation(true);
      reset({
        produit_ref: "",
        organisation_id: "",
        titre: {
          fr: "",
          en: "",
          ar: "",
        },
        description: {
          fr: "",
          en: "",
          ar: "",
        },
        code_a_barre: [],
        caracteristiques: [],
        code_article: `A_${String(soucheArticle?.souche).padStart(5, "0")}`,
        carte: "",
        categorie: "",
        souscategorie: "",
        prix_vente_ttc: 0,
        prix_vente_ht: 0,
        marque: "",
      });
      setorganisation(organisation_id);
    }
  }, [Article, ShowModal, reset]);
  useEffect(() => {
    if (societe) {
      dispatch(findProductsMarquesApiBySociete(societe));
      dispatch(findProductsCaracteristiquesApiBySociete(societe));
      dispatch(findCarteBySocieteApi(societe));
      dispatch(findTaxTvaApi(societe));
      dispatch(findFourinsseurBySocieteApi(societe));
      const datasc = { societe: societe, index: indexcat };
      dispatch(findSousCategorieBySocieteApi(datasc));
      dispatch(findCategorieBySocieteApi(societe));
      dispatch(findDepotsBySocieteApi(societe));
      dispatch(findSoucheArticleApi(societe));
    }
  }, [dispatch, societe]);


  //useEffect
   /* useEffect(() => {
  if (organisation === "null" || organisation === null) {
      setlist_cartes(ListCarte);
      setlist_fournisseur(ListFournisseur);
      setlist_marque(ListProductMarque);
    } 
    else if (organisation && organisation !== "null" && organisation !== null) {
      const carteslist = listCarteParOrganisation[Article?.organisation_id];
      carteslist ? setlist_cartes(carteslist) : setlist_cartes([]);

      const Marquelist = listMarqueParOrganisation[organisation];
      Marquelist ? setlist_marque(Marquelist) : setlist_marque([]);
    }
     
  }, [ShowModal, selectedOrganisation, organisation]);*/

  useEffect(() => {
    /* if (organisation === "null" || organisation === null) {
       setlist_cartes(ListCarte);
       setlist_fournisseur(ListFournisseur);
       setlist_marque(ListProductMarque);
     } 
     else if (organisation && organisation !== "null" && organisation !== null) {
       const carteslist = listCarteParOrganisation[Article?.organisation_id];
       carteslist ? setlist_cartes(carteslist) : setlist_cartes([]);
 
       const Marquelist = listMarqueParOrganisation[organisation];
       Marquelist ? setlist_marque(Marquelist) : setlist_marque([]);
     }*/
       setlist_cartes(ListCarte);
       setlist_fournisseur(ListFournisseur);
       setlist_marque(ListProductMarque);
   }, [ListCarte, ListFournisseur, ListProductMarque]);

  const showsouscategories = async (value: any) => {
    if (value === "more") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societe,
          index: indexcat + 12,
        })
      );
      setindexindexcat(indexcat + 12);
    } else if (value === "up") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societe,
          index: indexcat - 12,
        })
      );
      setindexindexcat(indexcat + 12);
    } else {
      setValue(`souscategorie`, value);
      setIsOpen(false);
    }
  };

  const verifcationMsg = () => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: `${t("vous voulez")} ${
        Article?._id ? t("modifier") : t("enregistrer")
      } ${t("ce article")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  function isValidJSON(input:any) {
    try {
        JSON.parse(input);
        return true; // Si l'analyse réussit, c'est un JSON valide
    } catch (e) {
        return false; // En cas d'erreur, ce n'est pas un JSON valide
    }
}

  const onSubmit = async (data: any) => {
    if (await verifcationMsg()) {
      const newdata = { ...data };
      const newcaracteristiques = newdata.caracteristiques.filter(
        (item: any) =>
          item.value !== 0 && item.value !== "" && item.value !== "0"
      ).map((car:any)=>{
        return {...car , value: isValidJSON(car.value) ? JSON.parse(car.value): car.value}
      });

      newdata.caracteristiques = newcaracteristiques;

      newdata.organisation_id = selectedOrganisation ? organisation_id : null;
      newdata.souscategorie =    (newdata.souscategorie !== null && newdata.souscategorie !== 'null' ) ?  newdata.souscategorie._id:null
      newdata.produit_ref =
        newdata.produit_ref !== ""
          ? typeof newdata.produit_ref === "string"
            ? newdata.produit_ref
            : newdata.produit_ref._id
          : null;

      newdata.societe_id = societe;
      let response;
      const barrecodelist = barcodeInputs.filter((item) => item !== "");
      newdata.code_a_barre = newdata.code_a_barre.concat(barrecodelist);
      newdata.caracteristiques = newdata.caracteristiques.map((obj: any) => ({
        caracteristique_id: obj.caracteristique_id,
        value: obj.value,
      }));

     if (data?._id) {
        newdata.changeall = newdata.type === "fils" ? false : updateall;

        response = await dispatch(updateArticleApi(newdata));
      } else {
        newdata.type = "fils";

        response = await dispatch(createArticleApi(newdata));
      }

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          produit_ref: "",
          organisation_id: "",
          titre: {
            fr: "",
            en: "",
            ar: "",
          },
          description: {
            fr: "",
            en: "",
            ar: "",
          },
          code_a_barre: [],
          caracteristiques: [],
          carte: "",
          categorie: "",
          souscategorie: "",
          prix_vente_ttc: 0,
          prix_vente_ht: 0,
          marque: "",
        });
        setShowModal(false);
        window.location.reload();
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };

  const generateRandomEAN = (length: any) => {
    const randomDigits = Array.from({ length }, () =>
      Math.floor(Math.random() * 10)
    ).join("");
    return calculateEANCheckDigit(randomDigits, length);
  };

  const calculateEANCheckDigit = (digits: any, length: any) => {
    if (digits.length !== length) {
      throw new Error(`Invalid number of digits for EAN-${length}`);
    }

    let sum = 0;
    for (let i = 0; i < digits.length; i++) {
      const digit = parseInt(digits[i], 10);
      sum += i % 2 === 0 ? digit : digit * 3;
    }

    const checkDigit = (10 - (sum % 10)) % 10;

    return `${digits}${checkDigit}`;
  };
  const handleIconClick = (index: number) => {
    const randomEAN = generateRandomEAN(12);
    if (index === -1) {
      const code = String(soucheArticle?.souche).padStart(12, "0");
      setValue("code_a_barre", [code]);
      setEanValue(code);
    } else {
      setBarcodeInputs((prev) => {
        const updatedInputs = [...prev];
        updatedInputs[index] = randomEAN;
        return updatedInputs;
      });
    }
  };

  const handleInputChange = (e: any) => {
    setEanValue(e.target.value);
    setValue("code_a_barre", [e.target.value]);
  };
  const addBarcodeInput = () => {
    setBarcodeInputs([...barcodeInputs, ""]);
  };
  const addCodeAbarre = (valeur: string, index: number) => {
    setBarcodeInputs((prev) => {
      const updatedInputs = [...prev];
      updatedInputs[index] = valeur;
      return updatedInputs;
    });
  };
  const changeprice = (type: string, valeur: any) => {
    const tva_value =  getValues("tva");

    if (tva_value) {
      if (type === "ttc") {
        const new_HT = calculHt(parseFloat(valeur), parseFloat(tva_value));
        setValue("prix_vente_ht", parseFloat(new_HT.toFixed(3)));
      } else if (type === "ht") {
        const new_TT = calculTTc(parseFloat(valeur),parseFloat(tva_value) );
        setValue("prix_vente_ttc", parseFloat(new_TT.toFixed(3)));
      } else if (type === "tva") {
        setValue('tva',parseFloat(valeur))
        const new_TT = calculTTc(getValues("prix_vente_ht"), parseFloat(valeur));

        setValue("prix_vente_ttc", parseFloat(new_TT.toFixed(3)));
      }
    }
  };

  return (
    <>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-70w"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">
            <div className="row">
              <div className="col-md-12"> {t("Ajouter Article")}</div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="container mt-4">
              <div className="row mb-4">
                <div className="col-md-4 ">
                  <div className="form-group ">
                    <label htmlFor="titre.fr">
                      <span style={{ color: "red" }}>*</span>
                      {t("Titre")}{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="titre fançais"
                      className={`form-control ${
                        errors?.titre?.fr ? "is-invalid" : ""
                      }`}
                      {...register("titre.fr")}
                    />

                    <div className="invalid-feedback">
                      {errors.titre?.fr &&
                        errors.titre.fr.message &&
                        errors.titre.fr.message.toString()}
                    </div>

                    <label htmlFor="description.fr">
                      <span style={{ color: "red" }}>*</span>
                      {t("Description")}{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="description fançais"
                      className={`form-control ${
                        errors?.description?.fr ? "is-invalid" : ""
                      }`}
                      {...register("description.fr")}
                    />
                    <div className="invalid-feedback">
                      {errors.description?.fr &&
                        errors.description.fr.message &&
                        errors.description.fr.message.toString()}
                    </div>
                  </div>
                </div>

                <div className="col-md-4  ">
                  <div className="form-group ">
                    <label htmlFor="article_code_a_barre">
                      {t("Code article")}{" "}
                    </label>
                    <div className="wrapper">{getValues("code_article")}</div>
                    <label htmlFor="article_code_a_barre">
                      <span style={{ color: "red" }}>*</span>
                      {t("Code a barre article")}{" "}
                    </label>
                    <div className="wrapper">
                      <div
                        className="icon-input"
                        onClick={() => handleIconClick(-1)}
                      >
                        {" "}
                        <i className="fas fa-barcode mr-2"></i>
                      </div>

                      <input
                        type="text"
                        className={` input form-control ${
                          errors?.code_a_barre ? "is-invalid" : ""
                        }`}
                        value={eanValue}
                        onChange={handleInputChange}
                        name="code_a_barre"
                      />

                      <div className="invalid-feedback">
                        {errors.code_a_barre &&
                          errors.code_a_barre.message &&
                          errors.code_a_barre.message.toString()}
                      </div>
                    </div>
                    <div>
                      {barcodeInputs.map((input, index) => (
                        <div key={index}>
                          <div className="wrapper">
                            <div
                              className="icon-input"
                              onClick={() => handleIconClick(index)}
                            >
                              {" "}
                              <i className="fas fa-barcode mr-2"></i>
                            </div>

                            <input
                              type="text"
                              className={` input form-control ${
                                errors?.code_a_barre ? "is-invalid" : ""
                              }`}
                              value={barcodeInputs[index]}
                              onChange={(e) =>
                                addCodeAbarre(e.target.value, index)
                              }
                              name="code_a_barre"
                            />

                            <div className="invalid-feedback">
                              {errors.code_a_barre &&
                                errors.code_a_barre.message &&
                                errors.code_a_barre.message.toString()}
                            </div>
                          </div>
                        </div>
                      ))}
                      <span
                        className="c-primary pointer mb-5"
                        onClick={addBarcodeInput}
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>{" "}
                        {t("AJOUTER CODE A BARRE")}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4  ">
                  <div className="form-group ">
                    <label htmlFor="organisation_id">
                      <span style={{ color: "red" }}></span>
                      {t("Organisation")}
                    </label>
                    {selectedOrganisation ? (
                      <select
                        id="organisation_id"
                        className={`form-control ${
                          errors?.organisation_id ? "is-invalid" : ""
                        }`}
                        {...register("organisation_id")}
                        onChange={(e) => setorganisation(e.target.value)}
                        value={organisation}
                      >
                        {listOrgaisations?.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.nom}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        id="organisation_id"
                        className="form-control mb-2"
                        {...register("organisation_id")}
                      >
                        <option value="null">
                          {t("Tout les organisations")}
                        </option>
                      </select>
                    )}
                    <div className="n-chk mt-3">
                      <label className="new-control new-radio radio-classic-primary">
                        <input
                          type="radio"
                          checked={selectedOrganisation}
                          onChange={(e) => {
                            setselectedOrganisation(true);
                            setorganisation(organisation_id);
                          }}
                          value="connectedOnly"
                          className="new-control-input"
                          name="custom-radio-2"
                        />
                        <span className="new-control-indicator" />
                        <p className="fs-15">
                          {t("l'organisation connecté seulement")}
                        </p>
                      </label>
                    </div>
                    <div className="n-chk">
                      <label className="new-control new-radio radio-classic-primary">
                        <input
                          type="radio"
                          checked={!selectedOrganisation}
                          onChange={(e) => {
                            setselectedOrganisation(false);
                            setorganisation("null");
                          }}
                          value="allOrganizations"
                          className="new-control-input"
                          name="custom-radio-2"
                        />
                        <span className="new-control-indicator" />
                        <p className="fs-15">
                          {t("Tous les organisations de la societe")}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-4 ">
                  <div className="form-group ">
                    <label htmlFor="tva">{t("TVA")} </label>
                    <select
                      id="tva"
                      className="form-control mb-2"
                      value={watch('tva')}
                      onChange={(e) => changeprice("tva", e.target.value)}

                    >
                      {listtaxtva.map((item: any, index) => (
                        <option
                          key={index}
                          value={item.taux}
                        >
                          {item.taux}
                        </option>
                      ))}
                    </select>
                    <div>
                      <span
                        className="c-primary pointer"
                        onClick={() => setproduitrefModal(true)}
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>{" "}
                        {t("Produit reference")}{" "}
                      </span>
                      {getValues("produit_ref")?.titre?.fr}
                    </div>
                    <ProduitReferenceForm
                      societe={societe}
                      ShowModal={produitrefModal}
                      setShowModal={setproduitrefModal}
                      setValue={setValue}
                      changeprice={changeprice}
                      listtaxtva={listtaxtva}
                    />
                    <label htmlFor="image">
                      <span style={{ color: "red" }}>*</span>
                      {t("Image")}
                    </label>
                    <input
                      className={`form-control ${
                        errors?.image ? "is-invalid" : ""
                      }`}
                      type="file"
                      id="image"
                      {...register("image")}
                    />
                    <div className="invalid-feedback">
                      {errors.image &&
                        errors.image.message &&
                        errors.image.message.toString()}
                    </div>
                    <label htmlFor="prix_ttc">
                      <span style={{ color: "red" }}>*</span>
                      {t("Prix de vente TTC")}{" "}
                    </label>
                    <input
                      className={`form-control ${
                        errors?.prix_vente_ttc ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="prix_ttc"
                      {...register("prix_vente_ttc")}
                      onChange={(e) => changeprice("ttc", e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="invalid-feedback">
                      {errors.prix_vente_ttc &&
                        errors.prix_vente_ttc.message &&
                        "prix vente  TTC obligatoire"}
                    </div>
                    <label htmlFor="prix_ht">
                      <span style={{ color: "red" }}>*</span>
                      {t("Prix de vente HT")}{" "}
                    </label>
                    <input
                      className={`form-control  numberinput ${
                        errors?.prix_vente_ht ? "is-invalid" : ""
                      }`}
                      type="text"
                      id="prix_ht"
                      {...register("prix_vente_ht")}
                      onChange={(e) => changeprice("ht", e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="invalid-feedback">
                      {errors.prix_vente_ht &&
                        errors.prix_vente_ht.message &&
                        "prix vente  HT obligatoire"}
                    </div>{" "}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group ">
                    <div className="row">
                      <label htmlFor="marques">
                        {t("Marques")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalProductMarque(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <select
                      id="marque_id"
                      className="form-control mb-2"
                      {...register("marque")}
                    >
                      <option value="">{t("Select")} </option>
                      {list_marque.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.libelle.fr}
                        </option>
                      ))}
                    </select>
                    <div className="row">
                      <label htmlFor="Cartes">
                        {t("Cartes")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalCarte(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <select
                      id="carte_id"
                      className="form-control mb-2"
                      {...register("carte")}
                    >
                      <option value="">{t("Select")} </option>
                      {list_cartes.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item?.libelle?.fr}
                        </option>
                      ))}
                    </select>
                    <div className="row">
                      <label htmlFor="Categories">
                        {t("Categories")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalCategorie(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <select
                      className="form-control mb-2"
                      {...register("categorie")}
                    >
                      <option value="">{t("Select")}</option>
                      {ListCategorie?.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.libelle.fr}
                        </option>
                      ))}
                    </select>
                    <div className="row">
                      <label htmlFor="Sous Categories">
                        {t("Sous Categories")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalSousCategorie(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <div className="custom-dropdown">
                      <div
                        className="form-control"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {watch(`souscategorie`) !== null ? watch(`souscategorie`)?.libelle?.fr 
                          : "Select category"}
                      </div>
                      {isOpen && (
                        <div className="custom-dropdown-options">
                          {indexcat > 0 && (
                            <div
                              className="custom-dropdown-option"
                              onClick={() => showsouscategories("up")}
                            >
                              <i className="fas fa-arrow-up"></i>
                            </div>
                          )}
                          {listSousCategories.map((option, i) => (
                            <div
                              key={i}
                              className="custom-dropdown-option"
                              onClick={() => showsouscategories(option)}
                            >
                              {option.libelle.fr}
                            </div>
                          ))}

                          <div
                            className="custom-dropdown-option"
                            onClick={() => showsouscategories("more")}
                          >
                            <i className="fas fa-arrow-down"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group ">
                    <ProductFormCaracteristiquesItem
                      setShowModalProductCaracteristique={
                        setShowModalProductCaracteristique
                      }
                      t={t}
                      register={register}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      getValues={getValues}
                      organisation={organisation}
                      societe={societe}
                      article={Article}
                      selectedOrganisation={selectedOrganisation}
                    />
                    {Article && Article.type === "parent" ? (
                      <div>
                        <div className="n-chk">
                          <label className="new-control new-radio radio-classic-primary">
                            <input
                              type="radio"
                              checked={updateall}
                              onChange={(e) => {
                                setupdateall(true);
                              }}
                              className="new-control-input"
                            />
                            <span className="new-control-indicator" />
                            <p className="fs-15">
                              {t("Modifier ce article ainsi ses fils")}
                            </p>
                          </label>
                        </div>
                        <div className="n-chk">
                          <label className="new-control new-radio radio-classic-primary">
                            <input
                              type="radio"
                              checked={!updateall}
                              onChange={(e) => {
                                setupdateall(false);
                              }}
                              className="new-control-input"
                            />
                            <span className="new-control-indicator" />
                            <p className="fs-15">
                              {t("Modifier ce produit seulement")}
                            </p>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t("Annuler")}
            </Button>
            <Button type="submit" className="btn btn-primary-app">
              {t("Enregistrer")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <ProductCaracteristiquesForm
        ShowModal={ShowModalProductCaracteristique}
        setShowModal={setShowModalProductCaracteristique}
        ProductCaracteristique={null}
        organisation_id={organisation_id}
        societe={societe}
      />
      <ProductMarquesForm
        ShowModal={ShowModalProductMarque}
        setShowModal={setShowModalProductMarque}
        ProductMarque={null}
        organisation_id={organisation_id}
        societe={societe}
      />
      <CategorieForm
        ShowModal={showModalCategorie}
        setShowModal={setShowModalCategorie}
        categorie={null}
        carteId={idcarte}
        currentOrganisation={organisation_id}
        societe={societe}
      />
      <CarteForm
        ShowModal={showModalCarte}
        setShowModal={setShowModalCarte}
        carte={null}
        currentOrganisation={organisation_id}
        societe={societe}
      />
      <SousCategorieForm
        ShowModal={showModalSousCategorie}
        setShowModal={setShowModalSousCategorie}
        categorie={null}
        currentOrganisation={organisation_id}
        societe={societe}
      />
      <FournisseurForm
        ShowModal={showModalFournisseur}
        setShowModal={setshowModalFournisseur}
        categorie={null}
        organisation_id={organisation_id}
        societe={societe}
      />
    </>
  );
}

/* const handelgetfile = async () => {
    try {
      const action = await dispatch(findFileApi(null));

      if (findFileApi.fulfilled.match(action)) {
        const axiosResponse: AxiosResponse = action.payload;

        // Créez un Blob à partir de la réponse
        const blob = new Blob([axiosResponse.data], {
          type: axiosResponse.headers["content-type"],
        });

        // Créez un object URL pour le Blob
        const objectUrl = URL.createObjectURL(blob);

        // Ouvrez un nouvel onglet avec le PDF
        const newTab = window.open(objectUrl, "_blank");
        if (!newTab) {
          ToastWarning("Popup blocker prevented opening a new tab.");
        }
      } else if (findFileApi.rejected.match(action)) {
        // Gérez le cas où l'action est rejetée
        const error = action.payload;
        console.log(error);
        ToastWarning("error lors de traitement d action ");
      }
    } catch (error) {
      console.error(error);
    }
  };*/
/* const handleExcelImport = async (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = event?.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const importedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const header: string[] = importedData[0] as string[];
      const dataArray = importedData.slice(1);

      const formattedData = dataArray.map((dataRow: any) =>
        header.reduce((obj: any, key: string, index: number) => {
          obj[key] = dataRow[index];
          return obj;
        }, {})
      );

      const response = await dispatch(createListArticleApi(formattedData));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));

        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    };

    reader.readAsBinaryString(file);
  };*/
