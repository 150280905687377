import React from "react";
import Modal from "react-bootstrap/Modal";

export default function AchatConfirmModel({
  totalttcInput,
  totalttccalculer,
  ShowModal,
  setShowModal,
  onSubmit,
  etat,
  data,
  t
}: any) {
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-30w"
    >
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">
          {" "}
          {t('Il y a une erreur de saisie')}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{t('Le total Prix TTC Entrée')}: {totalttcInput.toFixed(3)}</p>
          <p>{t('Le total Prix TTC Calculer')} : {totalttccalculer}</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          onClick={() => setShowModal(false)}
          className="btn btn-primary-app"
        >
          {t('Annuler')}
        </button>
        {!etat && (
          <button
            onClick={() => onSubmit(data)}
            className="btn btn-primary-app"
          >
            {t('Confirmer')}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
