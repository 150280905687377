import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function Reservationpopup({
  showModal,
  setshowModal,
  t,
  listClient,
  cart,
  confirmreservation
}: any) {
  type clientreservationType = {
    nom: string;
    mobile: number;
    duree: number;
    date_echeance: any;
    client:any
  };
  const [clientreservationdata, setclientreservationdata] = useState<
    clientreservationType | undefined
  >();

  useEffect(() => {

    if (cart.client_id && showModal) {
      const foundclient = listClient.find(
        (item: any) => item._id === cart.client_id
      );
      console.log(cart.client_id)
      if (foundclient) {
        const today = new Date();
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + 7);
        setclientreservationdata({
          client:foundclient,
          nom: foundclient.defaultClient
            ? foundclient.nom
            : foundclient.nom + foundclient.prenom,
          mobile: !foundclient.defaultClient ? foundclient.mobile : "",
          duree: 7,
          date_echeance: formatDateToInputValue(futureDate),
        });
      }
    }
  }, [listClient, cart.client_id,showModal]);

  const formatDateToInputValue = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const changedata = (name: string, e: any) => {
    const { value } = e.target;
    if (name === "duree") {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + parseInt(value)||0);
      const newdate_echance = formatDateToInputValue(futureDate);
      setclientreservationdata((prev: any) => {
        if (prev) {
          return {
            ...prev,
            date_echeance: newdate_echance,
            duree: parseInt(value)||0,
          };
        } else {
          return { nom: value };
        }
      });
    } else if (name === "date_echeance") {
  
      const date1 = new Date();
      const date2 = new Date(value);
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      setclientreservationdata((prev: any) => {
        if (prev) {
          return {
            ...prev,
            date_echeance: value,
            duree:parseInt((differenceInDays+1).toString()) ,
          };
        } else {
          return { nom: value };
        }
      });
    }else{
        setclientreservationdata((prev: any) => {
            if (prev) {
              return { ...prev, [name]: value };
            } else {
              return { nom: value };
            }
          });
    }

  };
  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      animation={false}
    >
      <Modal.Header closeButton>      <Modal.Title className="c-primary">{t('Information client ')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-2">
          <strong className=" col-12">
            {t("Nom")} {t("Prenom")}
          </strong>

          <input
            type="text "
            className="form-control col-12"
            value={clientreservationdata?.nom}
            onChange={(e) => changedata("nom", e)}
          />
        </div>

        <div className="row mt-2">
          <strong className=" col-12">
            {t("Numéro de téléphone")}
          </strong>
          <input
            type="text"
            className="form-control  numberinput col-12"
            value={clientreservationdata?.mobile}
            onChange={(e) => changedata("mobile", e)}
          />
        </div>

        <div className="row mt-2">
          <strong className=" col-12">
            {t("Durée  de reservation en jours")}
          </strong>
          <input
            type="text"
            className="form-control  numberinput col-3"
            value={clientreservationdata?.duree}
            onChange={(e) => changedata("duree", e)}
          />
        </div>
        <div className="row mt-2">
          <strong className=" col-12">{t("date d'écheance")}</strong>
          <input
            type="date"
            className="form-control"
            value={
              clientreservationdata?.date_echeance
                ? clientreservationdata.date_echeance
                : formatDateToInputValue(new Date())
            }
            onChange={(e) => changedata("date_echeance", e)}
          />{" "}
        </div>
      </Modal.Body>
      <Modal.Footer>
      <button onClick={()=>confirmreservation(cart,clientreservationdata)} className="btn btn-primary-app">
                {t("Valider reservation ")}
              </button>
      </Modal.Footer>
    </Modal>
  );
}
