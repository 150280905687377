import React, { useState, useEffect } from "react";
import ProductItem from "./ProductItem";
import ProductDetailsForm from "../ProductDetails/ProductDetailsForm";
import ProductTarifsForm from "../ProductTarifs/ProductTarifsForm";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  findProductsTarifByOrganisationApi,
  ListProductTarifProduit,
} from "../../../_App/Redux/Slices/product_tarif/product_tarifSlice";
import { ProductTarifType } from "../../../_App/Types/Entites/ProductTarifType";
import { Produit } from "../../../_App/Types/Entites/Produit";
import ProductFilter from "./ProductFilter";
export default function ProductsList({
  t,
  productList,
  openEditForm,
  desactiveitem,
  activeitem,
  setindex,
  index,
  setkeyword,
  handelsearch,
  keyword,
}: any) {
  const dispatch = useAppDispatch();

  const [productselected, setProductselected] = useState("");

  const [productTarif, setProductTarif] = useState<ProductTarifType>();

  const [ShowModalProducTarif, setShowModalProducTarif] = useState(false);
  const listProductTarifProduit = useAppSelector(ListProductTarifProduit);

  return (
    <div>
      <section className="container">
        <ProductFilter 
          t={t} 
          handelsearch={handelsearch}
          keyword={keyword}
          setkeyword={setkeyword}
           />
        <div className="row mt-5">
        {productList.map((item: any) => (
          <div key={item._id} className="col-md-2 ">
          <ProductItem
              t={t}
              product={item}
              openEditForm={openEditForm}
              desactiveitem={desactiveitem}
              key={item._id}
              setShowModalProducTarif={setShowModalProducTarif}
              setProductselected={setProductselected}
              activeitem={activeitem}
            />
          </div>
          
        ))}
      </div>
      <div className="row">
              <div className="pagination-no_spacing">
                <ul className="pagination">
                  {index > 0 && (
                    <li>
                      <a onClick={() => setindex(index - 10)} className="prev">
                        {" "}
                        <i className="fas fa-chevron-left"></i>{" "}
                      </a>{" "}
                    </li>
                  )}
                  <li>
                    <a onClick={() => setindex(index + 10)} className="next">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
       
      </section>

      <ProductTarifsForm
        ShowModal={ShowModalProducTarif}
        setShowModal={setShowModalProducTarif}
        ProductTarif={productTarif}
        Product_id={productselected}
      />
    </div>
  );
}
