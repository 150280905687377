import React from "react";

const BonReductionMethod = ({
  pointsFideliteEquivalent,
  setpointsFideliteEquivalent,
}: any) => {
  return (
    <div>
      <div className="navbar-item flex-row search-ul navbar-dropdown">
        <div className="nav-item align-self-center search-animated">
          <form
            className="form-inline search-full form-inline search"
            role="search"
          >
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control ml-lg-auto"
                placeholder="Espèces"
                value={pointsFideliteEquivalent}
                readOnly
              />
              {pointsFideliteEquivalent ? (
                <button
                  onClick={() => setpointsFideliteEquivalent(0)}
                  style={{
                    position: "absolute",
                    right: "10%",

                    border: "none",
                    background: "transparent",
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BonReductionMethod;
