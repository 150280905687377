import React, { useState } from 'react'
import ApproverTransfert from '../../Components/TransfertArticles/ApproverTransfert'
import BonTransfert from '../../Components/TransfertArticles/files/BonTransfert'
import { useParams } from 'react-router-dom';

export default function PageListDemande() {
    const [ShowModal,setShowModal]=useState(true)
    const { etat } = useParams(); // Access the URL parameter
    return (
      
    <div>
      <ApproverTransfert ShowModal={ShowModal} setShowModal={setShowModal} etatini={etat}/>
    </div>
  )
}
