import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { handleKeyPress } from "../../_App/Helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  addDistributionCommissionUserApi,
  findCommissionByOrganisationApi,
  findDistributionCommissionByuserApi,
  ListCommissionUser,
} from "../../_App/Redux/Slices/commission/commissionSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function CommissionUserList({
  t,
  listcommission,
  showModal,
  setshowModal,
  user,
}: any) {
  const dispatch = useAppDispatch();
  const [payer, setpayer] = useState(false);

  const [distributioncommission, setdistributioncommission] = useState<any>();
  const [value, setvalue] = useState(0);

  const l = useAppSelector(ListCommissionUser);

  const valider = async () => {
    const new_servis = distributioncommission?.servisTotal + value;
    const newreste = distributioncommission.montantTotal - new_servis;
    const updatedDistribution = {
      ...distributioncommission,
      servisTotal: new_servis,
      reste: newreste,
    };

    if (newreste < 0) {
      ToastWarning(
        "Le montant du servis dépasse le montant total de la commission"
      );
    } else {
      const objdistribution = {
        user_id: user._id,
        montantinitiale: distributioncommission.reste,
        montantservis: value,
        reste: newreste,
      };
      setdistributioncommission(updatedDistribution);

      const response = await dispatch(
        addDistributionCommissionUserApi(objdistribution)
      );
      if (response.payload.success) {
        // setshowModal(false);
        setvalue(0);
        ToastSuccess(response.payload.message);
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };

  useEffect(() => {
    if (user && user._id) {
      dispatch(findCommissionByOrganisationApi(user._id));
    }
  }, [user]);
  useEffect(() => {
    if (l) {
      const montantTotal = l.listcommission?.reduce((acc, item) => {
        return acc + item.commission;
      }, 0);
      const servisTotal = l.distribution?.reduce((acc, item) => {
        return acc + item.montantservis;
      }, 0);

      const obj = {
        montantTotal: montantTotal,
        servisTotal: servisTotal,
        reste: montantTotal - servisTotal,
      };
      setdistributioncommission(obj);
    }
  }, [l]);
  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      className="modal-1"
      dialogClassName="modal-60w"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {" "}
          {t("List Commission   ")}
          {user?.nom} {user?.prenom}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setshowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <table className="table ">
            <thead>
              <tr>
                <th>{t("Total")}</th>
                <th>{t("montant_servis")}</th>
                <th>{t("reste")}</th>
                <th></th>
              </tr>
            </thead>
            {distributioncommission && (
              <tbody>
                <tr>
                  <td>{distributioncommission?.montantTotal}</td>

                  <td>{distributioncommission?.servisTotal}</td>
                  <td>{distributioncommission?.reste}</td>
                  {payer && (
                    <td>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => setvalue(parseFloat(e.target.value))}
                      />
                    </td>
                  )}
                </tr>

                <tr>
                  <td colSpan={4}>
                    {!payer ? (
                      <button
                        className="btn btn-primary-app"
                        onClick={() => setpayer(true)}
                      >
                        payer
                      </button>
                    ) : (
                      <button className="btn btn-primary-app" onClick={valider}>
                        valider
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className="row">
          <div className="col-5">
            <strong> Table des commissions</strong>
            <table className="table ">
              <thead>
                <tr>
                  <th>{t("Order")}</th>
                  <th>{t("Commission ")}({distributioncommission?.montantTotal})</th>

                  <th>{t('date')}</th>
                </tr>
              </thead>
              <tbody>
                {l.listcommission?.map((element: any, index: number) => (
                  <tr key={index}>
                    <td>{element?.order.order_number}</td>

                    <td>{element?.commission}</td>
                    <td>{element?.createdAt.split("T")[0]}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-6">
            {" "}
            <strong>Table des distributions</strong>
            <table className="table ">
              <thead>
                <tr>
                  <th>{t("Montant initilae")}</th>
                  <th>{t("montant servis ")}({distributioncommission?.servisTotal})</th>
                  <th>{t("reste")}</th>

                  <th>{t("date")}</th>
                </tr>
              </thead>
              <tbody>
                {l.distribution?.map((element: any, index: number) => (
                  <tr key={index}>
                    <td>{element?.montantinitiale}</td>

                    <td>{element?.montantservis}</td>
                    <td>{element?.reste}</td>
                    <td>{element?.createdAt.split("T")[0]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
