import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  listCheques,
  updateCheque,
} from "../../../_App/Redux/Slices/payments/PaymentSlice";
import { useEffect, useState } from "react";
import flatpickr from "flatpickr";
import { ChequeType } from "../../../_App/Types/Entites/PaymentType";
import { formatdate } from "../../../_App/Helpers/helpers";
import { useTranslation } from "react-i18next";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import Swal from "sweetalert2";
import TicketDeCaissePaid from "../TicketDeCaisse/TicketDeCaissePaid";
import { ReptureStockList } from "../../../_App/Redux/Slices/article/articleSlice";
import StockType from "../../../_App/Types/Entites/StockType";

const ReptureStockTable = () => {
  const [ordersFilter, setOrdersFilter] = useState("");

  const filteringOrders = (item: StockType) => {
    if (item) {
      const filterLowerCase = ordersFilter.toLowerCase();
      const searchFilter =
        (ordersFilter &&
          item.article_id.titre.fr.toLowerCase().includes(filterLowerCase)) ||
        item.article_id?.code_a_barre?.some((barcode: string) =>
          barcode.toLowerCase().includes(filterLowerCase)
        );

      return ordersFilter === "" || searchFilter;
    }
    return true;
  };

  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted with filter:", ordersFilter);
  };

  const reptures = useAppSelector(ReptureStockList);

  return (
    <>
      <div className="row layout-spacing">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="navbar-item flex-row search-ul navbar-dropdown">
              <div className="nav-item align-self-center search-animated">
                <i className="fas fa-search"></i>
                <form
                  className="form-inline search-full form-inline search"
                  role="search"
                  onSubmit={handleSubmit}
                >
                  <div className="search-bar">
                    <input
                      style={{ background: "#f1f2f3", border: "none" }}
                      type="text"
                      className="form-control search-form-control ml-lg-auto"
                      placeholder={t("search")}
                      autoFocus
                      onChange={(e) => setOrdersFilter(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div
              className="widget-content widget-content-area"
              style={{ overflowX: "auto" }}
            >
              <table id="style-2" className="table style-2  table-hover">
                <thead>
                  <tr>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("Article")}
                    </th>
                    <th style={{ fontSize: "15px" }}>{t("Stock")}</th>
                  </tr>
                </thead>
                <tbody>
                  {reptures && reptures.length
                    ? reptures.map((item) => {
                        if (filteringOrders(item)) {
                          return (
                            <tr key={item._id}>
                              <td
                                className="checkbox-column"
                                style={{ fontSize: "15px" }}
                              >
                                {item.article_id.titre.fr}
                              </td>
                              <td style={{ fontSize: "15px" }} className="text-danger">
                            
                                  {item.stock}
                              </td>
                            </tr>
                          );
                        } else return null;
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReptureStockTable;
