import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  listCheques,
  updateCheque,
} from "../../../_App/Redux/Slices/payments/PaymentSlice";
import { useEffect, useState } from "react";
import flatpickr from "flatpickr";
import { ChequeType } from "../../../_App/Types/Entites/PaymentType";
import { formatdate } from "../../../_App/Helpers/helpers";
import { useTranslation } from "react-i18next";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import Swal from "sweetalert2";
import TicketDeCaissePaid from "../TicketDeCaisse/TicketDeCaissePaid";

const ChequesTable = () => {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const navigate = useNavigate();

  const cheques = useAppSelector(listCheques);
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const [stateShow, setStateShow] = useState(false);
  const [show, setShow] = useState("");
  const [status, setStatus] = useState("all");

  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };

  useEffect(() => {
    const datepickerElement = document.getElementById(
      "datepicker"
    ) as HTMLInputElement;

    if (datepickerElement) {
      const flatpickrInstance = flatpickr(datepickerElement, {
        dateFormat: "Y-m-d",
        onChange: function (selectedDates, dateString) {
          setSelectedDate(dateString);
        },
      });
    }
  }, [dispatch, currentOrganisation]);

  const [ordersFilter, setOrdersFilter] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted with filter:", ordersFilter);
  };
  const filteringOrders = (item: ChequeType) => {
    if (item) {
      const filterLowerCase = ordersFilter.toLowerCase();
      const searchFilter =
        (ordersFilter &&
          item.num_cheque.toLowerCase().includes(filterLowerCase)) ||
        (item.client_id &&
          item.client_id?.nom?.toLowerCase().includes(filterLowerCase));

      // Apply date filter only if a date is selected
      const dateFilter =
        !selectedDate || formatdate(item.echeance).toString() === selectedDate;

      return (
        dateFilter && // Only apply date filtering if selectedDate exists
        (status === "all" ||
          (item.paid && status.toLowerCase() === "payée") ||
          (!item.paid && status.toLowerCase() === "impayé")) &&
        (ordersFilter === "" || searchFilter)
      );
    }
    return true;
  };

  const { t } = useTranslation();

  const handlePayCheque = async (data: any) => {
    console.log(JSON.stringify(data));
    Swal.fire({
      title: "Voulez vous vraiment payer ce chèque ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(updateCheque(data));
      }
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(undefined);

  const handleOpenTicket = async (item: ChequeType) => {
    setSelectedOrder(item.payment_id.order_id);
    setShowModal(true);
  };

  useEffect(() => {
    if (!showModal) setSelectedOrder(undefined);
  }, [showModal]);

  return (
    <>
      <TicketDeCaissePaid
        ShowModal={showModal}
        setShowModal={setShowModal}
        order_id={selectedOrder}
      />
      <div className="row layout-spacing">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="navbar-item flex-row search-ul navbar-dropdown">
              <div className="nav-item align-self-center search-animated">
                <i className="fas fa-search"></i>
                <form
                  className="form-inline search-full form-inline search"
                  role="search"
                  onSubmit={handleSubmit}
                >
                  <div className="search-bar">
                    <input
                      style={{ background: "#f1f2f3", border: "none" }}
                      type="text"
                      className="form-control search-form-control ml-lg-auto"
                      placeholder={t("search")}
                      autoFocus
                      onChange={(e) => setOrdersFilter(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <input
                type="text"
                className="form-control"
                style={{
                  background: "#f1f2f3",
                  border: "none",
                  width: "auto",
                  height: "auto",
                }}
                id="datepicker"
                value={t("Date") + ": " + selectedDate}
                onChange={(e) => setSelectedDate(e.currentTarget.value)}
              />{" "}
              <div
                className="nav-item dropdown language-dropdown more-dropdown"
                onClick={open}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <div
                  className={"dropdown custom-dropdown-icon " + show}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    className="dropdown-toggle btn"
                    href="#"
                    role="button"
                    id="customDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={stateShow}
                  >
                    <span> {t(status)} </span>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-down"
                    >
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </a>
                  <div
                    className={"dropdown-menu dropdown-menu-right " + show}
                    aria-labelledby="customDropdown"
                  >
                    <div
                      className="dropdown-item"
                      data-img-value="flag-de3"
                      data-value="German"
                      onClick={(e) => setStatus("all")}
                    >
                      {t("all")}
                    </div>
                    <div
                      className="dropdown-item"
                      data-img-value="flag-de3"
                      data-value="German"
                      onClick={(e) => setStatus("Payée")}
                    >
                      {t("Payé")}
                    </div>
                    <div
                      className="dropdown-item"
                      data-img-value="flag-fr3"
                      data-value="French"
                      onClick={(e) => setStatus("Impayé")}
                    >
                      {t("Impayé")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="widget-content widget-content-area"
              style={{ overflowX: "auto" }}
            >
              <table id="style-2" className="table style-2  table-hover">
                <thead>
                  <tr>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("Numéro")}
                    </th>
                    <th style={{ fontSize: "15px" }}>{t("Ticket")}</th>
                    <th style={{ fontSize: "15px" }}>{t("Montant")}</th>

                    <th style={{ fontSize: "15px" }}>{t("Client")}</th>
                    <th style={{ fontSize: "15px" }}>{t("Tél")}</th>
                    <th style={{ fontSize: "15px" }}>{t("Date d'échéance")}</th>
                    <th className="text-center" style={{ fontSize: "15px" }}>
                      {t("status")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cheques && cheques.length
                    ? cheques.map((item) => {
                        if (filteringOrders(item)) {
                          return (
                            <tr key={item._id}>
                              <td
                                className="checkbox-column"
                                style={{ fontSize: "15px" }}
                              >
                                {item.num_cheque}
                              </td>
                              <td
                                style={{ fontSize: "15px" }}
                                onClick={() => handleOpenTicket(item)}
                              >
                                <a
                                  className="link"
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.payment_id.order_id.order_number}
                                </a>{" "}
                              </td>
                              <td style={{ fontSize: "15px" }}>
                                {item.amount + " TND"}
                              </td>
                              <td style={{ fontSize: "15px" }}>
                                {item?.client_id?.nom}
                              </td>
                              <td style={{ fontSize: "15px" }}>{item.tel}</td>
                              <td style={{ fontSize: "15px" }}>
                                {item.echeance.toString().split("T")[0]}
                              </td>
                              <td
                                className="text-center"
                                style={{ fontSize: "15px" }}
                              >
                                <span
                                  className={
                                    item.paid
                                      ? "shadow-none badge badge-success"
                                      : "shadow-none badge badge-danger"
                                  }
                                  style={{
                                    width: "100%",
                                    height: "25px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (!item.paid) {
                                      handlePayCheque({
                                        id: item._id,
                                        data: { paid: true },
                                      });
                                    }
                                  }}
                                >
                                  {item.paid ? "Payé" : "Impayé"}
                                </span>
                              </td>
                            </tr>
                          );
                        } else return null;
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChequesTable;
