import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import SocieteType from "../../../Types/Entites/SocieteType";



  
/**
 * Get souche of product.
 * @public
 */
export const findsocieteinfoApi = createAsyncThunk(
  "societe/findsocieteinfoApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`societe/findsocieteinfoApi/${societe}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



type societetate = {
    societe: SocieteType;
  };
const initialState: societetate = {
  societe:{}
  };
  export const societeSlice = createSlice({
    name: "societestate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
   
    
      builder.addCase(findsocieteinfoApi.fulfilled, (state, action) => {
      
         state.societe = action.payload
       });
       
    },
  })

  
  export const societeinfo = (state: RootState) =>state.Societe.societe;


export default societeSlice.reducer;
