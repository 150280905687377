import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListCommission,
  active_parametrageCommissionApi,
  findCommissionApi,
} from "../../_App/Redux/Slices/commission/commissionSlice";
import {
  ListUsers,
  findUserBySocieteIdApi,
} from "../../_App/Redux/Slices/users/userSlice";
import { Form } from "react-bootstrap";
import CommissionForm from "./CommissionForm";
import parametrage_CommissionType from "../../_App/Types/Entites/Commission/parametrage_Commission";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

interface CommissionData {
  [key: string]: any;
}

export default function CommissionList({ t }: any) {
  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);

  const listCommission = useAppSelector(ListCommission);
  const [keyword, setkeyword] = useState("");
  const [showModalForm, setshowModalForm] = useState(false);
  const [selectedcommission, setselectedcommission] =
    useState<CommissionData>();



  useEffect(() => {
    if (societe) {
      dispatch(findCommissionApi(societe));
    }
  }, [dispatch, societe]);


  const updateFunction = (item:any) => {
    setselectedcommission(item);
    setshowModalForm(true);
  };
  const activationCommission = async (element: any) => {
   const data = { _id: element._id, active: !element.active };
    const response = await dispatch(active_parametrageCommissionApi(data));
    if (response.payload.success) {
      ToastSuccess(t(response.payload.message));

      window.location.reload();
    } else {
      ToastWarning(t(response.payload.message));
    }
  };
  return (
    <div>
      <div className="tab-content mb-4" id="border-tabsContent">
        <table className="table">
          <thead>
            <tr>
              <th>{t("libelle")}</th>
              <th>{t("Commission")}</th>
              <th>{t("Date début")}</th>
              <th>{t("Date fin")}</th>
              <th>{t("Status")}</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {listCommission.map((item: any) => (
              <tr key={item._id}>
                <td
                 
                >
                  {item.libelle}
                </td>
                <td>{item.commission}</td>
                <td>{item.date_debut?.toString().split("T")[0]}</td>
                <td>{item.date_fin?.toString().split("T")[0]}</td>
                <td> {item.active ? "Actif" : "Inactif"} </td>

                
                {item.active ? (
                  <>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                          color: "green",
                        }}
                       onClick={() => activationCommission(item  )}
                      >
                        {" "}
                        <i className="fas fa-power-off"></i>
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                       onClick={() => updateFunction(item)}
                      >
                        <i
                          className="fas fa-edit"
                          style={{ color: "#06958d" }}
                        ></i>
                      </span>
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                          color: "red",
                        }}
                        onClick={() => activationCommission(item)}
                      >
                        {" "}
                        <i className="fas fa-power-off"></i>
                      </span>
                    </td>
                    <td></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CommissionForm
        t={t}
        societe_id={societe}
        ShowModal={showModalForm}
        setShowModal={setshowModalForm}
        commission={selectedcommission}
      />
    </div>
  );
}
