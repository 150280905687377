import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import TransfertArticleType from "../../../Types/Entites/TransfertArticle/TransfertArticleType";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { SelectedOrganisationId } from "../Auth/authSlice";

export const createTransfertApi = createAsyncThunk(
  "transferarticle/createTransfertApi",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "transferarticle/createTransfertApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const getlesDemandeTransfertApi = createAsyncThunk(
  "transferarticle/getlesDemandeTransfertApi",
  async (organisation_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "transferarticle/getlesDemandeTransfertApi/" + organisation_id
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const getlesDemandeTransfertCreeApi = createAsyncThunk(
  "transferarticle/getlesDemandeTransfertCreeApi",
  async (organisation_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "transferarticle/getlesDemandeTransfertCreeApi/" + organisation_id
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const annulerTransfertApi = createAsyncThunk(
  "transferarticle/annulerTransfertApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "transferarticle/annulerTransfertApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const approverTransfertApi = createAsyncThunk(
  "transferarticle/approverTransfertApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "transferarticle/approverTransfertApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const confirmTransfertApi = createAsyncThunk(
  "transferarticle/confirmTransfertApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "transferarticle/confirmTransfertApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const retourTransfertApi = createAsyncThunk(
  "transferarticle/retourTransfertApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "transferarticle/retourTransfertApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const confirmretourTransfertApi = createAsyncThunk(
  "transferarticle/confirmretourTransfertApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "transferarticle/confirmretourTransfertApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const numeroBonApi = createAsyncThunk(
  "transferarticle/numeroBonApi",
  async (societe: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "transferarticle/numeroBonApi/"+societe
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const creeDistributionApi = createAsyncThunk(
  "transferarticle/creeDistributionApi",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "transferarticle/creeDistributionApi/",data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
type transfertArticleState = {
  demande_transfer_recp: TransfertArticleType[];
  demande_transfer_cree: TransfertArticleType[];
};

const initialState: transfertArticleState = {
  demande_transfer_recp: [],
  demande_transfer_cree: [],
};

export const TransfertArticleSlice = createSlice({
  name: "transfertArticle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTransfertApi.fulfilled, (state, action) => {
      state.demande_transfer_cree = [
        ...state.demande_transfer_cree,
        ...action.payload.data,
      ];
    });
    builder.addCase(getlesDemandeTransfertApi.fulfilled, (state, action) => {
      state.demande_transfer_recp = action.payload;
    });
    builder.addCase(
      getlesDemandeTransfertCreeApi.fulfilled,
      (state, action) => {
        state.demande_transfer_cree = action.payload;
      }
    );
    builder.addCase(annulerTransfertApi.fulfilled, (state, action) => {
 

      state.demande_transfer_recp = state.demande_transfer_recp.filter(
        (item) =>
          action.payload.data.findIndex(
            (dataItem: any) => dataItem._id === item._id
          ) === -1
      );
    });
  },
});

export const Listdemande_transfer_recp = (state: RootState) =>
  state.transfertArticles.demande_transfer_recp;
export const Listdemande_transfer_cree = (state: RootState) =>
  state.transfertArticles.demande_transfer_cree;

export default TransfertArticleSlice.reducer;
