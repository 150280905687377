import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUserId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  DepotDefautOrganisation,
  findDepotsBySocieteApi,
  findDepotsDefautByOrganisationApi,
  ListDepotByOrganisation,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import {
  findFourinsseurBySocieteApi,
  ListFournisseurs,
} from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import Select from "react-select";
import { FournisseurType } from "../../../_App/Types/Entites/FournisseurType";
import {
  findCarteBySocieteApi,
  ListCartes,
} from "../../../_App/Redux/Slices/cartes/carteSlice";
import {
  findCategorieBySocieteApi,
  ListCategories,
} from "../../../_App/Redux/Slices/categories/categorieSlice";
import { ListSousCategories } from "../../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  findArticle_stockApi,
  findArticleStockByBarCodeApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { Spinner } from "react-bootstrap";
import { handleKeyPress_number } from "../../../_App/Helpers/helpers";
import { creeDistributionApi } from "../../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { useNavigate } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ClientForm from "../../Client/ClientForm";
import { UserDataType } from "../../../_App/Types/Forms/UserDataType";
import { findUsersByCodeApi } from "../../../_App/Redux/Slices/users/userSlice";
export default function Distribution() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const list_organisation = useAppSelector(SelectedOrganisations);
  const depotdefaut = useAppSelector(DepotDefautOrganisation);
  const listDepots = useAppSelector(ListDepotByOrganisation);
  const organisation = useAppSelector(SelectedOrganisation);
  const societe = useAppSelector(societeId);
  const organisationid = useAppSelector(SelectedOrganisationId);
  const fournisseur = useAppSelector(ListFournisseurs);
  const carte = useAppSelector(ListCartes);
  const categorie = useAppSelector(ListCategories);
  const souscategorie = useAppSelector(ListSousCategories);
  const user_id = useAppSelector(selectUserId);
  const [codeabarre, setcodeabarre] = useState("");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [userlivraison, setuserlivraison] = useState<UserDataType>();

  interface InterfaceObj {
    cartes: string[];
    categories: string[];
    souscategories: string[];
    fournisseurs: string[];
  }

  const [SelectedFilter, setSelectedFilter] = useState<InterfaceObj>({
    cartes: [],
    categories: [],
    souscategories: [],
    fournisseurs: [],
  });
  const [loading, setloading] = useState(false);
  const [optioncategorie, setcategoriesdOptions] = useState<any[]>([]);
  const [optionsouscategorie, setsouscategoriesdOptions] = useState<any[]>([]);
  const [articles, setarticles] = useState<any[]>([]);
  const [distribution, setdistribution] = useState<any[]>([]);

  const optionfournisseur = fournisseur.map((item: any) => ({
    value: item._id,
    label: item.nom,
  }));
  const optioncarte = carte.map((item: any) => ({
    value: item._id,
    label: item.libelle.fr,
  }));

  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
      dispatch(findFourinsseurBySocieteApi(societe));
      dispatch(findCarteBySocieteApi(societe));
      dispatch(findCategorieBySocieteApi(societe));
    }
  }, [societe]);
  useEffect(() => {
    if (organisationid) {
      dispatch(findDepotsDefautByOrganisationApi(organisationid));
    }
  }, [organisationid]);

  useEffect(() => {
    if (SelectedFilter.cartes?.length !== 0) {
      const listcategoriescarte = categorie?.filter((item: any) =>
        SelectedFilter.cartes?.includes(item.carte_id)
      );
      setcategoriesdOptions(
        listcategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
    if (SelectedFilter.categories?.length !== 0) {
      const listsouscategoriescarte = souscategorie?.filter((item: any) =>
        SelectedFilter.categories?.includes(item.categorie_id)
      );
      setsouscategoriesdOptions(
        listsouscategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [SelectedFilter]);

  const handleChangeFilter = (name: string, valeur: any) => {
    setSelectedFilter((prev) => ({
      ...prev,
      [name]: valeur.map((item: any) => {
        return item.value;
      }),
    }));
  };

  const findarticles = async () => {
    setloading(true);
    const data = { ...SelectedFilter, depot: depotdefaut._id };
    const articles = await dispatch(findArticle_stockApi(data));
    if (articles) {
      setloading(false);
      if (Array.isArray(articles.payload)) {
        setarticles(articles.payload);
      }
    }
  };

  const handleChangeDistribution = (
    index: number,
    depot: string,
    organisation: string,
    value: any
  ) => {
    // Get the article based on the index
    const article = articles[index];

    // Find the index of the depot in the distribution array
    const depotIndex = distribution.findIndex(
      (item) => item.depot_id === depot
    );
    const updatedDistribution = [...distribution];
    if (depotIndex !== -1) {
      // Find the index of the article in the depot's lines
      const articleIndex = updatedDistribution[depotIndex].lignes.findIndex(
        (a: any) => a.article_id === article._id
      );
      // If the article is found in the lines
      if (articleIndex !== -1) {
        // Create a copy of the articles array
        const updatedArticles = [...articles];

        // Update the stock for the article

        updatedArticles[index].stock[0].stock =
          updatedArticles[index].stock[0].stock +
          updatedDistribution[depotIndex].lignes[articleIndex].Quantite -
          (parseInt(value) || 0);

        // Check if stock is negative
        if (updatedArticles[index].stock[0].stock < 0) {
          ToastWarning("Vous avez dépassé la quantité du dépôt");
        } else {
          setarticles(updatedArticles);
          

          updatedDistribution[depotIndex].lignes[articleIndex].Quantite =
            parseInt(value) || 0;
          setdistribution(updatedDistribution);
        }
      } else {
        // Create a copy of the articles array
        const updatedArticles = [...articles];

        // Update the stock for the article
        updatedArticles[index].stock[0].stock =
          updatedArticles[index].stock[0].stock - (parseInt(value) || 0);

        // Check if stock is negative
        if (updatedArticles[index].stock[0].stock < 0) {
          ToastWarning("Vous avez dépassé la quantité du dépôt");
        } else {
          setarticles(updatedArticles);
          updatedDistribution[depotIndex].lignes = [
            ...updatedDistribution[depotIndex].lignes,
            {
              article_id: article._id,
              Quantite: parseInt(value) || 0,
              stock: article.stock[0]._id,
            },
          ];
        
          setdistribution(updatedDistribution);
        }
      }
    } else {
      // If the depot is not found, update stock and add a new depot entry
      const updatedArticles = [...articles];
      updatedArticles[index].stock[0].stock -= parseInt(value) || 0;

      // Check if stock is negative
      if (updatedArticles[index].stock[0].stock < 0) {
        ToastWarning("Vous avez dépassé la quantité du dépôt");
      } else {
        setarticles(updatedArticles);

        // Create a new depot entry and add it to the distribution
        const newDepotEntry = {
          lignes: [
            {
              article_id: article._id,
              Quantite: parseInt(value) || 0,
              stock: article.stock[0]._id,
            },
          ],
          depot_id: depot,
          organisation: organisation,
        };
        setdistribution((prev) => [...prev, newDepotEntry]);
      }
    }
  };

  const saveDistribution = async () => {
    const listtransfert = distribution.map((element) => {
      return {
        depots_id: depotdefaut._id,
        depots_reception: element.depot_id,
        organisation_demande: element.organisation,
        organisation_transfer: organisationid,
        lignes: element.lignes.filter((item: any) => item.Quantite !== 0).map((i:any)=>{return {...i,Quantiteenvoyee:i.Quantite}}),
        societe_id: societe,
        demander: null,
        accepter_transfer: {
          etat: true,
          user_id: user_id,
          date:new Date()
        },
        approver_reception: {
          etat: false,
        },
        agentLivraison:userlivraison     
       };
    });
    const filteredArray = listtransfert.filter(Boolean);
    if (filteredArray.length > 0) {
      const resp = await dispatch(creeDistributionApi(filteredArray));
      if (resp.payload.success) {
        ToastSuccess("la distributrion est cree ");
        navigate("/listdemande/apprové");
      } else {
        ToastWarning(resp.payload.message);
      }
    } else {
      ToastWarning("Veuillez renseigner les champs des distributions.");
    }
  };

  const handleChange = async (e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        await findArticle(value);
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };
  const findArticle = async (codeabarre: string) => {
    if (depotdefaut) {
      const iddepots = depotdefaut._id;
      const resultArticle = await dispatch(
        findArticleStockByBarCodeApi({ barcode: codeabarre, depots: iddepots })
      );

      if (resultArticle.payload.success) {
        const findarticle = articles.findIndex(
          (item) => item._id === resultArticle.payload.article._id
        );

        if (findarticle === -1) {
          const obj = {
            ...resultArticle.payload.article,
            stock: [resultArticle.payload.stock],
          };

          setarticles((prev) => [...prev, obj]);
          setcodeabarre("");
        } else {
          ToastWarning("l'article deja existe dans le tableau");
        }
      } else {
        ToastWarning("l'article n'existe pas");
      }
    }
  };
  const handleChangeAgentLivraison = async (e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        const resultArticle = await dispatch(findUsersByCodeApi(value));
        if (resultArticle.payload.success) {
          const user = resultArticle.payload.data;
          setuserlivraison(user);
        } else {
          ToastWarning(resultArticle.payload.message);
        }
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };
  const removeAgentlivraison=()=>{
    setuserlivraison(undefined);

  }
  return (
    <div>
      <div className="row ">
        <div className=" col-3">
          <strong>{t("Dépôt  destocké : ")}</strong>
          <strong>{depotdefaut?.libelle}</strong>
        </div>
        <div className="col-3">
          <strong>{t("organisation  destocké : ")}</strong>
          <strong>{organisation?.nom}</strong>
        </div>

        <div className="col-3">
          <strong>{t("Agent de livraison : ")}</strong>

          {!userlivraison ? (
            <input
              type="text"
              className="form-control"
              onChange={(e) => handleChangeAgentLivraison(e)}
            />
          ) : (
            <span className="form-control">
              {userlivraison.nom + " " + userlivraison.prenom}
              <i  onClick={removeAgentlivraison}className="fas fa-times"></i>

            </span>
          )}
        </div>
        
      </div>
      <div className="row mt-2">
        <div className="col-2">
          <strong>{t("fournisseur")}</strong>
          <Select
            isMulti
            options={optionfournisseur}
            isSearchable={true}
            onChange={(e) => handleChangeFilter("fournisseurs", e)}
          />
        </div>
        <div className="col-2">
          <strong>{t("carte")}</strong>
          <Select
            isMulti
            options={optioncarte}
            isSearchable={true}
            onChange={(e) => handleChangeFilter("cartes", e)}
          />
        </div>
        <div className="col-2">
          <strong>{t("categorie")}</strong>
          <Select
            isMulti
            options={optioncategorie}
            isSearchable={true}
            onChange={(e) => handleChangeFilter("categories", e)}
          />
        </div>
        <div className="col-2">
          <strong>{t("souscategorie")}</strong>
          <Select
            isMulti
            options={optionsouscategorie}
            isSearchable={true}
            onChange={(e) => handleChangeFilter("souscategories", e)}
          />
        </div>
        <div className="col-2 mt-4" onClick={findarticles}>
          <button className="btn btn-primary-app">
            <strong>{t("chercher les articles")}</strong>
          </button>
        </div>
        <div className="col-2">
          <strong>{t("code a barre ")}</strong>
          <input
            className="form-control"
            value={codeabarre}
            onChange={(e) => {
              setcodeabarre(e.target.value);
              handleChange(e);
            }}
          />{" "}
        </div>
      </div>

      <div className="row layout-spacing mt-5">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th colSpan={2}></th>
                <th
                  style={{
                    borderRight: "1px solid black",
                  }}
                ></th>

                {list_organisation &&
                  list_organisation?.map((item, index) => (
                    <>
                      <th
                        className="text-center "
                        colSpan={listDepots[item._id]?.length}
                      >
                        {
                          list_organisation?.find((org) => org._id === item._id)
                            ?.nom
                        }
                      </th>

                      <th
                        style={{
                          borderRight: "1px solid black",
                        }}
                      ></th>
                    </>
                  ))}
                <th>{t("societe")}</th>
              </thead>
              {loading ? (
                <>
                  {" "}
                  <Spinner animation="grow" />
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <strong>{t("Article")}</strong>
                    </td>

                    <td
                      style={{
                        borderRight: "1px solid black",
                      }}
                    >
                      <strong>{t("Quantite depot destocké")}</strong>
                    </td>

                    {list_organisation?.map((item, index) => (
                      <>
                        {listDepots[item._id] ? (
                          listDepots[item._id]?.map((dep: any) => (
                            <td key={dep._id}>{dep.libelle}</td>
                          ))
                        ) : (
                          <td></td>
                        )}
                        <td
                          style={{
                            borderRight: "1px solid black",
                          }}
                        ></td>
                      </>
                    ))}
                  </tr>

                  {Array.isArray(articles) && articles.length > 0 ? (
                    articles?.map((itemdata: any, dataIndex: any) => (
                      <tr key={dataIndex}>
                        <td>
                          {itemdata.image &&
                          itemdata.image !== null &&
                          itemdata.image.length !== 0 ? (
                            <Zoom>
                              <img
                                alt="image"
                                src={
                                  process.env.REACT_APP_API_PUBLIC_URL +
                                  "article/" +
                                  itemdata?.image
                                }
                                width="100"
                              />
                            </Zoom>
                          ) : (
                            <img
                              src="assets/assets/img/tshirt.png"
                              className="card-img-top"
                              alt="..."
                              style={{ width: "100px" }}
                            />
                          )}
                        </td>
                        <td>{itemdata?.titre?.fr}</td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                          }}
                        >
                          {itemdata.stock[0].stock}
                        </td>
                        {list_organisation?.map((itemdepo, index) => (
                          <React.Fragment key={index}>
                            {listDepots[itemdepo._id] ? (
                              listDepots[itemdepo._id]?.map(
                                (dep: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        onKeyPress={handleKeyPress_number}
                                        onChange={(e) =>
                                          handleChangeDistribution(
                                            dataIndex,
                                            dep._id,
                                            itemdepo._id,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          distribution
                                            .find(
                                              (item) =>
                                                item.depot_id === dep._id
                                            )
                                            ?.lignes.find(
                                              (a: any) =>
                                                a.article_id === itemdata._id
                                            )?.Quantite || 0
                                        }
                                      />
                                    </td>
                                  </React.Fragment>
                                )
                              )
                            ) : (
                              <td></td>
                            )}
                            <td
                              style={{
                                borderRight: "1px solid black",
                              }}
                            ></td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <p>{t("aucun article")}</p>
                  )}
                </tbody>
              )}
            </table>
            {Array.isArray(articles) && articles.length > 0 && (
              <button
                className="btn btn-primary-app"
                onClick={saveDistribution}
              >
                {t("Enregistrer")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
