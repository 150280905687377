import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  selectedAutorisations,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  getAllJournalCaisses,
  ListJournaux,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { JournalCaisseType } from "../../_App/Types/Entites/JournalCaisseType";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Navbar from "../../_Layouts/Navbar/Navbar";
import SessionListByJournalIdModal from "../../Components/SessionJournal/SessionListByjournal";
import AllStatistique from "../../Components/JournalCaisse/AllStatistique";
import { SessionJournals, getAllSessionJournals } from "../../_App/Redux/Slices/sessionJournal/sessionJournalSlice";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledSelect = styled(Select)`
  width: 350px;
`;
const CustomTableCell = styled.td`
  font-weight: bold;
  font-size: larger;
`;

const StyledInput = styled.input`
  height: 38px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SessionsJournaux: React.FC = () => {
  const idOrganisation = useAppSelector(SelectedOrganisationId);
  const journaux = useAppSelector(ListJournaux);
  const dispatch = useAppDispatch();
  const todayDate = new Date().toISOString().split("T")[0];
  const [selectedJournalId, setSelectedJournalId] = useState<string | null>(
    null
  );
  const { t } = useTranslation();

  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [showStatistiqueModal, setShowStatistiqueModal] =
    useState<boolean>(false);
  const [showModalType, setShowModalType] = useState<
    "session" | "statistique" | null
  >(null);

  const navigate = useNavigate();
  const sessions = useAppSelector(SessionJournals);


  const getCodeJournalById = (journalId: string): string | null => {
    const foundJournal = journaux.find((journal) => journal._id === journalId);
    return foundJournal ? foundJournal.code_journal || null : null;
  };

  const [selectedCaisses, setSelectedCaisses] = useState<
    | {
        value: string;
        label: string;
      }[]
    | null
  >(null);
  const [selectedDate, setSelectedDate] = useState(todayDate);
  useEffect(() => {
    dispatch(getAllSessionJournals());
  }, [dispatch]);

  useEffect(() => {
    if (idOrganisation) {
      dispatch(getAllJournalCaisses(idOrganisation));
    }
  }, [idOrganisation]);

  useEffect(() => {
    if (selectedDate && idOrganisation) {
      dispatch(getAllJournalCaisses(idOrganisation));
    }
  }, [selectedDate, idOrganisation, dispatch]);

  const groupedJournaux = journaux.reduce((acc, journal) => {
    const idCaisse = journal.id_caisse || "Unknown";
    if (!acc[idCaisse]) {
      acc[idCaisse] = [];
    }
    acc[idCaisse].push(journal);
    return acc;
  }, {} as Record<string, JournalCaisseType[]>);
  const caissesOptions = Object.keys(groupedJournaux).map((idCaisse) => ({
    value: idCaisse,
    label: `Caisse: ${
      groupedJournaux[idCaisse][0]?.libelle_caisse || idCaisse
    }`,
  }));

  const handleCodeJournalClick = (journalId: string) => {
    setSelectedJournalId(journalId);
    setShowModalType("session");
    setShowSessionModal(true);
    setShowStatistiqueModal(false);
  };

  const handleStatistiqueButtonClick = (journalId: string) => {
    setSelectedJournalId(journalId);
    setShowModalType("statistique");
    setShowStatistiqueModal(true);
    setShowSessionModal(false);
  };

  const autorisations = useAppSelector(selectedAutorisations);
  const canViewStatistic = autorisations?.CAN_VIEW_STATISTIQUE_JOURNAL;

  return (
    <>
      <h1>Journaux et sessions journaux</h1> <Navbar />
      <div>
        <Container>
          <label>{t("Caisse")}:</label>
          <StyledSelect
            isMulti
            options={caissesOptions}
            value={selectedCaisses}
            onChange={(selectedOptions) =>
              setSelectedCaisses(selectedOptions as typeof selectedCaisses)
            }
            placeholder="Sélectionnez une ou plusieurs caisses"
          />

          <label>{t("Date")}:</label>
          <StyledInput
            type="date"
            value={selectedDate }
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </Container>

        {Object.entries(groupedJournaux).map(([idCaisse, journals]) => (
          <div key={idCaisse}>
            
           
              <div>
                <h4 style={{ margin: "15px 0" }}>{`Caisse : ${
                  groupedJournaux[idCaisse][0]?.libelle_caisse || idCaisse
                }`}</h4>

                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("Caisse")}</th>
                      <th>{t("Caissier")}</th>
                      <th>{t("Code journal")}</th>
                      <th>{t("Nombre de sessions")}</th>

                      <th>{t("Fond initial")}</th>
                      <th>{t("Fond finale")}</th>
                      <th>{t("Ecart")}</th>
                      <th>{t("Date")}</th>
                      {canViewStatistic && <th>{t("Statistiques")}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {journals.filter( (journal) =>
                          !selectedDate ||  (journal.createdAt && new Date(journal.createdAt).toISOString().split("T")[0] === selectedDate) )
                      .map((journal) => (
                        <tr key={journal._id}>
                          <td>{journal.libelle_caisse}</td>
                          <td>
                            {journal.user_id?.nom || "-"}{" "}
                            {journal.user_id?.prenom || "-"}
                          </td>
                          <td>
                         
                              {journal.code_journal}
                          </td>

                          <td style={{ fontSize: "17px" }}>                          <div
                              className="clickable-code-journal"
                              onClick={() =>
                                handleCodeJournalClick(journal._id || "")
                              }
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                            {sessions
                              .filter(
                                (session) =>
                                  session.journal_id === journal._id
                              )
                              .length} sessions
                              </div>
                          </td>
                          <td>{journal.font_initial}</td>
                          <td>{journal.font_final}</td>
                          <CustomTableCell
                            style={{
                              color:
                                journal.fond_courant !== undefined &&
                                journal.font_final !== undefined
                                  ? journal.fond_courant -
                                    journal.font_final === 0
                                    ? "green"
                                    : "red"
                                  : "inherit",
                            }}
                          >
                            {journal.fond_courant !== undefined &&
                            journal.font_final !== undefined ? (
                              <>
                                {journal.fond_courant - journal.font_final ===
                                0 ? (
                                  "0"
                                ) : (
                                  <>
                                    {journal.fond_courant - journal.font_final <
                                      0 && "-"}
                                    {journal.fond_courant - journal.font_final >
                                      0 && "+"}
                                    {Math.abs(
                                      journal.fond_courant - journal.font_final
                                    )}
                                  </>
                                )}
                              </>
                            ) : null}
                          </CustomTableCell>
                          <td>
                            {journal.createdAt
                              ? new Date(journal.createdAt).toLocaleDateString()
                              : ""}
                          </td>
                          <td>
                            {canViewStatistic && (
                              <button
                                className="btn btn-primary-app"
                                onClick={() =>
                                  handleStatistiqueButtonClick(journal._id)
                                }
                              >
                                {t("voir statistique")}
                              </button>
                            )}
                          </td>
                          
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
          
          </div>
        ))}
        {selectedJournalId && showModalType === "statistique" && (
          <AllStatistique
            onClose={() => {
              setShowStatistiqueModal(false);
              setSelectedJournalId(null);
            }}
            journalId={selectedJournalId ?? ""}
            show={showStatistiqueModal}
            getCodeJournalById={getCodeJournalById}

          />
        )}
        {selectedJournalId && showModalType === "session" && (
          <SessionListByJournalIdModal
            handleClose={() => setShowSessionModal(false)}
            journalId={selectedJournalId ?? ""}
            showModal={showSessionModal}
            getCodeJournalById={getCodeJournalById}
          />
        )}
      </div>
    </>
  );
};

export default SessionsJournaux;
