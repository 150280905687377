import React, { useEffect, useState } from "react";

export default function ProductFilter({
  t,
  setkeyword,
  handelsearch,
  keyword,
}: any) {
 
useEffect(()=>{
  console.log('keyword',keyword)
},[keyword])
  return (
    <div>
      <div className="row mb-5">
        <div className="col-4">
          <input
            type="text"
            className="form-control product-search br-30"
            id="input-search"
            placeholder={t("chercher par mot cle")}
            onChange={(e) => setkeyword(e.target.value)}
          />
        </div>
     
        <div className="col-4">
          <select
            className="form-control product-search br-30"
            onChange={(e) =>{setkeyword(e.target.value) } }
          >
            <option value=''>{t('filtrer par type')}</option>
            <option value="parent">{t('parent')}</option>
            <option value="fils">{t('fils')}</option>
          </select>
        </div>

        <div className="col-4">
          <button className=" btn btn-primary-app" onClick={handelsearch}>{t('chercher')}</button>
        </div>
      </div>
    </div>
  );
}
