import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import {
  ListSousCategories,
  ListSousCategoriesByCategorie,
  currentpage,
  findSousCategorieBySocieteApi,
  findSousCategoriesApi,
  findSousCategoriesByCategorieApi,
  totalPages,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import SousCategoriesList from "../../Components/SousCategories/SousCategoriesList";
import { useParams } from "react-router";
import SousCategorieForm from "../../Components/SousCategories/SousCategorieForm";
import { SelectedOrganisationId ,societeId} from "../../_App/Redux/Slices/Auth/authSlice";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
const SousCategoriesPage = () => {
  const { idcategorie } = useParams();
  const [index,setindex]=useState(0)
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [showModalSousCategorie, setShowModalSousCategorie] = useState(false);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState(null);
  const dispatch = useAppDispatch();
  const sousCategories = useAppSelector(ListSousCategories);
  const sousCategoriesByCategorie = useAppSelector(
    ListSousCategoriesByCategorie
  );
  const societe = useAppSelector(societeId);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
   useEffect(()=>{
    const data={societe:societe ,index : index}
    dispatch(findSousCategorieBySocieteApi(data))
   },[societe ,index])

  useEffect(() => {
    // Check if idcarte is defined before dispatching
    if (idcategorie) {
      dispatch(findSousCategoriesByCategorieApi(idcategorie));
      console.log(idcategorie);
    } else if (currentOrganisation) {
      dispatch(findSousCategoriesApi(currentOrganisation));
    }
  }, [dispatch, idcategorie]);

  const openEditForm = (sousCategorie: any) => {
    setSelectedSousCategorie(sousCategorie);
    setShowModalSousCategorie(true);
  };

  
  return (
    <div>
      <div className="row">
        <button
          className="btn btn-primary btn-block mb-4 mr-2"
          onClick={() => setShowModalSousCategorie(true)}
        >
          Ajouter une sous catégorie
        </button>
      </div>
      <SousCategorieForm
        ShowModal={showModalSousCategorie}
        setShowModal={setShowModalSousCategorie}
        sousCategorie={selectedSousCategorie}
        categorie_id={idcategorie}
        currentOrganisation={currentOrganisation}
        societe={societe}
      /> 
      <SousCategoriesList
        sousCategoriesListe={
          idcategorie ? sousCategoriesByCategorie : sousCategories
        }
        onEditClick={openEditForm}
      />
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
    </div>
  );
};

export default SousCategoriesPage;
