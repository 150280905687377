import React from "react";
import ApportRetraitCaisse from "../../Components/Caisses/mouvementCaisse/ApportRetraitCaisse";
import MouvementCaisse from "./Modules/MouvementCaisse";
import Navbar from "../../_Layouts/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
const DashboardPage = () => {
  const navigate = useNavigate()
  return (
    <div className="admin-data-content layout-top-spacing">
      <div className="row">
       
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center ">
          <div className="widget widget-card-four cardstyle" onClick={()=>navigate("/mouvement")}>
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h1>Mouvement</h1>
                </div>
               
              </div>

             

              <div className="w-progress-stats">
               
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center "  onClick={()=>navigate("/mouvementvente")}>
          <div className="widget widget-card-four cardstyle">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h1 >Mouvement Vente</h1>
                </div>
               
              </div>

             

              <div className="w-progress-stats">
               
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center  " onClick={()=>navigate("/Mouvementpaiement")}>
          <div className="widget widget-card-four cardstyle">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h1>Mouvement Payement</h1>
                </div>
               
              </div>

             

              <div className="w-progress-stats">
               
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};
export default DashboardPage;
/* <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Mouvements Caisse</h6>
                </div>
              </div>

              <div className="w-content">
                <div className="w-info">
                  <MouvementCaisse />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Expenses</h6>
                </div>
                <div className="task-action">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="pendingTask"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>

                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="pendingTask"
                      style={{ willChange: "transform" }}
                    >
                      <a className="dropdown-item" href="javascript:void(0);">
                        This Week
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        Last Week
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        Last Month
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    $ 45,141 <span>this week</span>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-trending-up"
                    >
                      <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline>
                    </svg>
                  </p>
                  <p className="">Expenses</p>
                </div>
              </div>

              <div className="w-progress-stats">
                <div className="progress">
                  <div
                    className="progress-bar bg-gradient-secondary"
                    role="progressbar"
                    style={{ width: "57%" }}
                    aria-valuenow={57}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>

                <div className="">
                  <div className="w-icon">
                    <p>57%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/
