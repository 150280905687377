import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { User } from "../../../Types/Entites/User";
import axios from "axios";
import { UserDataType } from "../../../Types/Forms/UserDataType";

export const updateMyCodePinApi = createAsyncThunk(
  "user/updateMyCodePinApi",
  async (
    {
      userId,
      newPassword,
      newCodePin,
    }: { userId: string | undefined; newPassword: string; newCodePin: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`/user/updateMyCodePinApi`, {
        userId,
        newPassword,
        newCodePin,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
        message: "Mot de passe incorrect ",
      });
    }
  }
);

export const findUserByIdApi = createAsyncThunk(
  "user/findUserByIdApi",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/user/findUserByIdApi/${userId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const activateUserApi = createAsyncThunk(
  "user/activateUserApi",
  async (
    { userId, isActive }: { userId: string; isActive: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`/user/activateUserApi/${userId}`, {
        isActive,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        success: false,
      });
    }
  }
);

/**
 * Update User.
 * @public
 */

export const updateUserApi = createAsyncThunk(
  "user/updateUserApi",
  async (userData: UserDataType, { rejectWithValue }) => {
    try {
      const response = await axios.put("user/updateUserApi", userData);

      return response.data;
    } catch (error) {
      console.error("Erreur de mise à jour :");
      return rejectWithValue({
        success: false,
      });
    }
  }
);

/**
 * Get List of users by organisation.
 * @public
 */
export const findUserByOrganisationApi = createAsyncThunk(
  "user/findUserByOrganisationApi",
  async (organisationId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/user/findUserByOrganisationApi/${organisationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get List of users by societe.
 * @public
 */
export const findUserBySocieteIdApi = createAsyncThunk(
  "user/findUserBySocieteIdApi",
  async (societe: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/user/findUserBySocieteIdApi/${societe}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get List of users by list of organisations IDS.
 * @public
 */

export const findUsersByOrganisationsListApi = createAsyncThunk(
  "users/findUsersByOrganisationsApi",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const organisationIds = state.auth.user?.organisations || [];

      const response = await axios.post(
        "/user/findUsersByOrganisationsListApi",
        {
          organisationIds,
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const createUserApi = createAsyncThunk(
  "user/createUserApi",
  async (userData: FormData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post("/user/createUserApi", userData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const deleteUserApi = createAsyncThunk(
  "user/deleteUserApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      await axios.delete(`/user/deleteUserApi/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const closeSessionUserApi = createAsyncThunk(
  "user/closeSessionUserApi",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/user/closeSessionUserApi/${userId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const findUsersApi = createAsyncThunk(
  "users/findUsersApi",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`user/findUsersApi}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


export const findUsersByCodeApi = createAsyncThunk(
  "users/findUserByCodeApi",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`user/findUserByCodeApi/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateUserLangApi = createAsyncThunk(
  "user/updateUserLangApi",
  async (params: any, { rejectWithValue }) => {
    try {
      const { id, lang } = params;
      const response = await axios.put("user/updateUserLangApi", {
        id,
        lang,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const openSessionUserApi = createAsyncThunk(
  "user/openSessionUserApi",
  async ({ userId, pin }: { userId: string; pin: string }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/user/openSessionUserApi`, { _id: userId, pin });
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue({
        success: false,
      });
    }
  }
);


const initialState: User[] = [];

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findUserByOrganisationApi.fulfilled, (state, action) => {
      return (state = action.payload);
    });

    builder.addCase(findUserBySocieteIdApi.fulfilled, (state, action) => {
      return (state = action.payload);
    });
    builder.addCase(createUserApi.fulfilled, (state, action) => {
      const newUser = action.payload.data;
      return [...state, newUser];
    });

    builder.addCase(deleteUserApi.fulfilled, (state, action) => {
      const deletedUserId = action.payload;
      return state.filter((user) => user._id !== deletedUserId);
    });
    builder.addCase(
      findUsersByOrganisationsListApi.fulfilled,
      (state, action) => {
        return (state = action.payload);
      }
    );

    builder.addCase(updateUserApi.fulfilled, (state, action) => {
      const updatedUser = action.payload.data;
      console.log('new user' , updatedUser)
      return state.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user
      );
    });
    builder.addCase(findUserByIdApi.fulfilled, (state, action) => {
      return [action.payload];
    });

    builder.addCase(activateUserApi.fulfilled, (state, action) => {
      const updatedUser = action.payload.data;
      return state.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user
      );
    });

    builder.addCase(closeSessionUserApi.fulfilled, (state, action) => {
      const updatedUser = action.payload.data;
      return state.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user
      );
    });

    builder.addCase(openSessionUserApi.fulfilled, (state, action) => {
      const updatedUser = action.payload.data;
      return state.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user
      );
    });
    builder.addCase(updateMyCodePinApi.fulfilled, (state, action) => {
      const updatedUser = action.payload.data;
      return state.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user
      );
    });
    builder.addCase(updateUserLangApi.fulfilled, (state, action) => {
      const updatedUser = action.payload.data;
      return state.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user
      );
    });
  },
});

export const ListUsers = (state: RootState) => state.users;

export default userSlice.reducer;

