import React, { useEffect, useState } from "react";
import CreationMatrice from "../../../Components/Products/Matrice/CreationMatrice";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  findlistMatriceBySocieteApi,
  findMatriceBySocieteApi,
  Listmatrice,
} from "../../../_App/Redux/Slices/matrice/matriceSlice";
import { societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { ProductCaracteristiqueType } from "../../../_App/Types/Entites/ProductCaracteristiqueType";
import Matrice from "../../../Components/Products/Products/ProductsForm/Matrice";
export default function MatricePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const listmatrice = useAppSelector(Listmatrice);
  const [matriceselected, setmatriceselected] = useState();
  const [list_caracteristiques, setlist_caracteristiques] = useState<any[]>([]);
  const [ShowModalMatrice, setShowModalMatrice] = useState(false);

  useEffect(() => {
    if (societe) {
      dispatch(findlistMatriceBySocieteApi(societe));
    }
  }, [dispatch, societe]);
  const selectMatrice =(item:any)=>{
    const copie = {...item}
    copie.dimension1 = copie.dimension1?._id
    copie.dimension2= copie.dimension2?._id
    copie.dimension3= copie.dimension3?._id

    setmatriceselected(copie);
    setlist_caracteristiques([
      copie.dimension1,
      copie.dimension2,
      copie.dimension3,
    ]);
    setShowModalMatrice(true)
  }
  return (
    <div>
      <CreationMatrice t={t} />
      <div className="col-lg-12  mt-5   bg-white">
      <div className="inv--product-table-section ">
        <div className="table-responsive">
          <table className="table">
          <thead>
            <th>Matrice</th>
            <th>Demission 1</th>
            <th>Demission 2</th>
            <th>Demission 3</th>
          </thead>
          <tbody>
              {listmatrice?.map(item=>(
                <tr>
                  <td onClick={()=>selectMatrice(item)}>{item.name_matrice}</td>
                  <td>{item.dimension1?.libelle || '--'}</td>
                  <td>{item.dimension2?.libelle || '--'}</td>
                  <td>{item.dimension3?.libelle|| '--'}</td>

                </tr>
              ))}
          </tbody>
        </table>
      </div>
      </div>
      </div>
      <Matrice
        setmatriceselected={setmatriceselected}
        ShowModal={ShowModalMatrice}
        setShowModal={setShowModalMatrice}
        caracteristique={list_caracteristiques}
        matrice={matriceselected}
      />
    </div>
  );
}
