import { ChangeEvent, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  findFidelitePrograms,
  findNiveauxFidelite,
  ListNiveauxFidelite,
  ListZones,
} from "../../_App/Redux/Slices/client/clientSlice";
import Select from "react-select";
import {
  findCarteBySocieteApi,
  findCartesApi,
  ListCartes,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import {
  findCategorieBySocieteApi,
  findCategoriesApi,
  ListCategories,
  ListCategoriesByCarte,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  CategorieSousCategories,
  findSousCategorieBySocieteApi,
  findSousCategoriesApi,
  findSousCategoriesByCategorieApi,
  ListSousCategories,
  ListSousCategoriesByCategorie,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  findArticleByBarCode,
  findArticlebysocieteApi,
  ListArticles,
} from "../../_App/Redux/Slices/article/articleSlice";
import { ArticleType } from "../../_App/Types/Entites/ArticleType";

function ConditionForms({
  type,
  setConditionClient,
  setConditionProduit,
  setConditionAchat,
  solde,
}: any) {
  const { t } = useTranslation();
  const [rand, setRand] = useState(true);
  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);
  const niveaux = useAppSelector(ListNiveauxFidelite);
  const zones = useAppSelector(ListZones);

  const [inputValue, setInputValue] = useState("");
  const [article, setArticle] = useState(null);
  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);
  const [conditionProduitType, setconditionProduitType] = useState<
    string | null
  >(null);
  const [fournisseur, setFournisseur] = useState(null);
  const [etOuclient, setEtOuclient] = useState<string | null>(null);
  const [etOuProduit, setEtOuProduit] = useState<string | null>(null);

  const [cumulableachat, setCumulableachat] = useState(false);
  const [cumulableproduit, setCumulableproduit] = useState(false);

  const [niveauClient, setNiveauClient] = useState<string | null>(null);
  const [pointsMin, setPointsMin] = useState<number | null>(null);
  const [zone, setZone] = useState<string | null>(null);
  const [montant, setMontant] = useState<number | null>(null);

  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [selectedCarte, setSelectedCarte] = useState<any>(null);
  const [selectedCategorie, setSelectedCategorie] = useState<any>(null);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any>(null);
  useEffect(() => {
    setConditionAchat(conditionAchat);
    setConditionClient(conditionClient);
    setConditionProduit(conditionProduit);
    if (currentOrganisation) {
      dispatch(findCartesApi(currentOrganisation));
      dispatch(findCategoriesApi(currentOrganisation));
      if (selectedCategorie)
        dispatch(findSousCategoriesByCategorieApi(selectedCategorie));
    }
  }, [rand, dispatch, selectedCategorie]);
  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategoriesByCarte);
  const sousCategories = useAppSelector(CategorieSousCategories);

  const handleChange = (option: any, type: string) => {
    if (type === "carte") {
      setSelectedCarte(option.value);
    } else if (type === "categorie") {
      setSelectedCategorie(option.value);
    } else {
      setSelectedSousCategorie(option.value);
    }
  };
  const CartesOptions = cartes?.map((option) => ({
    value: option._id,
    label: option.libelle.fr,
  }));
  const categoriesdOptions =
    selectedCarte &&
    categories[selectedCarte]?.map((option) => ({
      value: option._id,
      label: option.libelle.fr,
    }));

  const sousCategoriesOptions =
    selectedCategorie &&
    sousCategories.map((option) => ({
      value: option._id,
      label: option.libelle.fr,
    }));

  let conditionClient =
    niveauClient || pointsMin || zone
      ? {
          niveau_fidelite: niveauClient,
          points_min: pointsMin,
          zone: zone,
          organisation_id: currentOrg,
          remise_id: type === "remise" ? "" : null,
          gratuite_id: type === "gratuite" ? "" : null,
          fidelite_id: type === "fidelite" ? "" : null,
        }
      : null;
  let conditionProduit =
    selectedCategorie ||
    selectedSousCategorie ||
    selectedCarte ||
    article ||
    min ||
    max
      ? {
          categorie_id: selectedSousCategorie,
          sous_categorie_id: selectedSousCategorie,
          carte_id: selectedCarte,
          article_id: article,
          min: min,
          max: max,
          type: conditionProduitType,
          organisation_id: currentOrg,
          fournisseur: fournisseur,
          remise_id: type === "remise" ? "" : null,
          gratuite_id: type === "gratuite" ? "" : null,
          fidelite_id: type === "fidelite" ? "" : null,
          cumulable: cumulableproduit,
        }
      : null;

  let conditionAchat = montant
    ? {
        montant_achat: montant,
        organisation_id: currentOrg,
        remise_id: type === "remise" ? "" : null,
        gratuite_id: type === "gratuite" ? "" : null,
        fidelite_id: type === "fidelite" ? "" : null,
      }
    : null;

  const handleInputArticleChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const barcode = event.target.value;
    setInputValue(barcode);
    const resultArticle = await dispatch(findArticleByBarCode(barcode));
    if (resultArticle.payload) {
      setArticle(resultArticle?.payload?._id);
      setInputValue(resultArticle?.payload?.titre?.fr);
    } else {
      // Handle the case where no article is found, if necessary
    }
  };

  const handleClearInput = () => {
    setInputValue("");
    setArticle(null);
  };

  return (
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      {solde ? null : (
        <Tab eventKey="client" title={t("Conditions client")}>
          <div className="row">
            <div className="col-6">
              <div>
                <label htmlFor="niveau ">
                  <span style={{ color: "red" }}></span>
                  {t("Niveau fidelité")}
                </label>
              </div>

              <select
                className="form-control mb-2"
                onChange={(e) => {
                  setNiveauClient(e.currentTarget.value);
                  setRand(!rand);
                }}
              >
                <option value="">{t("Choisir un niveau fidelité")} </option>
                {niveaux.map((item: any) => (
                  <option key={item._id} value={item._id}>
                    {item.libelle}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="Zone">
                  {" "}
                  <span style={{ color: "red" }}></span>
                  {t("Zone")}
                </label>
                <select
                  className="form-control mb-2"
                  onChange={(e) => {
                    setZone(e.currentTarget.value);
                    setRand(!rand);
                  }}
                >
                  <option value="">{t("Choisir une zone")} </option>
                  {zones.map((item: any) => (
                    <option key={item._id} value={item._id}>
                      {item.libelle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </Tab>
      )}
      {solde ? null : (
        <Tab eventKey="achat" title={t("Conditions Achat")}>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="montant">
                  {" "}
                  <span style={{ color: "red" }}></span>
                  {t("Montant minimum d'achat")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="montant"
                  onChange={(e) => {
                    //
                    setMontant(Number(e.currentTarget.value));
                    setRand(!rand);
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="cumulable">
                  {" "}
                  <span style={{ color: "red" }}></span>
                  {t("Cumulable")}
                </label>
                <input
                  className="m-2"
                  checked={cumulableachat}
                  type="checkbox"
                  id="cumulable"
                  onChange={(e) => {
                    setCumulableachat(!cumulableachat);
                    setRand(!rand);
                  }}
                />
              </div>
            </div>
          </div>
        </Tab>
      )}

      <Tab eventKey="produit" title="Conditions Produits">
        {/* <button onClick={() => console.log(solde)}>test</button> */}

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="carte">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Carte")}
              </label>
              <Select
                value={selectedCarte?.label}
                onChange={(e) => handleChange(e, "carte")}
                options={CartesOptions}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="categorie">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Categorie")}
              </label>
              <Select
                value={selectedCategorie?.label}
                onChange={(e) => handleChange(e, "categorie")}
                options={categoriesdOptions}
                isSearchable={true}
                isDisabled={selectedCarte ? false : true}
              />{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="sous">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Sous categorie")}
              </label>
              <Select
                value={selectedSousCategorie?.label}
                onChange={(e) => handleChange(e, "sous_categorie")}
                options={sousCategoriesOptions}
                isSearchable={true}
                isDisabled={selectedCategorie ? false : true}
              />{" "}
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label htmlFor="Article">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Article")}
              </label>
              <div className="input-button" style={{ display: "flex" }}>
                <input
                  value={inputValue}
                  onChange={(e) => {
                    handleInputArticleChange(e);
                    setRand(!rand);
                  }}
                  type="text"
                  className="form-control"
                  id="Article"
                />
                {inputValue && (
                  <button
                    onClick={handleClearInput}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="quantity">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Quantite / chiffre min")}
              </label>
              <input
                type="number"
                className="form-control"
                id="quantite"
                onChange={(e) => {
                  setMin(Number(e.currentTarget.value));
                  setRand(!rand);
                }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="quantity">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Quantite / chiffre max")}
              </label>
              <input
                type="number"
                className="form-control"
                id="quantite"
                onChange={(e) => {
                  setMax(Number(e.currentTarget.value));
                  setRand(!rand);
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label>
                Quantité <span style={{ color: "red" }}></span>
              </label>
              <input
                className="m-2"
                type="radio"
                id="quantite1"
                name="quantite"
                onChange={(e) => {
                  setconditionProduitType("Quantité");
                  setRand(!rand);
                }}
              />
              <label htmlFor="quantite1">
                Chiffre <span style={{ color: "red" }}></span>
              </label>
              <input
                className="m-2"
                type="radio"
                id="quantite2"
                name="quantite"
                onChange={(e) => {
                  setconditionProduitType("Chiffre");
                  setRand(!rand);
                }}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="cumulable">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Cumulable")}
              </label>
              <input
                className="m-2"
                checked={cumulableproduit}
                type="checkbox"
                id="cumulable"
                onChange={(e) => {
                  setCumulableproduit(!cumulableproduit);
                  setRand(!rand);
                }}
              />
            </div>
          </div>
        </div>
        {/* <button onClick={() => console.log(conditionProduit)}></button> */}
      </Tab>
    </Tabs>
  );
}

export default ConditionForms;
