import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { ChequeType, PaymentType } from "../../../Types/Entites/PaymentType";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";

/**
 * create a payment.
 * @public
 */

export const savePaymentApi = createAsyncThunk(
  "payments/savePaymentApi",
  async (data: PaymentType, { rejectWithValue }) => {
    try {
      const response = await axios.post("payments/savePaymentApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * save cheque.
 * @public
 */

export const saveCheque = createAsyncThunk(
  "payments/saveCheques",
  async (data: ChequeType[], { rejectWithValue }) => {
    try {
      const response = await axios.post("payments/saveCheques", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * list  of payments by organisation.
 * @public
 */
export const findPaymentsByOrganisationApi = createAsyncThunk(
  "payments/findPaymentsByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findPaymentsByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * list  of payments by organisation.
 * @public
 */
export const findCheques = createAsyncThunk(
  "payments/findCheques",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findCheques/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findPaymentsByOrderApi = createAsyncThunk(
  "payments/findPaymentsByOrderApi",
  async (idOrder: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findPaymentsByOrderApi/${idOrder}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findPaymentsByTicketApi = createAsyncThunk(
  "payments/findPaymentsByTicketApi",
  async (idTicket: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findPaymentsByTicketApi/${idTicket}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateCheque = createAsyncThunk(
  "payments/updateCheque",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`payments/updateCheque`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const mouvementPayementApi = createAsyncThunk(
  "payments/mouvementPayement",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`payments/mouvementPayement/`,data);
      if(response.data.data !== null){
        return response.data;

      }else{
        ToastWarning( response.data.message)
        throw Error;
     
      }
      
      
    } catch (error: any) {
      
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface PaymentsState {
  listPaymentMouvement: PaymentType[];
  listPaymentOperations: PaymentType[];
  listPaymentOperationsByTicket: PaymentType[];
  listPaymentsByPaymentMethod: {
    [paymentMethod: string]: PaymentType[];
  };
  chequesList: ChequeType[];
  totalPages:any,
  currentpage:any,
  totaux:any
}

const initialState: PaymentsState = {
  listPaymentMouvement:[],
  listPaymentOperations: [],
  listPaymentOperationsByTicket: [],
  listPaymentsByPaymentMethod: {},
  chequesList: [],
  totalPages:null,
  currentpage:null,
  totaux:null
};

export const PaymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(savePaymentApi.fulfilled, (state, action) => {
      state.listPaymentOperations.splice(0, 0, action.payload.data);
    });
    builder.addCase(findPaymentsByOrderApi.fulfilled, (state, action) => {
      const groupedByPaymentMethod = _.groupBy(
        action.payload,
        "payment_method"
      );
      return {
        ...state,
        listPaymentOperations: action.payload,
        listPaymentsByPaymentMethod: groupedByPaymentMethod,
      };
    });
    builder.addCase(findPaymentsByTicketApi.fulfilled, (state, action) => {
      const groupedByPaymentMethod = _.groupBy(
        action.payload,
        "payment_method"
      );
      return {
        ...state,
        listPaymentsByPaymentMethod: groupedByPaymentMethod,
      };
    });
    builder.addCase(findCheques.fulfilled, (state, action) => {
      return {
        ...state,
        chequesList: action.payload,
      };
    });
    builder.addCase(updateCheque.fulfilled, (state, action) => {
      const cheque = action.payload;
      const index = _.findIndex(state.chequesList, {
        _id: cheque._id,
      });
      state.chequesList.splice(index, 1, cheque);
    });

    builder.addCase(  mouvementPayementApi.fulfilled,   (state, action) => {
      state.listPaymentMouvement = action.payload.data.list;
      state.totalPages=action.payload.data.totalPages
      state.currentpage=action.payload.data.currentPage
      state.totaux= action.payload.data.totaux;
    }
  );
  },
});
export const ListPaymentMouvement = (state: RootState) =>
  state.payments.listPaymentMouvement;
export const listPaymentOperations = (state: RootState) =>
  state.payments.listPaymentOperations;
export const listCheques = (state: RootState) => state.payments.chequesList;
export const listOperationsByPaymentMethod = (state: RootState) =>
  state.payments.listPaymentsByPaymentMethod;
export const listPaymentOperationsByTicket = (state: RootState) =>
  state.payments.listPaymentOperationsByTicket;
export const Totaux = (state: RootState) => state.payments.totaux;

export const currentpage = (state: RootState) => state.payments.currentpage;
export const totalPages = (state: RootState) => state.payments.totalPages;
export default PaymentsSlice.reducer;
