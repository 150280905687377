// Dispatching the action

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  findParametragesCaisseByOrganisationApi,
  parametrageCaisse,
} from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import Navbar from "../../_Layouts/Navbar/Navbar";
import {
  findReptureDeStock,
  ReptureStockList,
} from "../../_App/Redux/Slices/article/articleSlice";
import ReptureStockTable from "../../Components/Caisses/Articles/ReptureStockTable";
import { findDepotsDefautByOrganisationApi } from "../../_App/Redux/Slices/depot/depotSlice";

const ReptureStock = () => {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const parametrage = useAppSelector(parametrageCaisse);

  useEffect(() => {
    const fetch = async () => {
      if (currentOrganisation) {
        const resParametrage = await dispatch(
          findParametragesCaisseByOrganisationApi(currentOrganisation)
        );
        const resDepot = await dispatch(
          findDepotsDefautByOrganisationApi(currentOrganisation)
        );
        if (resParametrage.payload.length && resDepot.payload)
          dispatch(
            findReptureDeStock({
              depot_id: resDepot.payload._id,
              min: resParametrage.payload[0].minimalStock,
            })
          );
      }
    };
    fetch();
  }, [dispatch]);

  const reptures = useAppSelector(ReptureStockList);

  return (
    <div>
      <Navbar titre={"Repture de stock"} />

      <div className="d-flex justify-content-end mb-3 mt-5"></div>

      {reptures && reptures.length !== 0 ? (
        <ReptureStockTable />
      ) : (
        <p>aucun repture</p>
      )}
    </div>
  );
};

export default ReptureStock;
