import React, { useState, useEffect } from "react";
import StockTable from "../../Stock/StockTable";
import Zoom from "react-medium-image-zoom";

export default function ArticleItem({
  t,
  article,
  setshowDetailArticle,
  setarticle,
  updateFunction,
  desactiverFunction,
  activerFunction,
  showonecodeabarre,
  listdepots,
  setshowModalStock,
  setarticleselected
}: any) {


  return (
    <div className="card component-card_3 mb-2">

      <div className="card-body">
        {article.image && article.image!== null &&  article.image.length !== 0? (
          <Zoom>
          <img
            alt="image"
            src={process.env.REACT_APP_API_PUBLIC_URL + 'article/' + article?.image}
            width="30"
            height="30"
          />
        </Zoom>

        ):
        <img src='assets/assets/img/tshirt.png' className="card-img-top" alt="..." />

        }
        <h5 className="card-user_name"> {article?.titre?.fr}</h5>
        <p className="card-user_occupation  lien-bleu-souligne" onClick={()=>{setarticle(article);setshowDetailArticle(true)}}>{article?.description?.fr}{' '}{article?.type}</p>

        <p>
          {" "}
          <span
            className="c-primary"
            onClick={() => showonecodeabarre(article)}
          >
            {t('print code a barre')} <i className="fas fa-barcode mr-2"></i>
          </span>
          {article.code_a_barre[0]}
        </p>

        {article.actif ? (
        <div className="row mt-2">
          <button
            className="btn btn-primary-app"
            onClick={() => desactiverFunction(article._id)}
          >
            {t('Desactiver')}
          </button>
          <button
            className="btn btn-primary-app mt-2"
            onClick={() => updateFunction(article)}
          >
            {t('Modifer')}
          </button>
          <span onClick={()=>{setshowModalStock(true);setarticleselected(article)}}><i className="fas fa-cubes fa-lg"></i></span>
        </div>):
         <div className="row mt-2">
        <h5 className="card-user_name"> {t('article desactivé')}</h5>

         <button
           className="btn btn-primary-app"
           onClick={() => activerFunction(article._id)}
         >
           {t('Activer')}
         </button>
      
       </div>
        }
      </div>
      

    </div>
  );
}
