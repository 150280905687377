import React from "react";
export default function SousCategoriesList({
  sousCategoriesListe,
  onEditClick,
}: any) {
  return (
    <div>
      <section className="container">
        <div className="row">
          {sousCategoriesListe&&sousCategoriesListe.map((item: any) => (
            <div className="col-xxxl-2 col-xl-3 col-lg-6 col-12">
              <div className="card component-card_2">
                <img
                  src="assets/assets/img/tshirt.png"
                  className="card-img-top"
                  alt="widget-card-2"
                />
                <div className="card-body">
                  <h5 className="card-title">{item.libelle.fr}</h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
