import Checkbox from "rc-checkbox";
import React from "react";

export default function ProductCaracteristiqueTable({
  ListProductCaracteristiques,
  onEditClick,
  deleteitem,
}: any) {
  
  return (
    
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>libelle</th>
          <th>valeurs</th>
          <th className="text-center" colSpan={2}>Action</th>
        </tr>
      </thead>
      <tbody>
        {ListProductCaracteristiques?.map((item: any) => (
          <tr key={item._id}>
            <td> {item.libelle}</td>
            <td>{item.color ? item.valeurs.map((value: any) => value.libelle + ', ') :item.valeurs.map((value: any) => value + ', ')}</td>
            <td>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        marginRight: "10px",
                      }}
                      onClick={() => deleteitem(item._id)}
                    >
                      {" "}
                      <i
                        className="fas fa-trash-alt"
                        style={{ color: "#FF0000" }}
                      ></i>
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        marginRight: "10px",
                      }}
                      onClick={() => onEditClick(item)}
                    >
                      <i
                        className="fas fa-edit"
                        style={{ color: "#06958d" }}
                      ></i>
                    </span>
                  </td>
          
          </tr>
        ))}
      </tbody>
    </table>
  );
}
/*fontSize: "2em" */
