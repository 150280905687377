import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import {
  selectCaisse,
  selectJournalCaisse,
  selectUser,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { updateJournal } from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListJournalMouvementsCaisse,
  ListMouvementsCaisse,
  findMouvementsCaisseByJournalApi,
} from "../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import { MouvementCaisseType } from "../../_App/Types/Entites/MouvementCaisseType";
import { OrderType } from "../../_App/Types/Entites/Orders/OrderType";
import { selectedSessionJournal } from "../../_App/Redux/Slices/Auth/authSlice";
import { clotureSessionApi } from "../../_App/Redux/Slices/sessionJournal/sessionJournalSlice";
import {
  ListJournalOrders,
  findOrdersByJournalApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import { useTranslation } from "react-i18next";

const StyledLabel = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  color: black;
  display: inline-block;
  width: 120px;
`;

const StyledInput = styled.input`
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 16px;
  width: 200px;
  display: inline-block;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 4px;
`;

const Asterisk = styled.span`
  color: red;
  margin-right: 5px;
  font-weight: bold;
`;

const RedText = styled.p`
  color: red;
  font-weight: bold;
`;

const LargerConfirmationModal = styled(Modal)`
  .modal-dialog {
    max-width: 45%;
    margin: 1.75rem auto;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const CustomCheckbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    border: 2px solid #007bff;
  }
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  color: black;
`;

const JournalCaisseClotureForm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const caisse = useAppSelector(selectCaisse);
  const user = useAppSelector(selectUser);
  const listMouvementsCaisse = useAppSelector(ListJournalMouvementsCaisse);
  const journalCaisse = useAppSelector(selectJournalCaisse);
  const [fontFinal, setFontFinal] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [printTicket, setPrintTicket] = useState(false);
  const [showReglementTicket, setShowReglementTicket] = useState(false);
  const orders = useAppSelector(ListJournalOrders);
  const [showStatistique, setShowStatistique] = useState(false);
  const selectedSessionJournalData = useAppSelector(selectedSessionJournal);
  const [printSessionTicket, setPrintSessionTicket] = useState(false);

  const [session_id, setSessionId] = useState<string | undefined>(
    selectedSessionJournalData?._id
  );

  const [fondFinalSessionValue, setFondFinalSessionValue] = useState<
    number | undefined
  >(undefined);

  const { t } = useTranslation();
  useEffect(() => {
    if (journalCaisse?._id) {
      dispatch(findOrdersByJournalApi(journalCaisse._id));
      dispatch(findMouvementsCaisseByJournalApi(journalCaisse._id));
    }
  }, [dispatch, journalCaisse]);

  useEffect(() => {
    if (session_id && fondFinalSessionValue !== undefined) {
    }
  }, [session_id, fondFinalSessionValue]);

  const handleStatistiqueCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowStatistique(e.target.checked);
  };

  useEffect(() => {
    setSessionId(selectedSessionJournalData?._id);
  }, [selectedSessionJournalData]);
  const handleCloture = async () => {
    try {
      if (
        caisse &&
        user &&
        journalCaisse &&
        journalCaisse._id &&
        journalCaisse.fond_courant
      ) {
        if (Number(fontFinal) === journalCaisse.fond_courant) {
          await performCloture();
        } else {
          setShowModal(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReglementTicketCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowReglementTicket(e.target.checked);
  };
  const handleCheckboxSessionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrintSessionTicket(e.target.checked);
  };

  const performCloture = async () => {
    try {
      if (journalCaisse && session_id) {
        const response = await dispatch(
          updateJournal({
            id: journalCaisse._id,
            data: { font_final: Number(fontFinal), cloture: true },
          })
        );

        if (response.payload && response.payload.success) {
          ToastSuccess(response.payload.message);
          const fontFinalValue = response.payload.data.font_final;
          onClose();

          if (session_id && selectedSessionJournalData) {
            if (!selectedSessionJournalData.cloture) {
              const sessionResponse = await dispatch(
                clotureSessionApi({
                  id: session_id,
                  fond_final: fontFinalValue,
                })
              );

              if (sessionResponse.payload && sessionResponse.payload.success) {
                ToastSuccess("session cloturé avec succès");

                setFondFinalSessionValue(
                  sessionResponse.payload.data.fond_final
                );

                if (printTicket) {
                  printClotureTicket(response.payload.data);
                }

                if (showReglementTicket) {
                  printReglementTicket();
                }
                if (showStatistique) {
                  printStatistique();
                }

                if (printSessionTicket) {
                  printClotureSessionTicket(
                    response.payload.data,
                    sessionResponse.payload.data.fond_final
                  );
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const printClotureSessionTicket = (data: any, fondFinalSessionValue: any) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

    const printWindow = window.open("", "cloture", "width=900,height=500");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
          <title>Ticket de cloture  de session</title>            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color: #000;
                text-align: center;
              }
              h3 {
                font-size: 11px;
                color: #000;
                margin-bottom: 10px;
                text-align: center;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
              
            </style>
          </head>
          <body>
            <h2>Cloture session journal</h2>
            <h3>${formattedDate}</h3>
            <hr />
            <p>Organisation: ${user?.selected_organisation?.nom}</p>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Fond initial: ${selectedSessionJournalData?.fond_initial}</p>
            <p>Fond final: ${fondFinalSessionValue}</p>
            <p>Code Journal : ${journalCaisse?.code_journal}</p>

            
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };
  const printStatistique = () => {
    const printWindow = window.open("", "Stat", "width=900,height=500");

    if (printWindow) {
      const totalAmount = calculateTotalAmount();
      const totalHT = calculateTotalHT();
      const totalArticles = calculateTotalArticles();
      const ticketMoyenTTC = totalAmount / orders.length;
      const ticketMoyenHT = totalHT / orders.length;
      const nombreDeTicket = orders.length;
      const nombreArticleMoyen = totalArticles / orders.length;
      const ticketMaxTTC = findTicketMaxTTC(orders);
      const ticketMinTTC = findTicketMinTTC(orders);

      printWindow.document.write(`
      <html>
      <head>
        <title>Liste des statistiques</title>
        <style>
          body {
            font-family: Verdana, sans-serif;
            margin: 0;
            padding: 0;
            width:18cm; 
          }
          h2 {
            font-size: 9px; 
          }
         
          table {
            width: 100%; 
            border-collapse: collapse;
            margin-top: 10px;
            margin-bottom: 20px; 
          }
          th, td {
            border: 1px solid gray;
            padding: 6px; 
            text-align: left;
            font-weight: normal;
            font-size: 12px; 
          }
          .statistiques-ticket {
            border: 1px solid gray;
            padding: 10px;
            margin-top: 10px;
            font-size: 12px;
          }
          .statistiques-title {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .statistiques-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-size: 12px;
          }
        </style>
      </head>
      <body>

        <table>
          <tr>
            <th>Vendeur</th>
            <th>Montant TTC</th>
            <th>Montant H.T.</th>
            <th>Nombre d'articles</th>
            <th>Nb. ticket</th>
            <th>Ticket moyen</th>
          </tr>
          <tr>
            <td>${user?.nom} ${user?.prenom}</td>
            <td>${totalAmount}</td>
            <td>${totalHT}</td>
            <td>${totalArticles}</td>
            <td>${orders.length}</td>
            <td>${ticketMoyenTTC.toFixed(2)}</td>
          </tr>
        </table>
        <div class="statistiques-ticket">
        <p class="statistiques-title">Statistiques Ticket</p>
        <div class="statistiques-row">
          <p>Ticket moyen TTC : ${ticketMoyenTTC.toFixed(2)}</p>
          <p>Ticket moyen H.T : ${ticketMoyenHT.toFixed(2)}</p>
        </div>
        <div class="statistiques-row">
          <p>Ticket max TTC : ${ticketMaxTTC.toFixed(2)}</p>
          <p>Nombre de Ticket : ${nombreDeTicket}</p>
        </div>
        <div class="statistiques-row">
          <p>Ticket min TTC : ${ticketMinTTC.toFixed(2)}</p>
          <p>Nb. article moyen : ${nombreArticleMoyen.toFixed(2)}</p>
        </div>
      </div>
    </body>
  </html>
    
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };
  const findTicketMaxTTC = (orders: OrderType[]) => {
    return orders.reduce((maxTTC, order) => {
      const orderTotalTTC = order.items.reduce((itemTotalTTC, item) => {
        return (
          itemTotalTTC +
          (typeof item.prix_total === "number" ? item.prix_total : 0)
        );
      }, 0);

      return Math.max(maxTTC, orderTotalTTC);
    }, 0);
  };

  const findTicketMinTTC = (orders: OrderType[]) => {
    return orders.reduce((minTTC, order) => {
      const orderTotalTTC = order.items.reduce((itemTotalTTC, item) => {
        return (
          itemTotalTTC +
          (typeof item.prix_total === "number" ? item.prix_total : 0)
        );
      }, 0);

      return minTTC === 0 ? orderTotalTTC : Math.min(minTTC, orderTotalTTC);
    }, 0);
  };

  const calculateTotalAmount = () => {
    const totalAmount = orders.reduce((orderTotal, order) => {
      return (
        orderTotal +
        order.items.reduce((itemTotal, item) => {
          const itemTotalPrice =
            typeof item.prix_total === "number" ? item.prix_total : 0;
          return itemTotal + itemTotalPrice;
        }, 0)
      );
    }, 0);

    return totalAmount;
  };
  const calculateTotalHT = () => {
    const totalHT = orders.reduce((orderTotalHT, order) => {
      return (
        orderTotalHT +
        order.items.reduce((itemTotalHT, item) => {
          const itemTotalPriceHT =
            typeof item.article.prix_vente_ht === "number"
              ? item.article.prix_vente_ht
              : 0;
          return itemTotalHT + itemTotalPriceHT;
        }, 0)
      );
    }, 0);

    return totalHT;
  };

  const calculateTotalArticles = () => {
    const totalArticles = orders.reduce((orderTotalArticles, order) => {
      return (
        orderTotalArticles +
        order.items.reduce((itemTotalArticles, item) => {
          return itemTotalArticles + item.quantity;
        }, 0)
      );
    }, 0);

    return totalArticles;
  };

  const printReglementTicket = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

    const reglementData: MouvementCaisseType[] = listMouvementsCaisse;

    const cashMovements = reglementData.filter(
      (reglement) => reglement.payment_method === "Cash"
    );
    const chequeMovements = reglementData.filter(
      (reglement) => reglement.payment_method === "Chéque"
    );

    const typeMovementsMap = new Map<string, MouvementCaisseType[]>();

    reglementData.forEach((reglement) => {
      const typeKey: string = `${reglement.type_mouvement_id?._id ?? ""}`;

      if (!typeMovementsMap.has(typeKey)) {
        typeMovementsMap.set(typeKey, []);
      }

      typeMovementsMap.get(typeKey)?.push(reglement);
    });

    const totalCash = cashMovements.reduce((total, reglement) => {
      const montant =
        reglement.type_mouvement_id.sens === "d"
          ? -reglement.montant
          : reglement.montant;
      return total + montant;
    }, 0);

    const totalCheque = chequeMovements.reduce(
      (total, reglement) => total + reglement.montant,
      0
    );

    const total = totalCheque + totalCash;

    const printWindow = window.open("", "Reglement", "width=900,height=500");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Liste des règlements</title>
            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color: #000;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
              .hr-text {
                position: absolute;
                top: -8px;
                left: 50%;
                background-color: white;
                padding: 0 5px;
              }
            </style>
          </head>
          <body>
            <h2>Liste des règlements</h2>
            <h2>${formattedDate}</h2>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Caisse :${caisse?.libelle}</p></p>
            <hr />
      `);

      if (cashMovements.length > 0) {
        printWindow.document.write(`
          <p style="font-weight: bold;">Espèces</p>
        `);
        cashMovements.forEach((reglement) => {
          const displayMontant =
            reglement.type_mouvement_id.sens === "d"
              ? `-${reglement.montant}`
              : reglement.montant;

          printWindow.document.write(`
            <div style="display: flex; justify-content: space-between; flex-direction: row-reverse;">
              <p>${displayMontant}</p>
              <p>${reglement.operation_code}</p>
            </div>
          `);
        });

        printWindow.document.write("<hr />");
        printWindow.document.write(`
          <p style="font-weight: bold;">Total espèces  : ${totalCash}</p><hr/>
        `);
      }

      if (chequeMovements.length > 0) {
        printWindow.document.write(`
          <p style="font-weight: bold;">Chèque</p>
        `);
        chequeMovements.forEach((reglement) => {
          printWindow.document.write(`
            <div style="display: flex; justify-content: space-between; flex-direction: row-reverse;">
              <p>${reglement.montant}</p>
              <p>${reglement.operation_code}</p>
            </div>
          `);
        });

        printWindow.document.write("<hr />");
        printWindow.document.write(`
          <p style="font-weight: bold;">Total Chèque  : ${totalCheque}</p><hr/>
        `);
      }

      typeMovementsMap.forEach((movements, typeKey) => {
        const typeMouvement = reglementData.find(
          (reglement) => reglement.type_mouvement_id?._id === typeKey
        )?.type_mouvement_id;

        if (typeMouvement) {
          printWindow.document.write(`
            <p style="font-weight: bold;">${typeMouvement.libelle}</p>
          `);

          movements.forEach((reglement) => {
            const displayMontant =
              reglement.type_mouvement_id.sens === "d"
                ? `-${reglement.montant}`
                : reglement.montant;

            printWindow.document.write(`
              <div style="display: flex; justify-content: space-between; flex-direction: row-reverse;">
                <p>${displayMontant}</p>
                <p>${reglement.operation_code}</p>
              </div>
            `);
          });

          printWindow.document.write("<hr />");
        }
      });

      printWindow.document.write(`
        <h2>Total  : ${total}</h2>
      </body>
    </html>
  `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };

  const openPrintWindow = (title: string) => {
    return window.open("", title, "width=1000,height=1000");
  };

  const printClotureTicket = (data: any) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

    const printWindow = window.open("", "cloture", "width=900,height=500");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Ticket de caisse</title>
            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color: #000;
                text-align: center;
              }
              h3 {
                font-size: 11px;
                color: #000;
                margin-bottom: 10px;
                text-align: center;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
              
            </style>
          </head>
          <body>
            <h2>Cloture caisse</h2>
            <h3>${formattedDate}</h3>
            <hr />
            <p>Organisation: ${user?.selected_organisation?.nom}</p>
            <p>Caisse: ${data.libelle_caisse}</p>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Fond initial: ${data.font_initial}</p>
            <p>Fond final: ${data.font_final}</p>
            <p>Pièce N°: ${data.code_journal}</p>

            
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };

  const handleFontFinalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    value = value.replace(/[^0-9.]/g, "");

    if (!/^\d+(\.\d*)?$/.test(value)) {
      setErrorMessage("Veuillez saisir un montant valide");
    } else {
      setErrorMessage("");
    }

    setFontFinal(value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrintTicket(e.target.checked);
  };

  const handleConfirmation = () => {
    setShowModal(false);
    performCloture();
  };

  return (
    <Modal show={true} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5
            onClick={() =>
              console.log(fontFinal.trim(), String(journalCaisse?.fond_courant))
            }
          >
            {" "}
            {t("Clôturer journal caisse")}
          </h5>
        </Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleCloture();
        }}
      >
        <Modal.Body>
          <StyledLabel>
            {t("Fond finale")} <Asterisk>*</Asterisk>
          </StyledLabel>
          <StyledInput
            type="number"
            value={fontFinal}
            onChange={handleFontFinalChange}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="printTicketCheckbox"
              checked={printTicket}
              onChange={handleCheckboxChange}
            />
            <CheckboxLabel htmlFor="printTicketCheckbox">
              {t("Imprimer le ticket de clôture de caisse")}
            </CheckboxLabel>
          </CheckboxContainer>

          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="showReglementTicketCheckbox"
              checked={showReglementTicket}
              onChange={handleReglementTicketCheckboxChange}
            />
            <CheckboxLabel htmlFor="showReglementTicketCheckbox">
              {t("Imrpimer la liste des règlements")}
            </CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="showStatistiqueCheckbox"
              checked={showStatistique}
              onChange={handleStatistiqueCheckboxChange}
            />
            <CheckboxLabel htmlFor="showStatistiqueCheckbox">
              {t("Imprimer la liste des statistiques")}
            </CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="printTicketCheckbox"
              checked={printSessionTicket}
              onChange={handleCheckboxSessionChange}
            />
            <CheckboxLabel htmlFor="printTicketCheckbox">
              {t("Imprimer le ticket de clôture session caisse")}
            </CheckboxLabel>
          </CheckboxContainer>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="submit"
            className="btn btn-primary-app"
            disabled={!fontFinal.trim() || errorMessage !== ""}
          >
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>

      <LargerConfirmationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              "Merci de vérifier le fond final car il y a une différence. Cliquez sur Enregistrer si vous êtes sûr que le fond final est :"
            )}{" "}
            <RedText>{fontFinal.trim()} TND</RedText>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={() => setShowModal(false)}
          >
            {t("Annuler")}
          </Button>
          <Button className="btn btn-primary-app" onClick={handleConfirmation}>
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </LargerConfirmationModal>
    </Modal>
  );
};

export default JournalCaisseClotureForm;
