import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListArticles,
  findArticleApi,
  findArticleByBarCode,
  findArticlebyidApi,
  findArticlebysocieteApi,
} from "../../_App/Redux/Slices/article/articleSlice";
import {
  SelectedOrganisations,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListUsers,
  findUserBySocieteIdApi,
} from "../../_App/Redux/Slices/users/userSlice";
import { Modal } from "react-bootstrap";
import CommissionType from "../../_App/Types/Entites/Commission/parametrage_Commission";
import { CommissionSchema } from "../../_App/Schemas/CommissionSchema";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";
import {
  createCommissionApi,
  updateCommissionApi,
  updateparametrageCommissionApi,
} from "../../_App/Redux/Slices/commission/commissionSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import parametrage_CommissionType from "../../_App/Types/Entites/Commission/parametrage_Commission";
import moment from "moment";
import ConditionCommission from "./ConditionCommission";
import { toast } from "react-toastify";
import condition_CommissionType from "../../_App/Types/Entites/Commission/condition_CommissionType";
import { findCarteBySocieteApi } from "../../_App/Redux/Slices/cartes/carteSlice";
import { findCategorieBySocieteApi } from "../../_App/Redux/Slices/categories/categorieSlice";
import { findSousCategoriesApi } from "../../_App/Redux/Slices/souscategories/souscategorieSlice";

export default function CommissionForm({
  t,
  societe_id,
  ShowModal,
  setShowModal,
  commission,
}: any) {
  // init form hooks
  const formOptions = {
    resolver: yupResolver(
      CommissionSchema
    ) as unknown as Resolver<parametrage_CommissionType>,
  };
  useForm<parametrage_CommissionType>(formOptions);
  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    formState: { errors: errorsForm1 },
    reset: resetForm1,
    getValues: getValue1,
    setValue: setValue1,
    watch: watch1,
  } = useForm<parametrage_CommissionType>();
  const [inputValue, setInputValue] = useState<any[]>([]);

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
    reset: resetForm2,
    getValues: getValue2,
    setValue: setValue2,
    watch: watch2,
  } = useForm<condition_CommissionType>({});
  const dispatch = useAppDispatch();
  const societeid = useAppSelector(societeId);
  const list_organisation = useAppSelector(SelectedOrganisations);
  const user = useAppSelector(selectUserId);
  const list_article = useAppSelector(ListArticles);
  const list_user = useAppSelector(ListUsers);
  const [optionsorg, setoptionsorg] = useState<any[]>();
  const [optionsUser, setoptionsUser] = useState<any[]>();
  const [optionsArticle, setoptionsArticler] = useState<any[]>();
  const [organisationchoix, setorganisationchoix] = useState(false);
  const [listuserselected, setlistuserselected] = useState<string[]>([]);
  const [listorganisationselected, setlistorganisationselected] = useState<
    string[]
  >([]);
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [articleInputs, setarticleInputs] = useState<any[]>([""]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);

  const [articles, setarticles] = useState<any[]>([""]);
  useEffect(() => {
    if (societeid) {
      dispatch(findCarteBySocieteApi(societeid));
      dispatch(findCategorieBySocieteApi(societeid));
      dispatch(findSousCategoriesApi(societeid));
    }
  }, [societeid]);
  useEffect(() => {
    if (commission) {
      resetForm1({
        _id: commission._id,
        commission: commission.commission,
        date_debut: moment(commission.date_debut).format("YYYY-MM-DD"),
        date_fin: moment(commission.date_fin).format("YYYY-MM-DD"),
        conditions: commission.conditions,
        libelle: commission.libelle,
        organisation_id: commission.organisation_id,
        societe_id: commission.societe_id,
      });
      if (
        commission?.conditions_comm &&
        Array.isArray(commission.conditions_comm) &&
        commission.conditions_comm.length > 0
      ) {
        const condi = commission.conditions_comm; // Note: Ensure the property name is correct
        resetForm2({
          organisation_id: condi[0]?.organisation_id,
          user_id: condi[0]?.user_id, // Fixed index here for consistency
          type: condi[0]?.type,
          min: condi[0]?.min,
          max: condi[0]?.max,
          carte: condi[0]?.carte,
          categorie: condi[0]?.categorie,
          souscategorie: condi[0]?.souscategorie,
          montant_achat: condi[0]?.montant_achat,
          conditions: condi[0]?.conditions,
          parametrage_commission: condi[0]?.parametrage_commission,
        });
        setlistuserselected(condi[0]?.user_id);
        setlistorganisationselected(condi[0]?.organisation_id);
        setSelectedCarte(condi[0]?.carte);
        setSelectedCategorie(condi[0]?.categorie);
        setSelectedSousCategorie(condi[0]?.souscategorie);
        updateArticles(condi[0].article);
      }
    } else {
      setValue2("min", 1);
      setValue1("commission", 0);

      setValue2("max", 100);
      setValue2("type", "quantite");
    }
  }, [commission]);
  async function updateArticles(list: any[]) {
    for (let i = 0; i < list.length; i++) {
      const element: string = list[i];
      const articleNames = getValue2("articleNames") || [];
      const resultArticle = await dispatch(findArticlebyidApi(element));

      if (resultArticle.payload) {
        const listaricle = getValue2("article") || [];

        const newArticleIds = listaricle.length
          ? [...listaricle, resultArticle?.payload?._id]
          : [resultArticle?.payload?._id];

        const newArticleNames = articleNames.length
          ? [...articleNames, resultArticle?.payload?.titre?.fr]
          : [resultArticle?.payload?.titre?.fr];

        setValue2("article", newArticleIds);
        setValue2("articleNames", newArticleNames);

        setInputValue(newArticleNames);
      }
    }
  }

  useEffect(() => {
    if (societe_id) {
      dispatch(findUserBySocieteIdApi(societe_id));
    }
  }, [dispatch, societe_id]);

  useEffect(() => {
    if (list_organisation) {
      const option = list_organisation.map((item: any) => ({
        value: item._id,
        label: item.nom,
      }));
      setoptionsorg(option);
    }
  }, [list_organisation]);

  useEffect(() => {
    if (list_user) {
      const option = list_user.map((item: any) => ({
        value: item._id,
        label: `${item.nom}  ${item.prenom}`,
      }));
      setoptionsUser(option);
    }
  }, [list_user]);

  useEffect(() => {
    if (list_article) {
      const option = list_article.map((item: any) => ({
        value: item._id,
        label: item.titre?.fr,
      }));
      setoptionsArticler(option);
    }
  }, [list_article]);

  const handleSelectChangeUser = (selectedOption: any) => {
    const listUser = selectedOption.map((item: any) => item.value);
    setlistuserselected(listUser);
    setValue2("user_id", listUser);
  };

  const handleSelectChangeOrganisation = (selectedOption: any) => {
    const listorg = selectedOption.map((item: any) => item.value);
    setlistorganisationselected(listorg);
    setValue2("organisation_id", listorg);
  };

  const save = async (e: any) => {
    e.preventDefault();
    //active true

    const entet = watch1();
    if (
      entet.libelle === "" ||
      entet.commission === 0 ||
      entet.conditions === null ||
      entet.date_debut === "" ||
      entet.date_fin === ""
    ) {
      ToastWarning("Veuillez remplir tous les champs  de l'en-tête de condition ")
    } else {
      const condition = watch2();
      let res;
      if (entet._id) {
        const data = { commission: entet, condition: condition };
        res = await dispatch(updateCommissionApi(data));
      } else {
        if (list_organisation) {
          entet.organisation_id = list_organisation[0]._id;
        }
        if (societeid) {
          entet.societe_id = societeid;
        }
        if (user) entet.user_id = user; //setValue1("user_id", user);
        // setValue1("active", true);
        entet.active = true;
        const data = { commission: entet, condition: condition };
        res = await dispatch(createCommissionApi(data));
      }
      if (res.payload.success) {
        ToastSuccess(res.payload.message);
        setShowModal(false);
      } else {
        ToastWarning(res.payload.message);
      }
    }
  };

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-50w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header>
          <Modal.Title className="c-primary">
            {" "}
            {t("Ajouter Commission")}
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>

        <form>
          <Modal.Body>
            <div className="row">
              <div className="col-4">
                <strong>{t("Libelle")}</strong>

                <input
                  type="text"
                  className={`form-control `}
                  {...registerForm1("libelle")}
                />
              </div>
              <div className="col-3 ">
                <strong>{t("Commission")}</strong>

                <input
                  type="text"
                  className={`form-control `}
                  onKeyPress={handleKeyPress_number}
                  {...registerForm1("commission")}
                />
              </div>
              <div className="col-3 mt-3 mr-3">
                <div className="form-group">
                  <label>
                    <strong>{t(" Et")}</strong>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="et"
                    {...registerForm1("conditions")}
                    value={"et"}
                  />
                  <label htmlFor="ou">
                    <strong>{t("Ou")}</strong>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="ou"
                    value={"ou"}
                    {...registerForm1("conditions")}
                  />
                </div>
              </div>

              <div className="col-6 mt-3">
                <strong>{t("date debut")}</strong>

                <input
                  type="Date"
                  className={`form-control `}
                  {...registerForm1("date_debut")}
                />
              </div>
              <div className="col-6 mt-3">
                <strong>{t("date fin")}</strong>

                <input
                  type="Date"
                  className={`form-control `}
                  {...registerForm1("date_fin")}
                />
              </div>
            </div>
            <div className="row mt-5">
              <ConditionCommission
                t={t}
                optionsorg={optionsorg}
                handleSelectChangeOrganisation={handleSelectChangeOrganisation}
                listorganisationselected={listorganisationselected}
                optionsUser={optionsUser}
                handleSelectChangeUser={handleSelectChangeUser}
                listuserselected={listuserselected}
                register={registerForm2}
                getValue={getValue2}
                setValue={setValue2}
                inputValue={inputValue}
                setInputValue={setInputValue}
                selectedCarte={selectedCarte}
                setSelectedCarte={setSelectedCarte}
                selectedCategorie={selectedCategorie}
                setSelectedCategorie={setSelectedCategorie}
                selectedSousCategorie={selectedSousCategorie}
                setSelectedSousCategorie={setSelectedSousCategorie}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t("Annuler")}
            </button>
            <button onClick={save} className="btn btn-primary-app">
              {t("Enregistrer")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
