import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import { ListClient } from "../../_App/Redux/Slices/client/clientSlice";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import {
  ajouterDureeToDate,
  formadate,
  formatdate,
} from "../../_App/Helpers/helpers";

export default function HeaderReservationList({
  t,
  listreservation,
  setlistfiltrer,
}: any) {
  const [datedebut, setdatedebut] = useState(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(today.getDate() - 7).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  });
  const [date_echeance, setdate_echeance] = useState(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  });

  const [keyword, setkeyword] = useState("");

  useEffect(() => {
    setlistfiltrer(listreservation);
  }, [listreservation]);

  // Fonction pour filtrer la liste de réservations
  const handleSearch = () => {
    const lowercaseKeyword = keyword.toLowerCase();

    const listFiltrer = listreservation.filter(
      (item: any) =>
        item?.order.order_number?.toLowerCase().includes(lowercaseKeyword) ||
        item?.infoclient?.nom?.toLowerCase().includes(lowercaseKeyword) ||
        item?.infoclient?.mobile?.toLowerCase().includes(lowercaseKeyword)
    );
    setlistfiltrer(listFiltrer);
  };
  const handleSearchdate = (dated: any) => {
    if (dated === null) {
      setlistfiltrer(listreservation);
      return;
    }

    const listFiltrer = listreservation.filter(
      (item: any) => formadate(item.date.split("T")[0]) === formadate(dated)
    );
    setlistfiltrer(listFiltrer);
  };

  return (
    <div className="container  mb-4">
      <div className="row">
        <div className="col-md-4">
          <label>{t("Date de creation")}</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-calendar-alt"></i>{" "}
              </span>
            </div>
            <input
              type="date"
              className="form-control "
              value={datedebut}
              onChange={(e) => {
                setdatedebut(e.target.value);
                handleSearchdate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <label>{t("Date d'echeance")}</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-calendar-alt"></i>{" "}
              </span>
            </div>
            <input
              type="date"
              className="form-control "
              value={date_echeance}
              onChange={(e) => {
                setdate_echeance(e.target.value);
                handleSearchdate(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="col-md-4">
          <label>{t("Chercher par mot clé")}</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={t("Entrez un mot-clé")}
              onChange={(e) => setkeyword(e.target.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => handleSearch()}
              >
                <i className="fas fa-search"></i>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
