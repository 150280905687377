import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  findsocieteinfoApi,
  societeinfo,
} from "../../../_App/Redux/Slices/societe/societeSlice";
import { societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { numeroBonApi } from "../../../_App/Redux/Slices/transferArticle/transferArticleSlice";

import ReactToPrint from "react-to-print";

export default function BonTransfert({
  societeid,
  data,
  t,
  type,
  setshowbon,
}: any) {
  const dispatch = useAppDispatch();
  const infosociete = useAppSelector(societeinfo);
  const BonRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    dispatch(findsocieteinfoApi(societeid));
  }, [dispatch, societeid]);

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <button
            className=" btn-primary-app pointer"
            style={{ margin: "20px" }}
          >
            {t("Print")}
          </button>
        )}
        content={() => BonRef.current}
      />
      <button
        className=" btn-primary-app pointer"
        onClick={() => setshowbon(false)}
      >
        x
      </button>
      <div
        ref={BonRef}
       className="bon"
      >
        <h1 style={{ textAlign: "center" }}>{infosociete?.nom}</h1>
        <p>
          {t("Matricule fiscale")} : {infosociete?.matricule_fiscale}
        </p>

        <h2>
          {t(`Bon de ${type}   N°`)}
          {type === "transfert" ? data.NumBontransfert : data.NumBonreception}
        </h2>
        {type === "transfert" ? (
          <>
            <p>
              {t("Organisation destocké")}: {data.organisation_transfer?.nom}
            </p>
            <p>
              {t("Dépôt destocké:")} {data.depots_id?.libelle}
            </p>
            <p>
              {t("Organisation receveur:")} {data.organisation_demande?.nom}
            </p>
            <p>
              {t("Dépôt receveur:")} {data.depots_reception?.libelle}
            </p>
            <p>
              Utilisateur :
              {data.accepter_transfer.user_id.nom +
                " " +
                data.accepter_transfer.user_id.prenom}
            </p>
          </>
        ) : (
          <>
            <p>
              {t("Organisation receveur")}: {data.organisation_demande?.nom}
            </p>
            <p>
              {t("Dépôt receveur:")} {data?.depots_reception?.libelle}
            </p>
            <p>
              {t("Organisation destocké:")} {data?.organisation_transfer?.nom}
            </p>

            <p>
              {t("Date reception")}:{" "}
              {data?.approver_reception?.date
                ? data.approver_reception.date.split("T")[0]
                : new Date().toISOString().split("T")[0]}
            </p>
            <p>
              {t("Utilisateur")} :
              {data.approver_reception?.user_id?.nom +
                " " +
                data.approver_reception?.user_id?.prenom}
            </p>
          </>
        )}
        <p>
          {t("Date Transfert")}: {data?.accepter_transfer.date?.split("T")[0]}
        </p>

        <p>
          {t("Agent de livraison")} :
          {data?.agentLivraison?.nom + " " + data?.agentLivraison?.prenom}
        </p>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Article")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Code Article")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Famille")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Quantité")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.lignes?.map((item: any) => (
              <tr>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.titre?.fr}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.code_article}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.souscategorie?.libelle?.fr}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.Quantiteenvoyee || item.Quantite}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row mt-3 mb-3 ">
          <strong className="col-2">Note :    </strong>
          <p >{data?.note}</p>
        </div>

        {type === "transfert" ? (
          <div className="row">
            <div className="col-6">
              <strong>{t("Signature de l'emetteur")}</strong>
            </div>
            <div className="col-6">
              <strong>{t("Signature  du demandeur")}</strong>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6">
              <strong>{t("Signature de l'emetteur")}</strong>
            </div>
            <div className="col-6">
              <strong>{t("Signature  du récepteur")}</strong>
            </div>
          </div>
        )}
        <div className="row ">
          <div className="col-6"></div>
          <div className="col-6"></div>
        </div>
      </div>
    </div>
  );
}
