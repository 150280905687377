import React from 'react';

export default function PaginationLayout( {index, nbrpage, totalpages ,setindex }:any) {
  // Détermine les numéros de page à afficher
  const range = 3;
  let startPage:number, endPage :number;

  if (nbrpage <= range) {
    startPage = 1;
    endPage = nbrpage;
  } else {
    if (index <= Math.ceil(range / 2)) {
      startPage = 1;
      endPage = range;
    } else if (index + Math.floor(range / 2) >= nbrpage) {
      startPage = parseInt(nbrpage) - range + 1;
      endPage = nbrpage;
    } else {
      startPage = parseInt(index )- Math.floor(range / 2);
      endPage = index + Math.floor(range / 2);
    }
  }

  return (
    <><p>Total page :{totalpages} </p><div className="paginating-container pagination-solid">

      <ul className="pagination">
        {index > 3 && (
          <li
            className="prev"
            onClick={() => setindex(index - 3)}
          >
            <i className="fas fa-angle-double-left"></i>
          </li>
        )}

        {index > 1 &&
          <li
            className="prev"
            onClick={() => setindex(index - 1)}
          >
            <i className="fas fa-chevron-left"></i>
          </li>}


        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((n) => (
          <li key={n} className={index === n ? "pointer active" : "pointer"}>
            <a onClick={() => setindex(n)}>{n}</a>
          </li>
        ))}
        {index < totalpages && <li
          className="next"
          onClick={() => setindex(index + 1)}
        >
          <i className="fas fa-chevron-right"></i>
        </li>}
        {index < totalpages - 3 && <li
          className="next"
          onClick={() => setindex(index + 3)}
        >
          <i className="fas fa-angle-double-right"></i>
        </li>}


      </ul>
    </div></>
  );
}
