import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Produit } from "../../../Types/Entites/Produit";
import { PaginationType } from "../../../Types/Entites/PaginationType";
import axios from "axios";
import _ from "lodash";

/**
 * create Products  function
 * @private
 */
export const createProductApi = createAsyncThunk(
  "produit/createProductApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const {
        titre,
        description,
        marque_id,
        carte_id,
        category_id,
        matrice,
        fournisseurs,
        prix_achat_ttc,
        prix_achat_ht,
        organisation_id,
        SousCategory_id,
        image,
        code_a_barreProduit,
        refProduit,
        societe_id,
        tva,
        article_id,
      } = data;
      var body = new FormData();
      body.append("titre", JSON.stringify(titre));
      body.append("description", JSON.stringify(description));
      console.log("image", image);

      console.log(organisation_id);
      const organisation =
        organisation_id !== null ? organisation_id.toString() : "null";

      const matricetostring =
        matrice && matrice !== null ? matrice.toString() : "null";
      body.append("organisation_id", organisation.toString());
      body.append("tva", tva.toString());

      body.append("prix_achat_ht", prix_achat_ht.toString());
      body.append("prix_achat_ttc", prix_achat_ttc.toString());
      body.append("societe_id", societe_id.toString());
      body.append("code_a_barreProduit", code_a_barreProduit.toString());
      body.append("refProduit", refProduit.toString());
      body.append("marque_id", marque_id.toString());
      body.append("carte_id", carte_id.toString());
      console.log( article_id.toString())
      body.append("category_id", category_id.toString());
      body.append("article_id", article_id.toString());
      body.append("SousCategory_id", SousCategory_id.toString());
      body.append("matrice", matricetostring);

      body.append("fournisseurs", JSON.stringify(fournisseurs));

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }
      const response = await axios.post(
        "produit/createProductApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create Products  function
 * @private
 */
export const insertListProductApi = createAsyncThunk(
  "produit/insertListProductApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };
    
      const formData = new FormData();
      formData.append('file', data);
      const response = await axios.post("produit/insertListProductApi", formData);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of produits by organisations
 * @public
 */
export const findProductByOrganisationApi = createAsyncThunk(
  "produit/findProductByOrganisationApi",
  async (idorganisation: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `produit/findProductByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of prod by fornisseur.
 * @private
 */
export const findproductfournisseurApi = createAsyncThunk(
  "depots/findproductfournisseurApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`produit/findproductfournisseurApi/${id}`);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of prod by article.
 * @private
 */
export const findproduitbyarticleIdApi = createAsyncThunk(
  "product/findproduitbyarticleIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`produit/findproduitbyarticleIdApi/${id}`);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of produits fournisseur by product
 * @public
 */
export const findProductFournisseurByProductApi = createAsyncThunk(
  "produit/findProductFournisseurByProductApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `produit/findProductFournisseurByProductApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of produits by societe
 * @public
 */
export const findProductBySocieteApi = createAsyncThunk(
  "produit/findProductBySocieteApi",

  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const {idsociete, index}=data

      const response = await axios.get(
        `produit/findProductBySocieteApi/${idsociete}/${index}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of products.
 * @public
 */
export const findProductbytitreApi = createAsyncThunk(
  "article/findProductbytitreApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const {keyword, index}=data
      const response = await axios.get(`produit/findProductbytitreApi/${encodeURIComponent(keyword)}/${index}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get   produits by id
 * @public
 */
export const findProductByIdApi = createAsyncThunk(
  "produit/findProductByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`produit/findProductByIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * update a product.
 * @public
 */

export const updateProductApi = createAsyncThunk(
  "produit/updateProductApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const {
        titre,
        description,
        marque_id,
        carte_id,
        category_id,
        caracteristiques,
        fournisseurs,
        _id,
        organisation_id,
        SousCategory_id,
        image,
        code_a_barreProduit,
        refProduit,
        societe_id,
        prix_achat_ht,
        prix_achat_ttc,
        article_id,
        changeall,
        type,
        matrice
      } = data;

      var body = new FormData();
      const matricetostring =
      matrice && matrice !== null ? matrice.toString() : "null";
      body.append("titre", JSON.stringify(titre));
      body.append("prix_achat_ht", prix_achat_ht.toString());
      body.append("changeall", changeall.toString());
      body.append("prix_achat_ttc", prix_achat_ttc.toString());
      body.append("description", JSON.stringify(description));
      body.append("caracteristiques", JSON.stringify(caracteristiques));
      body.append("matrice", matricetostring);

      const organisation =
        organisation_id !== null ? organisation_id.toString() : "null";
        
      body.append("organisation_id", organisation.toString());
      body.append("societe_id", societe_id.toString());
      body.append("_id", _id.toString());
      body.append("type", type.toString());

      body.append("code_a_barreProduit", code_a_barreProduit.toString());
      body.append("refProduit", refProduit.toString());
      const marque = marque_id !== null ? marque_id.toString() : "null";
      const carte = carte_id !== null ? carte_id.toString() : "null";
      const category = category_id !== null ? category_id.toString() : "null";
      const SousCategory =
        SousCategory_id !== null ? SousCategory_id.toString() : "null";
      const article =
        article_id && article_id !== null ? article_id.toString() : "null";

      body.append("marque_id", marque);
      body.append("carte_id", carte);
      body.append("category_id", category);
      body.append("SousCategory_id", SousCategory);
      body.append("article_id", article);

      body.append("fournisseurs", JSON.stringify(fournisseurs));

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }

      const response = await axios.put(
        "produit/updateProductApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * desactive  Products .
 * @private
 */
export const desactiveProductApi = createAsyncThunk(
  "produit/desactiveProductApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`produit/desactiveProductApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * active  Products .
 * @private
 */
export const activeProductApi = createAsyncThunk(
  "produit/activeProductApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`produit/activeProductApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get pagination.
 * @public
 */
export const updatepagination = createAsyncThunk(
  "produit/updatepagination",
  async (data: PaginationType, { rejectWithValue, dispatch }) => {
    try {
      //insert in data base
      return data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface ProduitState {
  listProduits: Produit[];
  ProduitsParCategorie: { [categoryId: string]: Produit[] };
  ProduitsParSousCategorie: { [sousCategoryId: string]: Produit[] };
  listProduitsParOrganisation: { [organisation: string]: Produit[] };
  listfournisseurparproduit: any[];
  pagination: PaginationType;
}
const initialPagination: PaginationType = {
  itemsPerPage: 10,
  NumberColone: 4,
};

const initialState: ProduitState = {
  listProduits: [],
  listfournisseurparproduit: [],
  listProduitsParOrganisation: {},
  ProduitsParCategorie: {},
  ProduitsParSousCategorie: {},
  pagination: initialPagination,
};

export const produitSlice = createSlice({
  name: "produits",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProductApi.fulfilled, (state, action) => {
      state.listProduits.splice(0, 0, action.payload.data);
    });
 
    builder.addCase(findProductbytitreApi.fulfilled, (state, action) => {
      //   const duplicatedPayload = Array(10).fill(action.payload).flat();

      const groupedByOrganisation = _.groupBy(
        action.payload,
        "organisation_id"
      );

      state.listProduits = action.payload;
      state.listProduitsParOrganisation = groupedByOrganisation;
    });

    builder.addCase(findProductByOrganisationApi.fulfilled, (state, action) => {
      const groupedProductsByCategories = _.groupBy(
        action.payload,
        "category_id"
      );
      const groupedProductsBySousCategories = _.groupBy(
        action.payload,
        "SousCategory_id"
      );

      state.listProduits = action.payload;

      state.ProduitsParCategorie = groupedProductsByCategories;
      state.ProduitsParSousCategorie = groupedProductsBySousCategories;
    });
    
    builder.addCase(findproductfournisseurApi.fulfilled, (state, action) => {
 
      state.listProduits = action.payload;

      
    });
    builder.addCase(findproduitbyarticleIdApi.fulfilled, (state, action) => {
 
      state.listProduits = action.payload;

      
    });
    builder.addCase(findProductBySocieteApi.fulfilled, (state, action) => {
      const groupedProductsByCategories = _.groupBy(
        action.payload,
        "category_id"
      );
      const groupedProductsBySousCategories = _.groupBy(
        action.payload,
        "SousCategory_id"
      );
      const groupedProductsByOrganisation = _.groupBy(
        action.payload,
        "organisation_id"
      );

      state.listProduits = action.payload;
      state.listProduitsParOrganisation = state.ProduitsParCategorie =
        groupedProductsByCategories;
      state.ProduitsParSousCategorie = groupedProductsBySousCategories;
      state.listProduitsParOrganisation = groupedProductsByOrganisation;
    });
    builder.addCase(updateProductApi.fulfilled, (state, action) => {
      const updated_product = action.payload.data;
      var index = _.findIndex(state.listProduits, {
        _id: updated_product._id,
      });
      // Replace item at index using native splice
      state.listProduits.splice(index, 1, updated_product);
    });
    builder.addCase(desactiveProductApi.fulfilled, (state, action) => {
      const updated_product = action.payload.data;
      var index = _.findIndex(state.listProduits, {
        _id: updated_product._id,
      });
      // Replace item at index using native splice
      state.listProduits.splice(index, 1, updated_product);
    });
    builder.addCase(activeProductApi.fulfilled, (state, action) => {
      const updated_product = action.payload.data;
      var index = _.findIndex(state.listProduits, {
        _id: updated_product._id,
      });
      // Replace item at index using native splice
      state.listProduits.splice(index, 1, updated_product);
    });
    builder.addCase(updatepagination.fulfilled, (state, action) => {
      return {
        ...state,
        pagination: action.payload,
      };
    });

    builder.addCase(findProductByIdApi.fulfilled, (state, action) => {
      const productById = action.payload;

      const existingProductIndex = state.listProduits.findIndex(
        (product) => product._id === productById._id
      );

      if (existingProductIndex !== -1) {
        state.listProduits[existingProductIndex] = productById;
      } else {
        state.listProduits.push(productById);
      }
    });
  },
});

export const ListProduits = (state: RootState) => state.produits.listProduits;
export const Listfournisseurparproduit = (state: RootState) =>
  state.produits.listfournisseurparproduit;

export const ListProduitsParCategorie = (state: RootState) =>
  state.produits.ProduitsParCategorie;
export const ListProduitsParOrganisation = (state: RootState) =>
  state.produits.listProduitsParOrganisation;
// export const ListProduitsParSousCategories = (state: RootState) =>
//   state.produits.ProduitsParSousCategorie;
export const PaginationProduits = (state: RootState) =>
  state.produits.pagination;

export default produitSlice.reducer;
