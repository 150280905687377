import React, { useState, useEffect } from "react";
import ArticleForm from "../../../Components/Products/Articles/ArticleForm";
import {
  SelectedOrganisationId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListArticles,
  currentpage,
  findArticleApi,
  findArticlebysocieteApi,
  findArticlebytitreApi,
  findArticlesFiltresApi,
  totalPages,
} from "../../../_App/Redux/Slices/article/articleSlice";
import ArticleList from "../../../Components/Products/Articles/ArticleList";
import { useTranslation } from "react-i18next";
import Navbar from "../../../_Layouts/Navbar/Navbar";
import { findProductBySocieteApi } from "../../../_App/Redux/Slices/produits/produitSlice";
import { findFourinsseurBySocieteApi } from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import { ArticleType } from "../../../_App/Types/Entites/ArticleType";
import PaginationLayout from "../../../_Layouts/Pagination/PaginationLayout";
import { Spinner } from "react-bootstrap";
import Filtre from "../../../Components/Stock/Filtre";
export default function ArticlePage() {
  type datafilterType = {
    carte: any;
    categories: any;
    sousCategories: any;
    carateristique: any;
    index: number;
  };
  const [datafilter, setdatafilter] = useState<datafilterType>();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [ShowModalArticle, setShowModalArticle] = useState(false);
  const [articleitem, setarticleitem] = useState(null);
  const articles = useAppSelector(ListArticles);
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);

  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  interface SelectionsState {
    [key: string]: any[];
  }
  const [selections, setSelections] = useState<SelectionsState>({});

  const [keyword, setkeyword] = useState("");
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const [loading, setloading] = useState(false);
  const [showModalcodeabarre, setshowModalcodeabarre] = useState(false);

  useEffect(() => {
    if (datafilter?.categories) {

      const copie = { ...datafilter };
      copie.index = index ;
      setdatafilter(copie);
      dispatch(findArticlesFiltresApi(copie));
    } else if (keyword !== ""){
      setloading(true);

      const data = { keyword: keyword, index: index };

      dispatch(findArticlebytitreApi(data));

    }else{
      setloading(true);
      const data = { id: societe, index: index };
      dispatch(findArticlebysocieteApi(data));
    }
   
    
  }, [dispatch, index, societe]);

  useEffect(() => {
    if (articles) {
      
      setloading(false);
    }
  }, [articles]);

  const handleSearch = async () => {
    if (keyword === "") {
      if (societe) {
        setindex(1);
        setloading(true);
        const data = { id: societe, index: 1 };
        await dispatch(findArticlebysocieteApi(data));
      }
    } else {
      setindex(1);

      setloading(true);

      const data = { keyword: keyword, index: 1 };
      await dispatch(findArticlebytitreApi(data));
    }
  };
  const searchfiltre = () => {
    setloading(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: 1,
    };
    setindex(1);
    setdatafilter(data);

    dispatch(findArticlesFiltresApi(data));
  };


  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className=" btn btn-primary-app col-2"
          onClick={() => setShowModalArticle(true)}
        >
          {t("Ajouter Article")}
        </button>
        <button className=" btn btn-primary-app col-2 ml-2"
        onClick={()=>setshowModalcodeabarre(true)}>
          {t("code a barre")}
        </button>{" "}
      </div>
      {ShowModalArticle ? (
        <ArticleForm
          ShowModal={ShowModalArticle}
          setShowModal={setShowModalArticle}
          Article={articleitem}
        />
      ) : null}
      <Filtre
        spinner={loading}
        handleSearch={handleSearch}
        setkeyword={setkeyword}
        t={t}
        searchfiltre={searchfiltre}
        setspinner={setloading}
        setdatafilter={setdatafilter}
        setindex={setindex}
        selectedCarte={selectedCarte}
        setSelectedCarte={setSelectedCarte}
        selectedCategorie={selectedCategorie}
        setSelectedCategorie={setSelectedCategorie}
        selectedSousCategorie={selectedSousCategorie}
        setSelectedSousCategorie={setSelectedSousCategorie}
        categoriesOptions={categoriesOptions}
        setcategoriesdOptions={setcategoriesdOptions}
        souscategoriesOptions={souscategoriesOptions}
        setsouscategoriesdOptions={setsouscategoriesdOptions}
        selections={selections}
        setSelections={setSelections}
      />
      {!loading ? (
        <ArticleList
          articles={articles}
          t={t}
          list_Article={articles}
          setarticleitem={setarticleitem}
          setShowModalArticle={setShowModalArticle}
          societe={societe}
          setshowModalcodeabarre={setshowModalcodeabarre}
          showModalcodeabarre={showModalcodeabarre}
        />
      ) : (
        <div className="text-center" style={{ marginTop: "300px" }}>
          <Spinner
            animation="grow"
            style={{ width: "100px", height: "100px" }}
          />
          <br />
          <strong>LOADING</strong>
        </div>
      )}
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
    </div>
  );
}
