import React from 'react'

export default function ServerError() {
  return (
<body className="error500 text-center">
    
    <div className="container-fluid">
        <div className="row">
            <div className="col-md-4 mr-auto mt-5 text-md-left text-center">
                <a href="index.html" className="ml-md-5">
                </a>
            </div>
        </div>
    </div>

    <div className="container-fluid error-content">
        <div className="">
            <h1 className="error-number">500</h1>
            <p className="mini-text">Ooops!</p>
            <p className="error-text">Internal Server Error!</p>
            <a href="index.html" className="btn btn-secondary mt-5">Go Back</a>
        </div>
    </div>

   
</body>
  )
}
