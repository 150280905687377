import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function LoadingPage() {
  return (
<body className="error500 text-center">
    
    <div className="container-fluid">
    <Spinner animation="border" />LOADING...

    </div>

 

   
</body>
  )
}
