import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CarteType } from "../../../Types/Entites/CarteType";
import axios from "axios";
import CarteDataType from "../../../Types/Forms/CarteDataType";
import _ from "lodash";

/**
 * Get list of cartes.
 * @public
 */
export const findCartesApi = createAsyncThunk(
  "carte/findCartesApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`carte/findCartesApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


/**
 * Get list of cartes par societe.
 * @public
 */
export const findCarteBySocieteApi = createAsyncThunk(
  "carte/findCarteBySocieteApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`carte/findCarteBySocieteApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * create a carte.
 * @public
 */

export const createCarteApi = createAsyncThunk(
  "carte/createCarteApi",
  async (data: CarteDataType, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { libelle, image, organisation_id ,societe_id } = data;
      var body = new FormData();
      body.append("libelle[fr]", libelle.fr.toString());
      body.append("libelle[en]", libelle.en.toString());
      body.append("libelle[ar]", libelle.ar.toString());
      const organisation = organisation_id !== null? organisation_id.toString(): organisation_id
      body.append("organisation_id", organisation.toString());
      body.append("societe_id", societe_id.toString());

      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }
      const response = await axios.post("carte/createCarteApi", body, config);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * update a carte.
 * @public
 */

export const updateCarteApi = createAsyncThunk(
  "carte/updateCarteApi",
  async (data: CarteType, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { _id, libelle, image ,societe_id,organisation_id} = data;
      var body = new FormData();
      body.append("_method", "put");
      body.append("_id", _id.toString());
      body.append("libelle[fr]", libelle.fr);
      body.append("libelle[en]", libelle.en);
      body.append("libelle[ar]", libelle.ar);
      body.append("societe_id", societe_id.toString());
      const organisation = organisation_id !== null? organisation_id.toString(): organisation_id
      body.append("organisation_id", organisation.toString());
      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }

      const response = await axios.put("carte/updateCarteApi", body, config);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * delete a carte.
 * @public
 */

export const deleteCarteApi = createAsyncThunk(
  "carte/deleteCarteApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `carte/deleteCarteApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



interface CarteState {
  listCarte: CarteType[];
  listCarteParOrganisation : { [organisation: string]: CarteType[] };
}



const initialState: CarteState = {
  listCarte: [],
  listCarteParOrganisation : {},
};

export const carteSlice = createSlice({
  name: "cartes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findCartesApi.fulfilled, (state, action) => {
      return {
        ...state,
        listCarte: action.payload,
      };
    });
    builder.addCase(createCarteApi.fulfilled, (state, action) => {
      state.listCarte.splice(0, 0, action.payload.data);
      const carteOrganisationNotNull = state.listCarte.filter((item:any)=>item.organisation_id !== null)
      const groupedByOrganisation = _.groupBy(carteOrganisationNotNull, "organisation_id");
    
      state.listCarte = action.payload;  // First update
      state.listCarteParOrganisation = groupedByOrganisation;  // Second update
    });
    builder.addCase(updateCarteApi.fulfilled, (state, action) => {
      const updated_carte = action.payload.data;
      var index = _.findIndex(state.listCarte, {
        _id: updated_carte._id,
      });
      // Replace item at index using native splice
      state.listCarte.splice(index, 1, updated_carte);
    });
    builder.addCase(findCarteBySocieteApi.fulfilled, (state, action) => {
      const carteOrganisationNotNull = action.payload.filter((item:any)=>item.organisation_id !== null)
      const groupedByOrganisation = _.groupBy(carteOrganisationNotNull, "organisation_id");
    
      state.listCarte = action.payload;  // First update
      state.listCarteParOrganisation = groupedByOrganisation;  // Second update
    });
    
  },
  
});

export const ListCartes = (state: RootState) => state.cartes.listCarte;
export const ListCarteParOrganisation = (state: RootState) => state.cartes.listCarteParOrganisation;


export default carteSlice.reducer;
