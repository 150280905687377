import React, { useEffect, useState } from "react";
import {
  ajouterDureeToDate,
  formatDateAndTime,
  formatdate,
} from "../../_App/Helpers/helpers";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { selectOrder } from "../../_App/Redux/Slices/Orders/OrderSlice";
import { useNavigate } from "react-router-dom";
import {
  ListReservations,
  SelectReservation,
  currentPage,
  findReservationsByOrganisationApi,
  totalPages,
} from "../../_App/Redux/Slices/Reservation/ReservationSlice";
import { useTranslation } from "react-i18next";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { parametrageCaisse } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import {
  ReservationToPanier,
  setClientRemise,
  setNetPrice,
  setNetPriceArticle,
} from "../../_App/Redux/Slices/Caisse/PanierSlice";
import { findPaymentsByOrderApi } from "../../_App/Redux/Slices/payments/PaymentSlice";
import HeaderReservationList from "./HeaderReservationList";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";

export default function Reservationlist({ setshowModal, showModal }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organisation_id = useAppSelector(SelectedOrganisationId);
  const listReservation = useAppSelector(ListReservations);
  const parametrage = useAppSelector(parametrageCaisse);
  const [listfiltrer, setlistfiltrer] = useState(listReservation);
  const current_page = useAppSelector(currentPage);
  const totalpages = useAppSelector(totalPages);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const [loading, setloading] = useState(false);

  const todayDate = new Date();

  useEffect(() => {
    if (organisation_id) {
      setloading(true);
      dispatch(
        findReservationsByOrganisationApi({
          organisation_id: organisation_id,
          index: index,
        })
      );
    }
  }, [index]);

  useEffect(() => {
    if (listReservation) {
      setloading(false);
    }
  }, [listReservation]);
  const dispatch = useAppDispatch();
  const handleSelectOrder = async (item: any) => {
    const echeanceDate = new Date(item.date_echeance);

    if (todayDate <= echeanceDate) {
      if (parametrage && parametrage.barcodeOnly) {
        await dispatch(findPaymentsByOrderApi(item?.order._id));
        await dispatch(
          ReservationToPanier({
            avance: item.avance?.total,
            order_id: item.order._id,
            order: item.order,
            items: item.order.items,
            client: item.infoclient.client,
          })
        );
        await dispatch(setClientRemise(item.infoclient.client.remise));
        await dispatch(setNetPriceArticle());

        setshowModal(false);
      }
    } else {
      ToastWarning("vous avez dépassé la date  d'échéance");
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      className="modal-2"
      dialogClassName="modal-60w"
      backdropClassName="modal-backdrop-2"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">
          {t("list reservations")}{" "}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setshowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="row layout-spacing">
            <HeaderReservationList
              t={t}
              listreservation={listReservation}
              setlistfiltrer={setlistfiltrer}
            />
            {loading ? (
              <div className="text-center" style={{ marginTop: "300px" }}>
                <Spinner
                  animation="grow"
                  style={{ width: "100px", height: "100px" }}
                />
                <br />
                <strong>LOADING</strong>
              </div>
            ) : (
              <div className="col-lg-12">
                <div className="statbox widget box box-shadow">
                  <div
                    className="widget-content widget-content-area"
                    style={{ overflowX: "auto" }}
                  >
                    <table id="style-2" className="table style-2 table-hover">
                      <thead>
                        <tr>
                          <th style={{ fontSize: "15px" }}>{t("N°")}</th>
                          <th style={{ fontSize: "15px", width: "100px" }}>
                            {t("Date de creation")}
                          </th>
                          <th style={{ fontSize: "15px", width: "100px" }}>
                            {t("Date d'echeance")}
                          </th>
                          <th style={{ fontSize: "15px", width: "100px" }}>
                            {t("Client")}
                          </th>
                          <th style={{ fontSize: "15px", width: "100px" }}>
                            {t("Num Tel")}
                          </th>
                          <th style={{ fontSize: "15px" }}>{t("Total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listfiltrer && listfiltrer.length
                          ? listfiltrer
                              
                              .map((item: any, index: number) => (
                                <tr key={item._id}>
                                  <td
                                    className="lien-bleu-souligne"
                                    style={{ fontSize: "15px" }}
                                    onClick={() => handleSelectOrder(item)}
                                  >
                                    {item.order?.order_number}
                                  </td>
                                  <td colSpan={6}>
                                    <Accordion>
                                      <Accordion.Item
                                        eventKey={`accordion-${index}`}
                                      >
                                        <Accordion.Header>
                                          <table className="table style-2 table-hover">
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  {formatdate(item.date)}
                                                </td>
                                                <td
                                                  style={{ fontSize: "15px ",color:todayDate > new Date(item.date_echeance) ? 'red':'' }}
                                                >
                                                  {formatdate(
                                                    item.date_echeance
                                                  )}
                                                </td>
                                                <td
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  {item.infoclient.nom}
                                                </td>
                                                <td
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  {item.infoclient.mobile}
                                                </td>
                                                <td
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  {item.order?.totalNet.toFixed(
                                                    3
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <table className="table style-2 table-hover">
                                            <thead>
                                              <tr>
                                                <th>{t("Code article")}</th>
                                                <th>{t("Libelle article")}</th>
                                                <th>{t("Quantite")}</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item.items.map(
                                                (element: any) => (
                                                  <tr
                                                    key={
                                                      element.article
                                                        .code_article
                                                    }
                                                  >
                                                    <td
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {
                                                        element.article
                                                          .code_article
                                                      }
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {element.article.titre.fr}
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      {element.quantity}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </Accordion>
                                  </td>
                                </tr>
                              ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            <div className="row layout-spacing">
              <PaginationLayout
                index={index}
                nbrpage={current_page}
                totalpages={totalpages}
                setindex={setindex}
              />
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
