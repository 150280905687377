import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import TicketDeCaisse from "../../../Components/Caisses/TicketDeCaisse/TicketDeCaisseModal";
import {
  DefaultClient,
  Panier,
  Reduction,
  addClient,
  changetotal,
  findDefaultClientsBySociete,
  resetOperationType,
  resetPanier,
  resetReduction,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { selectedTable } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  ListOrders,
  PayerReservationApi,
  getOrderByIdApi,
  order,
  resetSelectedOrder,
  saveOrderApi,
  selectOrder,
  selectedOrder,
  updateOrderApi,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  selectedSessionJournal,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  listPaymentOperations,
  saveCheque,
  savePaymentApi,
} from "../../../_App/Redux/Slices/payments/PaymentSlice";
import {
  ListMouvementsCaisse,
  saveMouvementsCaisseApi,
} from "../../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import { updateFondCourantApi } from "../../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { updateTableStatusApi } from "../../../_App/Redux/Slices/table/tableSlice";
import {
  UnpaidTicket,
  findTicketsByOrderApi,
  saveTicketApi,
  updateUserLocally,
} from "../../../_App/Redux/Slices/tickets/TicketSlice";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { updateArticleStockApi } from "../../../_App/Redux/Slices/article/articleSlice";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";
import Select from "react-select";
import {
  Vendeurs,
  findVendeurs,
} from "../../../_App/Redux/Slices/users/vendeurSlice";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import CreditCardPaymentMethod from "../../../Components/Caisses/Payment keyboard/CreditCardPaymentMethod";
import {
  createHistoriqueFidelite,
  findClientByBarcode,
  findClientById,
  ListClient,
  ListProgramFidelite,
  findClientBySocieteApi,
  updateClientApi,
} from "../../../_App/Redux/Slices/client/clientSlice";
import BonDeReductionTicket from "../../../Components/Caisses/TicketDeCaisse/BonDeReduction";
import BonReductionType from "../../../_App/Types/Entites/ReductionType";
import {
  ListBonsReduction,
  saveBonReductionApi,
} from "../../../_App/Redux/Slices/reduction/reductionSlice";
import { ProgramFideliteType } from "../../../_App/Types/Entites/RemiseType";
import ClientType, {
  HistoriqueFideliteType,
} from "../../../_App/Types/Entites/ClientType";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import Reservationpopup from "./Reservationpopup";
import PointsFidleliteMethod from "../../../Components/Caisses/Payment keyboard/PointsFidleliteMethod";
import CashPaymentMethod from "../../../Components/Caisses/Payment/CashPaymentMethod";
import ChequePaymentMethod from "../../../Components/Caisses/Payment/ChequePaymentMethod";
import GiftCardPaymentMethod from "../../../Components/Caisses/Payment/GiftCardPaymentMethod";
import { ChequeType } from "../../../_App/Types/Entites/PaymentType";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import BonReductionMethod from "../../../Components/Caisses/Payment/BonReductionMethod";

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#151516",
    color: "#888ea8",
    borderRadius: "4px",
    border: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#e0f0ef" : "white",
    color: "#888ea8",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#888ea8", // Change this to your desired color
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#888ea8", // Change this to your desired color
  }),

  // Style for the placeholder text
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#888ea8", // Change this to your desired color
  }),
};

interface PaymentRightBarProps {
  visible: boolean;
  setVisible: any;
  ArticlesToPay: any;
  miniOrder: any;
}
interface PaidProduct {
  itemIndex: number;
  paid_quantity: number;
}
const PaymentDrawer: React.FC<PaymentRightBarProps> = ({
  visible,
  setVisible,
  miniOrder,
}) => {
  const dispatch = useAppDispatch();
  const vendeurs = useAppSelector(Vendeurs);
  const cart = useSelector(Panier);
  const reduction = useAppSelector(Reduction);
  let tempReduction = reduction;

  const retrievedOrder = useAppSelector(order);
  const currentOrder = useAppSelector(selectedOrder);
  const openedOrder = retrievedOrder ? retrievedOrder : currentOrder;
  const selectedtable = useAppSelector(selectedTable);
  const orders = useAppSelector(ListOrders);
  const unpaidTicket = useAppSelector(UnpaidTicket);
  const mouvementsCaisse = useAppSelector(ListMouvementsCaisse);
  const journal = useAppSelector(selectJournalCaisse);
  const defaultClient = useAppSelector(DefaultClient);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const user = useAppSelector(selectUser);
  const bonsReduction = useAppSelector(ListBonsReduction);
  const [echeance, setEcheance] = useState<Date | null>();
  const [tel, setTel] = useState<string | null>();
  const [numCheque, setNumCheque] = useState<string | null>();
  const [cheques, setCheques] = useState<ChequeType[]>([
    {
      tel: "",
      num_cheque: "",
      echeance: new Date(),
      amount: 0,
      client_id: cart.client_id?.toString(),
      paid: false,
      payment_id: "",
      organisation_id: currentOrganisation,
    },
  ]);
  const societe_id = useAppSelector(societeId);
  const [enteredAmount, setEnteredAmount] = useState<number | 0>(0);
  const [enteredAmountCash, setEnteredAmountCash] = useState<number | 0>(0);
  const [enteredAmountCheque, setEnteredAmountCheque] = useState<number | 0>(0);
  useEffect(() => {
    const totalChequeAmount = cheques.reduce(
      (sum, cheque) => sum + (cheque.amount || 0),
      0
    );
    setEnteredAmountCheque(totalChequeAmount);
  }, [cheques]);

  const [BonReductionAmount, setBonReductionAmount] = useState(0); // Initialize with 0 or default value

  useEffect(() => {
    const amount = reduction;
    setBonReductionAmount(amount);
  }, [reduction]);

  const addCheque = () => {
    setCheques([
      ...cheques,
      {
        tel: "",
        num_cheque: "",
        echeance: new Date(),
        amount: 0,
        client_id: cart.client_id.toString(),
        paid: false,
        payment_id: "",
        organisation_id: currentOrganisation,
      },
    ]);
  };

  const updateCheque = (index: number, updatedCheque: ChequeType) => {
    const newCheques = [...cheques];
    newCheques[index] = updatedCheque;
    setCheques(newCheques);
  };

  const [enteredAmountGiftCard, setEnteredAmountGiftCard] = useState<
    number | 0
  >(0);
  const [enteredAmountCreditCard, setEnteredAmountCreditCard] = useState<
    number | 0
  >(0);
  const [fideliteAmount, setFideliteAmount] = useState<number | 0>(0);

  const [idbon, setIdBon] = useState();
  const [tempCash, setTempCash] = useState<number | 0>(0);
  const [tempCheque, setTempCheque] = useState<number | 0>(0);
  const [totalENteredAmount, setTotalENteredAmount] = useState<number | 0>(0);
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [showModalTicketBon, setShowModalTicketBon] = useState(false);

  const [ticketId, setTicketId] = useState<string | null>(null); // Assuming the ticket ID is a string, adjust if necessary
  const session = useAppSelector(selectedSessionJournal);
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const parametrage = useAppSelector(parametrageCaisse);
  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const listClient = useAppSelector(ListClient);
  const { t } = useTranslation();
  const listpayment = useAppSelector(listPaymentOperations);
  const openedOrderId = openedOrder?._id;
  const [totalpayee, settotalpayee] = useState(0);
  const [totalcommande, settotalcommande] = useState(0);
  const [firsttotal, setfirsttotal] = useState(0);
  const [activePayment, setActivePayment] = useState("cash"); // Default to 'cash'
  const [showReservationpopup, setshowReservationpopup] = useState(false);
  const total = openedOrder
    ? miniOrder
      ? miniOrder.total
      : unpaidTicket
      ? unpaidTicket.total
      : openedOrder.total
    : cart.operationType === "Retour"
    ? cart.totalNet
    : cart.totalNet;

  const [currentClient, setCurrentClient] = useState<ClientType>();
  // useEffect(() => {
  //   console.log(enteredAmount);
  //   console.log(enteredAmountCash);
  //   console.log(enteredAmountCheque);
  //   console.log(fideliteAmount);
  // }, [
  //   enteredAmount,
  //   enteredAmountCash,
  //   enteredAmountCheque,
  //   fideliteAmount,
  //   activePayment,
  // ]);
  useEffect(() => {
    const getClient = async () => {
      if (cart.client_id) {
        const loyalClient = await dispatch(
          findClientById(cart.client_id.toString())
        );
        if (loyalClient && loyalClient.payload)
          setCurrentClient(loyalClient.payload);
      }
    };
    getClient();
  }, [cart.client_id]);

  useEffect(() => {
    if (cart && cart.order_id) {
      setfirsttotal(cart.total);
      let totalpaid;
      //alert("avance")
      if (cart && cart.avance) {
        //  alert("avance")
        totalpaid = cart.avance;
      } else {
        totalpaid = listpayment.reduce((acc, val) => acc + val.amount, 0);
      }
      settotalpayee(totalpaid);

      dispatch(changetotal({ total: cart.total - totalpaid }));
      settotalcommande(cart.total - totalpaid + totalpayee);
      setfirsttotal(cart.totalNet);
      settotalpayee(totalpaid);
      dispatch(changetotal({ total: cart.totalNet - totalpaid }));
      settotalcommande(cart.totalNet - totalpaid + totalpayee);
    }
  }, [cart.order_id]);
  // entring amounts
  const handleEnteredAmount = (amount: number | 0) => {
    setEnteredAmountCash(amount);
    setEnteredAmount(
      enteredAmountCash +
        enteredAmountCheque +
        enteredAmountCreditCard +
        enteredAmountGiftCard +
        fideliteAmount
    );
  };

  const handleEnteredAmountGiftCard = (amount: number | 0) => {
    setEnteredAmountGiftCard(amount);
    setEnteredAmount(
      enteredAmountCash +
        enteredAmountCheque +
        enteredAmountCreditCard +
        enteredAmountGiftCard +
        fideliteAmount
    );
  };
  const handleEnteredAmountCreditCard = (amount: number | 0) => {
    setEnteredAmountCreditCard(amount);
    setEnteredAmount(
      enteredAmountCash +
        enteredAmountCheque +
        enteredAmountCreditCard +
        enteredAmountGiftCard +
        fideliteAmount
    );
  };
  // end entering amounts
  function generateMouvementNumber(): string {
    let uniqueId: string;
    const existingOrderNumbers = mouvementsCaisse.map(
      (item) => item.operation_code
    );
    do {
      uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
    } while (
      existingOrderNumbers.some((operationCode) => operationCode === uniqueId)
    );

    return uniqueId;
  }

  const saveMouvementCaisse = async (
    montant: number,
    paymentMethod: string,
    order: any,
    sens: any
  ) => {
    dispatch(
      saveMouvementsCaisseApi({
        operation_code: generateMouvementNumber(),
        caisse_code: "someCode",
        order_id: order,
        montant: montant,
        date: new Date(),
        user_id: user?._id,
        journal_id: journal?._id,
        session_id: session?._id,
        payment_method: paymentMethod,
        organisation_id: currentOrganisation,
        commentaire: sens && sens === "d" ? "Remboursement" : null,
        type_mouvement_id:
          sens && sens === "d"
            ? "66068c482b2a9e20c257701e"
            : "65a5399107fa84b78ac5c65b",
      })
    );
  };

  // payments methods
  const cashPayment = async (response: any, responseTicket: any) => {
    dispatch(
      savePaymentApi({
        operation_code: "4554",
        order_id: response.payload.data._id,
        ticket_id: responseTicket.payload.data._id,
        payment_method: "Espéces",
        date: new Date(),
        amount: enteredAmountCash ? enteredAmountCash : total,
        rendu:
          enteredAmountCash +
            enteredAmountCheque +
            enteredAmountCreditCard +
            enteredAmountGiftCard +
            fideliteAmount >
          total
            ? Math.abs(
                total -
                  (enteredAmountCash +
                    enteredAmountCheque +
                    enteredAmountCreditCard +
                    enteredAmountGiftCard +
                    fideliteAmount)
              )
            : 0,
        code_caisse: "someCode",
        organisation_id: currentOrganisation ?? "defaultOrganizationId",
      })
    );
    saveMouvementCaisse(
      response.payload.data.total - enteredAmountCheque,
      "Cash",
      response.payload.data._id,
      null
    );
  };
  const fidelitePayment = async (response: any, responseTicket: any) => {
    dispatch(
      savePaymentApi({
        operation_code: "4554",
        order_id: response.payload.data._id,
        ticket_id: responseTicket.payload.data._id,
        payment_method: "Points fidelité",
        date: new Date(),
        amount: fideliteAmount ? fideliteAmount : total,
        rendu: 0,
        code_caisse: "someCode",
        organisation_id: currentOrganisation ?? "defaultOrganizationId",
      })
    );
    dispatch(
      updateClientApi({
        _id: cart.client_id.toString(),
        points: 0,
        equivalentMoney: 0,
      })
    );
    saveMouvementCaisse(
      response.payload.data.total - enteredAmountCheque,
      "Points fidelité",
      response.payload.data._id,
      null
    );
  };
  const bonPayment = async (response: any, responseTicket: any) => {
    dispatch(
      savePaymentApi({
        operation_code: "4554",
        order_id: response.payload.data._id,
        ticket_id: responseTicket.payload.data._id,
        payment_method: "bon",
        date: new Date(),
        amount: tempReduction,
        rendu: 0,
        code_caisse: "someCode",
        organisation_id: currentOrganisation ?? "defaultOrganizationId",
      })
    );
    dispatch(
      updateClientApi({
        _id: cart.client_id.toString(),
        points: 0,
        equivalentMoney: 0,
      })
    );
    saveMouvementCaisse(
      response.payload.data.total - enteredAmountCheque,
      "Points fidelité",
      response.payload.data._id,
      null
    );
  };
  const chequePayment = async (response: any, responseTicket: any) => {
    const res = await dispatch(
      savePaymentApi({
        operation_code: "4554",
        order_id: response.payload.data._id,
        ticket_id: responseTicket.payload.data._id,
        payment_method: "Chéque",
        date: new Date(),
        amount: enteredAmountCheque,
        rendu: null,
        code_caisse: "someCode",
        organisation_id: currentOrganisation,
      })
    );
    if (res.payload.success) {
      console.log(cheques);
      for (const item of cheques) {
        item.payment_id = res.payload.data._id;
      }
      console.log(cheques);
      await dispatch(saveCheque(cheques));
    }
    saveMouvementCaisse(
      enteredAmountCheque,
      "Chéque",
      response.payload.data._id,
      null
    );
  };
  const giftCardPayment = async (response: any, responseTicket: any) => {
    dispatch(
      savePaymentApi({
        operation_code: "4554",
        order_id: response.payload.data._id,
        ticket_id: responseTicket.payload.data._id,
        payment_method: "Tickets cadeaux",
        date: new Date(),
        amount: enteredAmountGiftCard,
        rendu:
          enteredAmountCash +
            enteredAmountCheque +
            enteredAmountCreditCard +
            enteredAmountGiftCard +
            fideliteAmount >
          total
            ? Math.abs(
                total -
                  (enteredAmountCash +
                    enteredAmountCheque +
                    enteredAmountCreditCard +
                    enteredAmountGiftCard +
                    fideliteAmount)
              )
            : 0,
        code_caisse: "someCode",
        organisation_id: currentOrganisation ?? "defaultOrganizationId",
      })
    );
    saveMouvementCaisse(
      response.payload.data.total - enteredAmountCheque,
      "Tickets cadeaux",
      response.payload.data._id,
      null
    );
  };
  const creditCardPayment = async (response: any, responseTicket: any) => {
    dispatch(
      savePaymentApi({
        operation_code: "4554",
        order_id: response.payload.data._id,
        ticket_id: responseTicket.payload.data._id,
        payment_method: "Carte bancaire",
        date: new Date(),
        amount: enteredAmountGiftCard,
        rendu:
          enteredAmountCash +
            enteredAmountCheque +
            enteredAmountCreditCard +
            enteredAmountGiftCard +
            fideliteAmount >
          total
            ? Math.abs(
                total -
                  (enteredAmountCash +
                    enteredAmountCheque +
                    enteredAmountCreditCard +
                    enteredAmountGiftCard +
                    fideliteAmount)
              )
            : 0,
        code_caisse: "someCode",
        organisation_id: currentOrganisation ?? "defaultOrganizationId",
      })
    );
    saveMouvementCaisse(
      response.payload.data.total - enteredAmountCheque,
      "Carte bancaire",
      response.payload.data._id,
      null
    );
  };
  // end payments methods

  //payments
  const partialPayment = async (order: any) => {
    let response;
    let responseTicket;
    response = await dispatch(
      updateOrderApi({
        orderId: order._id,
        status: !miniOrder ? "Payée" : "Partiellement payée",
        paidProducts: [],
        vendeur:
          selectedOption && selectedOption.value ? selectedOption.value : null,
      })
    );
    if (openedOrder && response.payload.success) {
      dispatch(selectOrder(response.payload.data)); //update selected order
      responseTicket = await dispatch(
        saveTicketApi({
          main_order: openedOrder._id,
          ticket_order: miniOrder,
          type: "Partiel",
        })
      );
      if (responseTicket.payload.success) {
        const newTicketId = responseTicket.payload.data._id; // Assuming _id is the ID property
        setTicketId(newTicketId);
      } else {
        ToastWarning(response.payload.message);
      }
      dispatch(
        updateTableStatusApi({
          table_id: order.table_id,
          status: "Disponible",
        })
      );
      if (!enteredAmount) {
        cashPayment(response, responseTicket);
      }
      if (enteredAmountCash) {
        cashPayment(response, responseTicket);
      }
      if (enteredAmountCheque) {
        chequePayment(response, responseTicket);
      }
      if (enteredAmountGiftCard) {
        giftCardPayment(response, responseTicket);
      }
      if (enteredAmountCreditCard) {
        creditCardPayment(response, responseTicket);
      }
      if (fideliteAmount) {
        fidelitePayment(response, responseTicket);
      }
      if (tempReduction) {
        bonPayment(response, responseTicket);
      }
    } else {
      console.log(response.payload.message);
    }
  };
  const totalPayment = async (order: any) => {
    let response;
    let responseTicket;
    let tempOrder = _.cloneDeep(order);
    let previousStatus = tempOrder.status;
    response = await dispatch(
      updateOrderApi({
        orderId: order._id,
        status: "Payée",
        paidProducts: [],
        vendeur:
          selectedOption && selectedOption.value ? selectedOption.value : null,
      })
    );
    if (response.payload.success) {
      tempOrder.status = "Payée";
      responseTicket = await dispatch(
        saveTicketApi({
          main_order: tempOrder._id,
          ticket_order:
            previousStatus === "Partiellement payée" ? unpaidTicket : tempOrder,
          type: previousStatus === "Nouvelle" ? "Total" : "Partiel",
        })
      );
      if (responseTicket.payload.success) {
        const newTicketId = responseTicket.payload.data._id;
        setTicketId(newTicketId);
      } else {
        ToastWarning(response.payload.message);
      }
      ToastSuccess(response.payload.message);
      console.log(
        "at payment",
        enteredAmount,
        enteredAmountCash,
        fideliteAmount
      );
      if (!enteredAmount) {
        console.log("test1");
        cashPayment(response, responseTicket);
      }
      if (enteredAmountCash > 0) {
        console.log("test2");

        cashPayment(response, responseTicket);
      }
      if (enteredAmountCheque > 0) {
        chequePayment(response, responseTicket);
      }
      if (enteredAmountGiftCard > 0) {
        giftCardPayment(response, responseTicket);
      }
      if (enteredAmountCreditCard > 0) {
        creditCardPayment(response, responseTicket);
      }
      if (fideliteAmount > 0) {
        fidelitePayment(response, responseTicket);
      }
      if (tempReduction) {
        bonPayment(response, responseTicket);
      }
      if (journal) {
        dispatch(
          updateFondCourantApi({
            id: journal._id,
            fond_courant: (
              Number(journal.fond_courant) + response.payload.data.total
            ).toFixed(3),
          })
        );
      }
    } else {
      ToastWarning(response.payload.message);
    }
  };
  const AvancePayment = async (response: any, ticket: any) => {
    if (enteredAmountCash) {
      cashPayment(response, ticket);
    }
    if (enteredAmountCheque) {
      chequePayment(response, ticket);
    }
  };
  const handlePayOrder = async (order: any) => {
    if (miniOrder) {
      unpaidTicket && miniOrder.total === unpaidTicket?.total
        ? await totalPayment(order)
        : partialPayment(order);
    } else {
      await totalPayment(order);
    }
    dispatch(resetSelectedOrder());
    setShowModalTicket(true);
  };
  const clientFidelite = async (order: OrderType) => {
    if (cart.programmesFidelite.length) {
      for (const item of cart.programmesFidelite) {
        const data: HistoriqueFideliteType = {
          client_id: cart.client_id.toString(),
          date: new Date(),
          order_id: order._id,
          organisation_id: currentOrganisation ? currentOrganisation : "",
          points: item?.points,
          program_fidelite: item._id,
        };

        try {
          await dispatch(createHistoriqueFidelite(data));
          const client = await dispatch(
            findClientById(cart.client_id.toString())
          );

          if (client && client.payload) {
            await dispatch(
              updateClientApi({
                _id: cart.client_id.toString(),
                points: client.payload.points + item.points,
                equivalentMoney:
                  Number(client.payload.equivalentMoney) +
                  Number(item.equivalentMoney),
              })
            );
          } else {
            console.error("Client not found or payload missing", client);
          }
        } catch (error) {
          console.error("Error updating client fidelity", error);
        }
      }
    }
  };

  const reptureAlerts = async (orderItems: any) => {
    for (const value of orderItems) {
      const res = await dispatch(
        updateArticleStockApi({
          id: value.article._id,
          depotId: currentDepot._id,
          soldQuantity: value.quantity,
        })
      );
      console.log(res);
      if (
        res.payload.success &&
        parametrage &&
        res.payload.data.stock < parametrage.minimalStock
      ) {
        toast.warning(
          `L'article ${res.payload.data.article_id?.titre?.fr} est presque en rupture de stock ! Stock actuel : ${res.payload.data.stock}`
        );
      }
    }
  };

  const RefundCash = async () => {
    saveMouvementCaisse(Math.abs(total), "Cash", null, "d");
  };

  function generateCode(): string {
    let uniqueId: string;
    const existingCodes = Object.values(bonsReduction).map(
      (item) => item[0]?.code
    );
    do {
      uniqueId = (Math.floor(Math.random() * 900000) + 100000).toString();
    } while (existingCodes.some((operationCode) => operationCode === uniqueId));

    return uniqueId;
  }

  const handleRefund = async () => {
    if (activePayment === "bon") {
      const today = new Date();

      // Create a new date that is 2 weeks later
      const twoWeeksLater = new Date(today);
      twoWeeksLater.setDate(today.getDate() + 14);
      let data: BonReductionType = {
        date: today,
        amount: Number(Math.abs(total)),
        dateEcheance: twoWeeksLater,
        depot_id: currentDepot._id,
        code: generateCode(),
        type: "Contre bon",
      };
      const res = await dispatch(saveBonReductionApi(data));
      if (res.payload.success) {
        setIdBon(res.payload.data._id);
        setShowModalTicketBon(true);
      }
    } else {
      RefundCash();
      setShowModalTicketBon(true);
    }
    dispatch(resetPanier());
    dispatch(resetOperationType());
    dispatch(resetReduction());
  };

  const paymentRightBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        paymentRightBarRef.current &&
        !paymentRightBarRef.current.contains(event.target as Node)
      ) {
        if (setVisible) setVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setVisible]);
  useEffect(() => {
    if (societe_id) {
      dispatch(findClientBySocieteApi(societe_id));
      dispatch(findDefaultClientsBySociete(societe_id));
    }
    if (openedOrder) dispatch(findTicketsByOrderApi(openedOrder._id));
    if (currentOrganisation) dispatch(findVendeurs(currentOrganisation));
  }, [dispatch, openedOrder]);

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const options = vendeurs.map((item) => ({
    value: item._id,
    label: item.nom,
    data: item,
  }));

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    console.log(selectedOption);
  };

  const handleFideliteAmount = async () => {
    const client = await dispatch(findClientById(cart.client_id.toString()));
    if (client && client.payload) {
      setFideliteAmount(client.payload.equivalentMoney);
      setEnteredAmount(
        enteredAmountCash +
          enteredAmountCheque +
          enteredAmountCreditCard +
          enteredAmountGiftCard +
          fideliteAmount
      );

      if (client.payload.equivalentMoney >= total) setEnteredAmountCash(0);
    }
  };

  useEffect(() => {
    setEnteredAmount(0);
    setEnteredAmountCash(0);
    setEnteredAmountCheque(0);
    setFideliteAmount(0);
    setBonReductionAmount(0);

    setEcheance(null);
    setTel("");
    setNumCheque("");
    setCheques([
      {
        tel: "",
        num_cheque: "",
        echeance: new Date(),
        amount: 0,
        client_id: cart?.client_id?.toString(),
        paid: false,
        payment_id: "",
        organisation_id: currentOrganisation,
      },
    ]);
  }, [openedOrder, cart]);

  function generateOrderNumber(): string {
    let uniqueId: string;
    const existingOrderNumbers = orders.map((order) => order.order_number);
    do {
      uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
    } while (
      existingOrderNumbers.some((orderNumber) => orderNumber === uniqueId)
    );

    return uniqueId;
  }

  const handleConfirmOrder = async (data: any, status: string) => {
    let order = { ...data };
    delete order.calculatedQuantity;
    delete order.orderConfirmed;
    let response: any;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total;
    order.totalNet = cart.totalNet;
    order.order_number = generateOrderNumber();
    order.status = status;
    order.client_id = cart.client_id;
    order.clientRemise = cart.clientRemise;
    order.societe_id = societe_id;
    order.depots_id = currentDepot._id;

    if (journal) order.journal_id = journal?._id;
    order.vendeur =
      selectedOption && selectedOption.value ? selectedOption.value : user?._id;
    if (user) order.user_id = user?._id;
    try {
      response = await dispatch(saveOrderApi(order));
      if (response.payload.success) {
        reptureAlerts(order.items);
        let data = response.payload.data;
        dispatch(
          saveTicketApi({
            main_order: data._id,
            ticket_order: data,
            type: "Total",
          })
        );
        clientFidelite(data);
        await dispatch(resetPanier());
        await dispatch(resetReduction());

        await dispatch(addClient(defaultClient._id));
        if (parametrage && !parametrage.barcodeOnly)
          await dispatch(getOrderByIdApi(response.payload.data._id));
        handlePayOrder(response.payload.data);
        setTotalENteredAmount(enteredAmount);
        setEnteredAmount(0);
        setTempCash(enteredAmountCash);
        setEnteredAmountCash(0);
        setTempCheque(enteredAmountCheque);
        setCheques([
          {
            tel: "",
            num_cheque: "",
            echeance: new Date(),
            amount: 0,
            client_id: cart.client_id.toString(),
            paid: false,
            payment_id: "",
            organisation_id: currentOrganisation,
          },
        ]);
        setEnteredAmountCheque(0);
        setEcheance(null);
        setTel("");
        setNumCheque("");
        setShowModalTicket(true);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    }
  };

  const handlereserverOrder = async () => {
    if (cart.items.length === 0) {
      ToastWarning(t("choisissez un article"));
    } else {
      setshowReservationpopup(true);
    }
  };
  const confirmreservation = async (data: any, inforeservation: any) => {
    let order = { ...data };
    delete order.calculatedQuantity;
    delete order.orderConfirmed;
    let response: any;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total;
    order.totalNet = cart.totalNet;

    order.order_number = generateOrderNumber();
    order.status = "reservation";
    order.client_id = cart.client_id;
    order.journal_id = journal?._id;
    order.depots_id = currentDepot._id ? currentDepot._id : null;
    order.vendeur =
      selectedOption && selectedOption.value ? selectedOption.value : user?._id;
    order.enteredAmountCash = enteredAmountCash;
    order.enteredAmountCheque = enteredAmountCheque;
    order.reservationdata = {
      avance: {
        total: enteredAmountCash + enteredAmountCheque,
        cash: enteredAmountCash,
        cheque: enteredAmountCheque,
      },
      duree: inforeservation.duree,
      date_echeance: inforeservation.date_echeance,
      infoclient: {
        client: inforeservation.client,
        nom: inforeservation.nom,
        mobile: inforeservation.mobile,
      },
    };

    const avanceresevation = order.reservationdata.avance.total;

    order.user_id = user?._id;
    try {
      response = await dispatch(saveOrderApi(order));
      if (response.payload.success) {
        order.items.forEach((value: any) => {
          dispatch(
            updateArticleStockApi({
              id: value.article._id,
              depotId: currentDepot._id,
              soldQuantity: value.quantity,
            })
          );
        });
        let data = response.payload.data;
        const copiedata = { ...data };
        copiedata.total = avanceresevation;
        const ticketresp = await dispatch(
          saveTicketApi({
            main_order: data._id,
            ticket_order: copiedata,
            type: "Partiel",
          })
        );
        if (ticketresp.payload.success) {
          const newTicketId = ticketresp.payload.data._id; // Assuming _id is the ID property
          setTicketId(newTicketId);
        }
        dispatch(resetPanier());
        dispatch(addClient(defaultClient._id));
        dispatch(getOrderByIdApi(response.payload.data._id));
        setTotalENteredAmount(enteredAmount);
        setEnteredAmount(0);
        setTempCash(enteredAmountCash);
        setEnteredAmountCash(0);
        setTempCheque(enteredAmountCheque);
        setEnteredAmountCheque(0);
        setFideliteAmount(0);

        setShowModalTicket(true);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    }
  };
  const PayerReservation = async () => {
    const paiementdata = {
      giftcard: enteredAmountGiftCard,
      cheque: enteredAmountCheque,
      cash: enteredAmountCash,
      remainingAmount: remainingAmount,
    };
    const data = { paiementdata: paiementdata, order: cart };
    const resp = await dispatch(PayerReservationApi(data));
    if (resp.payload.success) {
      setTicketId(resp.payload.data.ticket._id);

      dispatch(updateUserLocally(resp.payload.data.ticket)); // this is the action

      setShowModalTicket(true);

      ToastSuccess(t(resp.payload.message));
      dispatch(resetPanier());
    } else {
      ToastWarning(t(resp.payload.message));
    }
  };

  const remainingAmount =
    enteredAmountCash +
      enteredAmountCheque +
      enteredAmountCreditCard +
      enteredAmountGiftCard +
      fideliteAmount +
      reduction !==
    0
      ? fideliteAmount > total || BonReductionAmount > total
        ? 0
        : Math.abs(
            total -
              (enteredAmountCash +
                enteredAmountCheque +
                enteredAmountCreditCard +
                enteredAmountGiftCard +
                fideliteAmount +
                reduction)
          )
      : null;

  const orderConfirmationAlert = () => {
    Swal.fire({
      title: "Voulez-vous confirmer ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (openedOrder) {
          if (total < 0) {
            handleRefund();
          } else {
            handlePayOrder(openedOrder);
          }
        } else {
          if (total < 0) {
            handleRefund();
          } else handleConfirmOrder(cart, "Payée");
        }
      }
    });
  };

  return (
    <div
      className={
        setVisible
          ? visible === true
            ? "col-right-content-container payment-rightbar displayed"
            : "col-right-content-container payment-rightbar"
          : "fixed-payment-rightbar"
      }
      ref={paymentRightBarRef}
    >
      {ticketId ? (
        <TicketDeCaisse
          ShowModal={showModalTicket}
          setShowModal={setShowModalTicket}
          givenAmount={tempCash + tempCheque}
          cash={tempCash}
          cheque={tempCheque}
          ticket_id={ticketId}
          order_id={openedOrderId}
        />
      ) : null}
      <BonDeReductionTicket
        ShowModal={showModalTicketBon}
        setShowModal={setShowModalTicketBon}
        Bon_id={idbon}
      />

      <div className="widget-message" style={{ padding: "10px" }}>
        <div className="widget-title mb-10">
          <h5
            onClick={() => {
              console.log(cheques);
            }}
          >
            {total && total < 0 ? "Remboursement" : t("payment")}
          </h5>
          {setVisible ? (
            <button
              className="delete-button"
              onClick={(e) => {
                if (setVisible) setVisible(false);
              }}
              style={{ float: "right" }}
            >
              <i
                className="fas fa-times"
                style={{ width: "auto", display: "flex" }}
              ></i>
            </button>
          ) : null}
        </div>
      </div>
      <div style={{ margin: "2px 0" }}>
        <Select
          className="basic"
          options={options}
          isSearchable={true}
          onChange={handleSelectChange}
          value={selectedOption}
          styles={customStyles}
          placeholder={"Vendeur / vendeuse"}
        />
      </div>
      {/*cart.order_id && (
        <div className="widget-todo" style={{ width: "100%" }}>
          <div className="todo-content">
            <div className="todo-title">
              <h6>
                <span>{t("montant total de commande")}</span>
              </h6>
            </div>
            <div className="todo-text">
              <p>{ totalcommande.toFixed(3) || ""}</p>
            </div>
            
          </div>
          
        </div>
      )*/}
      {cart.order_id && (
        <div className="widget-todo" style={{ width: "100%" }}>
          <div className="todo-content">
            <div className="todo-title">
              <h6>
                <span>{t("Avance")}</span>
              </h6>
            </div>
            <div className="todo-text">
              <p>{totalpayee?.toFixed(3) || ""}</p>
            </div>
          </div>
        </div>
      )}
      <div className="widget-todo">
        <div className="todo-content">
          <div className="todo-title">
            <h6>
              <span
                className="align-self-center"
                onClick={() => console.log(reduction, tempReduction)}
              >
                {t("total_to_pay")}
              </span>
            </h6>
          </div>
          <div className="todo-text">
            <p>{total?.toFixed(3)}</p>
          </div>
        </div>
      </div>

      <div className="activity-section">
        <div style={{ overflow: "auto", maxHeight: "524px" }}>
          {enteredAmountCash > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span
                      className="align-self-center"
                      onClick={() => {
                        console.log("total:", total);
                        console.log(enteredAmountCash);
                        console.log(remainingAmount);
                      }}
                    >
                      {t("Espéces")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountCash}</p>
                </div>
              </div>
            </div>
          ) : null}
          {enteredAmountCheque > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span
                      className="align-self-center"
                      onClick={() => {
                        console.log("total:", total);
                        console.log(enteredAmountCash);
                        console.log(remainingAmount);
                      }}
                    >
                      {t("Chéque")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountCheque}</p>
                </div>
              </div>
            </div>
          ) : null}
          {enteredAmountGiftCard > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span
                      className="align-self-center"
                      onClick={() => {
                        console.log("total:", total);
                        console.log(enteredAmountCash);
                        console.log(remainingAmount);
                      }}
                    >
                      {t("Tickets cadeaux")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{enteredAmountGiftCard}</p>
                </div>
              </div>
            </div>
          ) : null}
          {fideliteAmount > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">
                      {t("Points fidelité")}
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{fideliteAmount}</p>
                </div>
              </div>
            </div>
          ) : null}
          {reduction > 0 ? (
            <div className="widget-todo" style={{ width: "100%" }}>
              <div className="todo-content">
                <div className="todo-title">
                  <h6
                    onClick={() => console.log(reduction, BonReductionAmount)}
                  >
                    <span className="align-self-center">{t("Contre bon")}</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{reduction}</p>
                </div>
              </div>
            </div>
          ) : null}
          {openedOrder || cart
            ? enteredAmountCash +
                enteredAmountCheque +
                enteredAmountCreditCard +
                enteredAmountGiftCard +
                fideliteAmount +
                reduction !==
                0 &&
              enteredAmountCash +
                enteredAmountCheque +
                enteredAmountCreditCard +
                enteredAmountGiftCard +
                fideliteAmount +
                reduction !==
                total && (
                <div className="widget-todo" style={{ width: "100%" }}>
                  <div className="todo-content">
                    <div className="todo-title">
                      <h6>
                        <span
                          className="align-self-center"
                          onClick={() => {
                            console.log("total:", total);
                            console.log(enteredAmountCash);
                            console.log(remainingAmount);
                          }}
                        >
                          {enteredAmountCash +
                            enteredAmountCheque +
                            enteredAmountCreditCard +
                            enteredAmountGiftCard +
                            fideliteAmount +
                            reduction <
                          total
                            ? t("Remaining to Pay")
                            : t("Remaining to Return")}
                        </span>
                      </h6>
                    </div>
                    <div className="todo-text">
                      <p>
                        {remainingAmount !== null
                          ? remainingAmount?.toFixed(3)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              )
            : null}

          <nav id="payment-block">
            <ul className="menu-payments" style={{ flexWrap: "wrap" }}>
              <li className="menu active">
                <a
                  href="#cash"
                  data-active="true"
                  className="menu-toggle"
                  onClick={() => setActivePayment("cash")}
                  style={{
                    borderColor:
                      activePayment && activePayment === "cash"
                        ? "#009688" // Change to the desired background color
                        : "",
                  }}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-money-bill-alt"></i>{" "}
                    </div>
                    <span>Cash</span>
                  </div>
                </a>
              </li>
              {total >= 0 ? (
                <>
                  <li className="menu">
                    <a
                      style={{
                        borderColor:
                          activePayment && activePayment === "cheque"
                            ? "#009688" // Change to the desired background color
                            : "",
                      }}
                      href="#cheque"
                      data-active="false"
                      className="menu-toggle"
                      onClick={() => setActivePayment("cheque")}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-money-check-alt"></i>
                        </div>
                        <span>Chéque</span>
                      </div>
                    </a>
                  </li>

                  <li className="menu">
                    <a
                      href="#giftcard"
                      data-active="false"
                      className="menu-toggle"
                      onClick={() => setActivePayment("gift card")}
                      style={{
                        borderColor:
                          activePayment && activePayment === "gift card"
                            ? "#009688" // Change to the desired background color
                            : "",
                      }}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-gift"></i>
                        </div>
                        <span>Gift Card</span>
                      </div>
                    </a>
                  </li>
                  <li className="menu">
                    <a
                      href="#creditcard"
                      data-active="false"
                      className="menu-toggle"
                      onClick={() => setActivePayment("credit card")}
                      style={{
                        borderColor:
                          activePayment && activePayment === "credit card"
                            ? "#009688" // Change to the desired background color
                            : "",
                      }}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-credit-card"></i>
                        </div>
                        <span>Credit Card</span>
                      </div>
                    </a>
                  </li>
                  {currentClient && currentClient.equivalentMoney > 0 ? (
                    <li className="menu">
                      <a
                        href="#cheque"
                        data-active="false"
                        className="menu-toggle"
                        onClick={() => {
                          handleFideliteAmount();
                          setActivePayment("points");
                        }}
                        style={{
                          borderColor:
                            activePayment && activePayment === "points"
                              ? "#009688" // Change to the desired background color
                              : "",
                        }}
                      >
                        <div className="base-menu">
                          <div className="base-icons">
                            <i className="fas fa-wallet"></i>{" "}
                          </div>
                          <span>Points</span>
                        </div>
                      </a>
                    </li>
                  ) : null}
                  {reduction > 0 ? (
                    <li className="menu">
                      <a
                        href="#cheque"
                        data-active="false"
                        className="menu-toggle"
                        onClick={() => {
                          handleFideliteAmount();
                          setActivePayment("bon");
                        }}
                        style={{
                          borderColor:
                            activePayment && activePayment === "bon"
                              ? "#009688" // Change to the desired background color
                              : "",
                        }}
                      >
                        <div className="base-menu">
                          <div className="base-icons">
                            <i className="fas fa-wallet"></i>{" "}
                          </div>
                          <span>Contre bon</span>
                        </div>
                      </a>
                    </li>
                  ) : null}
                </>
              ) : (
                <li className="menu">
                  <a
                    href="#creditcard"
                    data-active="false"
                    className="menu-toggle"
                    onClick={() => setActivePayment("bon")}
                    style={{
                      borderColor:
                        activePayment && activePayment === "bon"
                          ? "#009688" // Change to the desired background color
                          : "",
                    }}
                  >
                    <div className="base-menu">
                      <div className="base-icons">
                        <i className="fas fa-credit-card"></i>
                      </div>
                      <span>Contre bon</span>
                    </div>
                  </a>
                </li>
              )}
            </ul>
          </nav>
          {activePayment === "cash" && (
            <CashPaymentMethod
              total={total}
              onAmountChange={handleEnteredAmount}
              givenAmout={enteredAmount}
              enteredAmountCash={enteredAmountCash}
              remainingAmount={remainingAmount}
            />
          )}
          {activePayment === "cheque" ? (
            <>
              {cheques.map((cheque, index) => (
                <ChequePaymentMethod
                  key={index}
                  total={total}
                  enteredAmountCheque={cheque.amount}
                  tel={cheque.tel}
                  setTel={(tel: any) => updateCheque(index, { ...cheque, tel })}
                  num={cheque.num_cheque}
                  setNumCheque={(num_cheque: any) =>
                    updateCheque(index, { ...cheque, num_cheque })
                  }
                  echeance={cheque.echeance}
                  setEcheance={(echeance: any) =>
                    updateCheque(index, { ...cheque, echeance })
                  }
                  onAmountChange={(amount: any) =>
                    updateCheque(index, { ...cheque, amount })
                  }
                />
              ))}
              <button
                className="btn btn-primary"
                onClick={addCheque}
                style={{ width: "100%" }}
              >
                +
              </button>
            </>
          ) : null}
          {activePayment === "gift card" && (
            <GiftCardPaymentMethod
              total={total}
              onAmountChange={handleEnteredAmountGiftCard}
              givenAmout={enteredAmount}
              enteredAmountGiftCard={enteredAmountGiftCard}
            />
          )}
          {activePayment === "credit card" && (
            <CreditCardPaymentMethod
              total={total}
              onAmountChange={handleEnteredAmountCreditCard}
              givenAmout={enteredAmount}
              enteredAmountCreditCard={enteredAmountCreditCard}
            />
          )}
          {activePayment === "points" && (
            <PointsFidleliteMethod
              total={total}
              onAmountChange={handleEnteredAmountCreditCard}
              givenAmout={enteredAmount}
              pointsFideliteEquivalent={fideliteAmount}
              setpointsFideliteEquivalent={setFideliteAmount}
            />
          )}
          {activePayment === "bon" && (
            <BonReductionMethod
              total={total}
              onAmountChange={handleEnteredAmountCreditCard}
              givenAmout={enteredAmount}
              pointsFideliteEquivalent={BonReductionAmount}
              setpointsFideliteEquivalent={setBonReductionAmount}
            />
          )}
        </div>

        {cart.order_id ? (
          <div className="cart-footer">
            <div
              className="widget-calendar-lists-scroll"
              style={{ width: "100%" }}
            >
              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                onClick={(e) => {
                  PayerReservation();
                }}
              >
                {t("Payer")}
              </button>
            </div>
          </div>
        ) : (
          <div className="cart-footer">
            <div
              className="widget-calendar-lists-scroll"
              style={{ width: "100%" }}
            >
              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                disabled={
                  cart.items.length === 0 &&
                  (!openedOrder || openedOrder?.items?.length === 0)
                }
                onClick={(e) => {
                  orderConfirmationAlert();
                }}
              >
                {t("confirm")}
              </button>

              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                disabled={total === 0}
                onClick={(e) => {
                  handlereserverOrder();
                }}
              >
                {t("reserver")}
              </button>
            </div>
          </div>
        )}
      </div>
      <Reservationpopup
        showModal={showReservationpopup}
        setshowModal={setshowReservationpopup}
        t={t}
        listClient={listClient}
        cart={cart}
        confirmreservation={confirmreservation}
      />
    </div>
  );
};

export default PaymentDrawer;
