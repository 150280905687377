import React, { useEffect } from "react";
import ParametragesCaisseType from "../../../_App/Types/Entites/CaisseParametragesType";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  parametrageCaisse,
  saveParametrageCaisseApi,
  updateParametrageCaisseApi,
} from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import _ from "lodash";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { CaisseParametrageSchema } from "../../../_App/Schemas/AppParametrages/CaisseParametrageSchema";

const CaisseParametrageForm = () => {
  const dispatch = useAppDispatch();
  const parametrage_caisse = useAppSelector(parametrageCaisse);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const { register, handleSubmit, reset, formState } =
    useForm<ParametragesCaisseType>();

  useEffect(() => {
    if (parametrage_caisse) {
      reset({
        _id: parametrage_caisse._id,
        barcodeOnly: parametrage_caisse.barcodeOnly,
        defaultCurrency: parametrage_caisse.defaultCurrency,
        direct_payment: parametrage_caisse.direct_payment,
        groupement_produits: parametrage_caisse.groupement_produits,
        message_promo: parametrage_caisse.message_promo,
        table: parametrage_caisse.table,
        instantRemise: parametrage_caisse.instantRemise,
        organisation_id: parametrage_caisse.organisation_id,
        vente_stock_negative: parametrage_caisse.vente_stock_negative,
        minimalStock: parametrage_caisse.minimalStock,
        methodeCloture: parametrage_caisse.methodeCloture,
      });
    } else {
      reset({
        barcodeOnly: false,
        defaultCurrency: "",
        direct_payment: false,
        groupement_produits: false,
        message_promo: "",
        table: false,
        instantRemise: false,
        organisation_id: currentOrganisation,
        vente_stock_negative: false,
        minimalStock: 0,
        methodeCloture: "",
      });
    }
  }, [parametrage_caisse]);
  const onSubmit: SubmitHandler<ParametragesCaisseType> = async (data) => {
    try {
      let res;
      if (parametrage_caisse) {
        res = await dispatch(updateParametrageCaisseApi(data));
      } else {
        data.organisation_id = currentOrganisation;
        res = await dispatch(saveParametrageCaisseApi(data));
        if (res.payload.success) {
          toast.success("Paramétrages enregistrés avec succès!");
        } else {
          toast.error("Erreur lors de l'enregistrement des paramètres.");
        }
      }
    } catch (error) {
      console.error("Error in API call:", error);
      // Handle the error as needed
    }
  };
  return (
    <div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox box box-shadow">
          <div className="widget-header">
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4
                  onClick={() => {
                    console.log(parametrage_caisse);
                    console.log(
                      "Default direct_payment:",
                      parametrage_caisse.direct_payment
                    );
                    console.log("Default table:", parametrage_caisse.table);
                  }}
                >
                  Paramétrages de caisse
                </h4>
              </div>
            </div>
          </div>
          {parametrage_caisse ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Paiement directe
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("direct_payment")}
                      defaultChecked={parametrage_caisse.direct_payment}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Tables {" (Restauration)"}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("table")}
                      defaultChecked={parametrage_caisse.table}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Groupement de produits
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("groupement_produits")}
                      defaultChecked={parametrage_caisse.groupement_produits}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Vente stock negative
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("vente_stock_negative")}
                      defaultChecked={parametrage_caisse.vente_stock_negative}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Vente code a barre seulement
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("barcodeOnly")}
                      defaultChecked={parametrage_caisse.barcodeOnly}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Remise instantané
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("instantRemise")}
                      defaultChecked={parametrage_caisse.instantRemise}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Message promo
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <textarea
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    {...register("message_promo")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.message_promo !== null
                        ? parametrage_caisse.message_promo?.toString()
                        : ""
                    }
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Stock minimal
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    type="number"
                    {...register("minimalStock")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.minimalStock !== null
                        ? parametrage_caisse.minimalStock?.toString()
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Methode de cloture journal
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    className="form-control mb-2"
                    type="number"
                    {...register("methodeCloture")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.methodeCloture !== null
                        ? parametrage_caisse.methodeCloture?.toString()
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Devise
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <select
                    className="form-control"
                    {...register("defaultCurrency")}
                  >
                    <option>
                      {parametrage_caisse
                        ? parametrage_caisse.defaultCurrency
                        : "Choisir un devise"}
                    </option>
                    <option value={"TND"}>Dinar tunisien</option>
                    <option value={"$"}>Dollar $</option>
                    <option value={"€"}>Euro €</option>
                  </select>
                </div>
              </div>
              <button type="submit" className="btn btn-primary-app">
                Enregistrer
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Paiement directe
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="checkbox" {...register("direct_payment")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Tables {" (Restauration)"}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="checkbox" {...register("table")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Groupement de produits
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("groupement_produits")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Vente stock negative
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("vente_stock_negative")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Vente code a barre seulement
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="checkbox" {...register("barcodeOnly")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Message promo
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <textarea
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    {...register("message_promo")}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Stock minimal
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    {...register("minimalStock")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Methode de cloture journal
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    className="form-control mb-2"
                    {...register("methodeCloture")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Devise
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <select
                    className="form-control"
                    {...register("defaultCurrency")}
                  >
                    <option>{"Choisir un devise"}</option>
                    <option value={"TND"}>Dinar tunisien</option>
                    <option value={"$"}>Dollar $</option>
                    <option value={"€"}>Euro €</option>
                  </select>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary-app"
                style={{ width: "100%", marginTop: "20px" }}
              >
                Enregistrer
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaisseParametrageForm;
