import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { t } from "i18next";
import ActionModel from "./ActionModel";
import Filtre from "../Stock/Filtre";
import { currentpage, findActionApi, ListMouvement, mouvementArticleApi, totalPages } from "../../_App/Redux/Slices/mouvement/mouvementSlice";
import TicketDeCaissePaid from "../Caisses/TicketDeCaisse/TicketDeCaissePaid";

export default function Mouvement() {
  type datafilterType = {
    carte: any;
    categories: any;
    sousCategories: any;
    carateristique: any;
    index: number;
  };
  const dispatch = useAppDispatch();

  const listMouvement = useAppSelector(ListMouvement);
  const [keyword, setkeyword] = useState("");
  const [date_debut, setdate_debut] = useState("");
  const [date_fin, setdate_fin] = useState("");
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [order_id, setorder_id] = useState(null);
  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  interface SelectionsState {
    [key: string]: any[];
  }
  const [datafilter, setdatafilter] = useState<datafilterType>();

  const [selections, setSelections] = useState<SelectionsState>({});
  const [spinner, setspinner] = useState(false);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const [action, setaction] = useState();
  const [modelAction, setmodelAction] = useState(false);
  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setdate_debut(formatDate(lastMonth));
    setdate_fin(formatDate(today));
  }, []);

  useEffect(()=>{
   setspinner(false)
  },[listMouvement])
  const findMouvement = async () => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: 1,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
    };
    setindex(1);
    setdatafilter(data);
    dispatch(mouvementArticleApi(data));
  };

  useEffect(()=>{
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: index,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
    };
    setindex(index);
    setdatafilter(data);
    dispatch(mouvementArticleApi(data));

  },[index])
  const findAction = async (id: string) => {
    const action = await dispatch(findActionApi(id));
    if (action?.payload && action?.payload?.data?.length !== 0) {
      setaction(action.payload);
      setmodelAction(true);
    }
  };

  return (
    <>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
      
        <div className="col-2">
          <label>{t("Date debut")}</label>
          <input
            type="date"
            className="form-control "
            value={date_debut}
            onChange={(e) => setdate_debut(e.target.value)}
          />
        </div>
        <div className="col-2">
          <label>{t("Date fin")}</label>
          <input
            type="date"
            className="form-control "
            value={date_fin}
            onChange={(e) => setdate_fin(e.target.value)}
          />
        </div>
        <Filtre
          spinner={spinner}
          handleSearch={findMouvement}
          setkeyword={setkeyword}
          t={t}
          setspinner={setspinner}
          setdatafilter={setdatafilter}
          setindex={setindex}
          searchfiltre={findMouvement}
          selectedCarte={selectedCarte}
          setSelectedCarte={setSelectedCarte}
          selectedCategorie={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          selectedSousCategorie={selectedSousCategorie}
          setSelectedSousCategorie={setSelectedSousCategorie}
          categoriesOptions={categoriesOptions}
          setcategoriesdOptions={setcategoriesdOptions}
          souscategoriesOptions={souscategoriesOptions}
          setsouscategoriesdOptions={setsouscategoriesdOptions}
          selections={selections}
          setSelections={setSelections}
        />

        {/*<div className="col-3">
          {listMouvement && listMouvement.length !== 0 && (
            <>
              <strong>
                {" "}
                {t("Article")} : {listMouvement[0]?.id_article?.titre?.fr}
              </strong>
              <br />
              <strong>
                {" "}
                {t(" Code a barre")} :{" "}
                {listMouvement[0]?.id_article?.code_a_barre[0]}
              </strong>
            </>
          )}
        </div>*/}
      </div>
      <div className="row layout-spacing mt-5">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th>{t("Famille")}</th>
                <th>{t("Article")}</th>
                <th>{t("Action")}</th>
                <th>{t("Organisation")}</th>
                <th>{t("Depots")}</th>
                <th>{t("Sens")}</th>
                <th> {t("Type")}</th>
                <th>{t("Quantite")}</th>
                <th>{t("Prix TTC")}</th>
                <th>{t("Prix ")}</th>
                <th>{t("Date")}</th>
              </thead>
              <tbody>
                {listMouvement &&
                  listMouvement.length !== 0 &&
                  listMouvement.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.article?.souscategorie?.libelle?.fr}</td>
                      <td>{item?.article?.titre?.fr}</td>
                      <td
                        className="lien-bleu-souligne"
                        onClick={() =>{
                          if(  item?.movements?.order_id ){
                              setorder_id(item?.movements?.order_id)
                              setShowModalTicket(true)
                          }else{
                            findAction(
                              item?.movements?.id_achat
                                ? item?.movements?.id_achat
                                : item?.movements?.id_Transfert
                                ? item?.movements?.id_Transfert
                                : item?.movements?.id_Inventaire
                                ? item?.movements?.id_Inventaire
                                : item?.movements?.id_Reservation   
                               
                                
                            )
                          }
                         
                        }

                        
                        }
                      >
                        {item?.movements?.id_achat && "Reception"}
                        {item?.movements?.id_Transfert && "Transfert"}
                        {item?.movements?.id_Inventaire && "Inventaire"}
                        {item?.movements?.id_Reservation && " Reservation"}
                        {item?.movements?.order_id && "Vente"}

                      </td>
                      <td>{item?.movements?.organisation_id?.nom}</td>
                      <td>{item?.movements?.position_depot?.libelle}</td>
                      <td>{item?.movements?.sens}</td>
                      <td>{item?.movements?.type}</td>
                      <td>{item?.movements?.quantite}</td>
                      <td>{item?.movements?.prix_ttc}</td>
                      <td>{Number(item?.movements?.prix_ttc * item?.movements?.quantite)||''}</td>

                      <td>{item?.movements?.createdAt?.split("T")[0]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
      <ActionModel
        t={t}
        data={action}
        showModal={modelAction}
        setshowModal={setmodelAction}
      />
         <TicketDeCaissePaid
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        order_id={order_id}
      />
    </>
  );
}
