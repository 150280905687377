import React, { useEffect, useMemo, useState } from "react";
import ListDimension from "./Matrice/ListDimension";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { updateMatriceApi } from "../../_App/Redux/Slices/matrice/matriceSlice";
import { handleKeyPress } from "../../_App/Helpers/helpers";

export default function Matrice({
  societe,
  getValue,
  setValue,
  index,
  matrice,
  listcaracteristique,
  reception,
  t,
  codearticle,
  getarticlebycode,
  showTicket
}: any) {
  const dispatch = useAppDispatch();
  const [list, setlist] = useState<any[]>([[], [], []]);
  const [dimension, setdimension] = useState<any[]>([]);
  const [checkedlist, setcheckedlist] = useState<any[]>([]);
  const [list_dimension, setlist_dimension] = useState([]);
  const [selected_dimension, setselected_dimension] = useState("");
  const [showModalDimension, setshowModalDimension] = useState(false);
  const [dimension_change, setdimension_change] = useState("");
  const [index_dimension, setindex_dimension] = useState(-1);
  const [imagelist, setimagelist] = useState<any[]>([]);
  const changedimension = (
    dimension: string,
    idcaracteristique: string,
    index: number
  ) => {
    setindex_dimension(index);
    const foundcaratristique = listcaracteristique.find(
      (item: any) => item._id === idcaracteristique
    );
    if (foundcaratristique) {
      setdimension_change(dimension);
      setlist_dimension(foundcaratristique.valeurs);
      setselected_dimension(foundcaratristique);
      setshowModalDimension(true);
    }
  };
  const updateMatrice = async (valeur: string) => {
    // ajout itemm to list_dimension
    const newmatrice = { ...matrice };

    if (index_dimension === 0) {
      newmatrice.list_dimension1 = [...newmatrice.list_dimension1, valeur];
    } else if (index_dimension === 1) {
      newmatrice.list_dimension2 = [...newmatrice.list_dimension2, valeur];
    } else if (index_dimension === 2) {
      newmatrice.list_dimension3 = [...newmatrice.list_dimension3, valeur];
    }

    const response = await dispatch(updateMatriceApi(newmatrice));
    if (index_dimension === 0) {
      newmatrice.list_dimension1 = newmatrice.list_dimension1.filter(
        (item: string) => item !== dimension_change
      );
    } else if (index_dimension === 1) {
      newmatrice.list_dimension2 = newmatrice.list_dimension2.filter(
        (item: string) => item !== dimension_change
      );
    } else if (index_dimension === 2) {
      newmatrice.list_dimension3 = newmatrice.list_dimension3.filter(
        (item: string) => item !== dimension_change
      );
    }
    setValue("matrice", newmatrice);
    return response;
  };
  const removeLigneMatrice = async (valeur: string) => {
    // ajout itemm to list_dimension
    const newmatrice = { ...matrice };

    if (index_dimension === 0) {
      newmatrice.list_dimension1 = newmatrice.list_dimension1.filtrer(
        (item: string) => item !== valeur
      );
    } else if (index_dimension === 1) {
      newmatrice.list_dimension2 = newmatrice.list_dimension2.filter(
        (item: string) => item !== valeur
      );
    } else if (index_dimension === 2) {
      newmatrice.list_dimension3 = newmatrice.list_dimension3.filtrer(
        (item: string) => item !== valeur
      );
    }
    setValue("matrice", newmatrice);
  };
  const insert_quantite = (
    item0: any,
    item1: any,
    item2: any,
    quantite: number
  ) => {
    let caracteristique_list:any[];
    if (item1 === "null") {
      caracteristique_list = [
        { caracteristique_id: dimension[0], value: item0 },
        { caracteristique_id: dimension[1], value: item1 },
        { caracteristique_id: dimension[2], value: item2 },
      ];
    } else {
      caracteristique_list = [
        { caracteristique_id: dimension[0], value: item0 },
        { caracteristique_id: dimension[1], value: item1 },
      ];
    }
    

    const findimage = imagelist.find(item=>item.valeur === caracteristique_list[0].value.valeur)
    const data = { cara: caracteristique_list, quantite: quantite , file: findimage?.file};

  
    let quantite_input = getValue(`${index}.quantite_input`) || 0;
    if (
      getValue(`${index}.ligne_reception`) &&
      getValue(`${index}.ligne_reception`)?.length !== 0
    ) {
      let isFound = false;
      const updatedMatrice = getValue(`${index}.ligne_reception`)
        ?.map((element: any) => {
          if (caracteristiqueEqual(element.cara, data.cara)) {
            isFound = true;
           
            if (quantite === 0) {
              // Return null to remove the element
              quantite_input = quantite_input - element.quantite;

              return null;
            } else {
              quantite_input = quantite_input - element.quantite + quantite;

              return { ...element, quantite: quantite };
            }
          }
          return element;
        })
        .filter((element: any) => element !== null); // Filter out null elements
        console.log('(isFound',isFound)

      if (!isFound) {
        // ajouter la quantite
        const copieinput_matrice = [
          ...getValue(`${index}.ligne_reception`),
          data,
        ];
        quantite_input = quantite_input + data.quantite;

        setValue(`${index}.ligne_reception`, copieinput_matrice);
      } else {
        setValue(`${index}.ligne_reception`, updatedMatrice);
      }
    } else {
      quantite_input = quantite_input + data.quantite;

      setValue(`${index}.ligne_reception`, [data]);
    }
    setValue(`${index}.quantite_input`, quantite_input);
  };

  
  function caracteristiqueEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    
    for (let i = 0; i < arr1.length; i++) {
      const item1 = arr1[i];
      const item2 = arr2[i];
      
      // Compare caracteristique_id
      if (item1.caracteristique_id !== item2.caracteristique_id) {
        return false;
      }
      
      // Compare value property
      const value1 = item1.value;
      const value2 = item2.value;
  
      if (typeof value1 !== typeof value2) {
        return false;
      }
  
      if (typeof value1 === 'string') {
        if (value1 !== value2) {
          return false;
        }
      } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
        // Deep comparison of objects
        if (!deepEqual(value1, value2)) {
          return false;
        }
      } else {
        return false;
      }
    }
  
    return true;
  }
  
  // Helper function for deep comparison of objects
  function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }
    
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) {
      return false;
    }
    
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    
    return true;
  }
  

  useEffect(() => {
    setcheckedlist([]);
    setlist([[], [], []]);
    if (matrice?._id) {
      setlist((prev: any) => {
        const newListArray = [...prev];
        newListArray[0] = matrice?.list_dimension1;
        return newListArray;
      });
      setlist((prev: any) => {
        const newListArray = [...prev];
        newListArray[1] = matrice?.list_dimension2;
        return newListArray;
      });
      setlist((prev: any) => {
        const newListArray = [...prev];
        newListArray[2] = matrice?.list_dimension3;
        return newListArray;
      });
      setdimension([
        matrice?.dimension1,
        matrice?.dimension2,
        matrice?.dimension3,
      ]);

      setcheckedlist(matrice.checked);
    }
  }, [matrice, showModalDimension]);
  const findimage = (dimmenssionid: string, valeur: string) => {
    const fils = getValue(`${index}.listfils`);
    const findarticle = fils?.find((item: any) =>
      item.caracteristiques.length !== 0
        ? item.caracteristiques[0]?.caracteristique_id === dimmenssionid &&
          item.caracteristiques[0]?.value === valeur
        : false
    );
    if (findarticle && findarticle.image?.length !== 0) {
      return findarticle.image[0];
    } else {
      return "notfound";
    }
  };

  const handleChangeImage = (files: any, valeur: string) => {
    if (files.length > 0) {

      const file = files[0];
      const newImage = { file, valeur };
      const list = [...(getValue(`${index}.ligne_reception`) || [])];
      let findindex = -1;

      findindex = list?.findIndex(
        (item: any) => item?.cara[0]?.value?.valeur === valeur
      );
      if (findindex !== -1) {
       

        list[findindex].file = file;
        setValue(`${index}.ligne_reception`,list)
      }
      setimagelist((prev) => [...prev, newImage]);
    }
  };
 
  return (
    <div>
      <div className=" table-responsive mt-5  ">
        <table id="style-2" className=" style-2 text-center bg-white ">
          <thead style={{ background: "#f1f2f3" }}>
            <tr>
              <th className="text-center"></th>

              {list[1]?.map((item1: any, poidsIndex: number) => (
                <th
                  className="text-center"
                  style={{
                    cursor: "pointer",
                    width: "100px",
                    height: "40px",
                    padding: "0 0 0 0",
                    border: "1px solid #ccc",
                    backgroundColor: listcaracteristique.find(
                      (item: any) => item._id === dimension[1]
                    )?.color
                      ? item1.valeur
                      : null,
                  }}
                  key={poidsIndex}
                  onClick={() => changedimension(item1, dimension[1], 1)}
                >
                  {listcaracteristique.find(
                    (item: any) => item._id === dimension[1]
                  )?.color
                    ? item1.libelle
                    : item1}
                  {list[2]?.map((item2: any) => (
                    <>
                      <th className="border text-center">
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100px",
                            height: "40px",
                            backgroundColor: listcaracteristique.find(
                              (item: any) => item._id === dimension[2]
                            )?.color
                              ? item2.valeur
                              : null,
                          }}
                          onClick={() =>
                            changedimension(item2, dimension[2], 2)
                          }
                        >
                          {listcaracteristique.find(
                            (item: any) => item._id === dimension[2]
                          )?.color
                            ? item2.libelle
                            : item2}
                        </div>
                      </th>
                    </>
                  ))}
                </th>
              ))}
              <th
                className="text-center"
                style={{
                  cursor: "pointer",
                  width: "100px",
                  height: "40px",
                  padding: "0 0 0 0",
                  border: "1px solid #ccc",
                }}
              >
                {t("Image")}
              </th>
            </tr>
          </thead>
          <tbody>
            {list[0]?.map((item0: any, item0Index: number) => (
              <tr key={item0Index}>
                <td className="text-center">
                  <div
                    style={{
                      cursor: "pointer",
                      width: "100px",
                      height: "40px",
                      padding: "0 0 0 0",
                      border: "1px solid #ccc",
                      backgroundColor: listcaracteristique.find(
                        (item: any) => item._id === dimension[0]
                      )?.color
                        ? item0.valeur
                        : null,
                    }}
                    onClick={() => changedimension(item0, dimension[0], 0)}
                  >
                    {listcaracteristique.find(
                      (item: any) => item._id === dimension[0]
                    )?.color
                      ? item0.libelle
                      : item0}
                  </div>
                </td>

                {list[1]?.map((item1: any, item1Index: number) => (
                  <td
                    key={item1Index}
                    style={{
                      cursor: "pointer",
                      width: "50px",
                    }}
                  >
                    {list[2]?.length !== 0 ? (
                      list[2]?.map((item2: any, item2Index: number) => (
                        <td
                          key={item2Index}
                          className="border"
                          style={{
                            height: "50px",
                            width: "100px",
                          }}
                        >
                          <div className="checkbox-container">
                            <input
                              type="number"
                              style={{
                                height: "50px",
                                width: "100%",
                              }}
                              disabled={reception?.cloturer}
                              value={
                                getValue(`${index}.ligne_reception`)?.find(
                                  (item: any) =>
                                    caracteristiqueEqual(item.cara, [
                                      {
                                        caracteristique_id: dimension[0],
                                        item0,
                                      },
                                      {
                                        caracteristique_id: dimension[1],
                                        item1,
                                      },
                                      {
                                        caracteristique_id: dimension[2],
                                        item2,
                                      },
                                    ])
                                )?.quantite
                              }
                              onKeyPress={handleKeyPress}
                              onChange={(e) =>
                                insert_quantite(
                                  item0,
                                  item1,
                                  item2,
                                  parseFloat(e.target.value) || 0
                                )
                              }
                              className="text-center numberinput"
                            />

                            <div className="checkbox-label"></div>
                          </div>
                        </td>
                      ))
                    ) : (
                      <td
                        className="border"
                        style={{
                          cursor: "pointer",
                          width: "100px",
                        }}
                      >
                        <div className="checkbox-container">
                          <input
                            type="number "
                            style={{
                              height: "50px",
                              width: "100%",
                              color: "blue",         
                              textDecoration: "underline", 
                              cursor: "pointer",          
                              textAlign: "center",       
                              lineHeight: "50px"         
                            }}
                          
                            value={
                              getValue(`${index}.ligne_reception`)?.find(
                                (item: any) =>
                                  caracteristiqueEqual(item.cara, [
                                    {
                                      caracteristique_id: dimension[0],
                                      value: item0,
                                    },
                                    {
                                      caracteristique_id: dimension[1],
                                      value: item1,
                                    },
                                  ])
                              )?.quantite
                            }
                            onKeyPress={handleKeyPress}
                            onClick={() => {
                              if (reception?.cloturer) {
                               showTicket(item0.libelle,item1)
                              }
                            }}
                       
                            onChange={(e) =>
                              insert_quantite(
                                item0,
                                item1,
                                "null",
                                parseFloat(e.target.value) || 0
                              )
                            }
                            className={reception?.cloturer ? 'custom-disabled' : 'text-center numberinput '}

                          />

                          <div className="checkbox-label"></div>
                        </div>
                      </td>
                    )}
                  </td>
                ))}
                <td className="border">
                  {findimage(dimension[0], item0.valeur) !== "notfound" ? (
                    <img
                      src={
                        process.env.REACT_APP_API_PUBLIC_URL +
                        "article/" +
                        findimage(dimension[0], item0.valeur)
                      }
                      style={{
                        height: "100px",
                        width: "100px",
                      }}
                      alt="..."
                    />
                  ) : (
                    <div>
                      <input
                        type="file"
                        onChange={(e) =>
                          handleChangeImage(e.target.files, item0.valeur)
                        }
                        accept="image/*"
                      />

                      {imagelist.find((item) => item.valeur === item0.valeur)
                        ?.file && (
                        <img
                          key={item0.valeur}
                          alt={`Selected ${item0.valeur}`}
                          style={{ width: "200px", height: "auto" }}
                        />
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ListDimension
        dimension_change={dimension_change}
        listdimension={list_dimension}
        selecteddimension={selected_dimension}
        ShowModal={showModalDimension}
        setShowModal={setshowModalDimension}
        updateMatrice={updateMatrice}
        removeLigneMatrice={removeLigneMatrice}
        t={t}
      />
    </div>
  );
}
