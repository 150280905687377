// PanierSlice.js
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { OrderType } from "../../../Types/Entites/Orders/OrderType";

interface UpdateOrderParams {
  orderId?: string;
  status: string | null;
  paidProducts?: any;
  vendeur : String | null;
}


/**
 * list  of reservation by organisation.
 * @public
 */
export const findReservationsByOrganisationApi = createAsyncThunk(
  "reservation/findReservationsByOrganisationApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    const {organisation_id,index}=data
    try {
      const response = await axios.get(
        `reservation/findReservationsByOrganisationApi/${organisation_id}/${index}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface OrdersState {
  listReservations: any[];
  selectedOrder:   OrderType | null;
  totalPages:any;
  currentpage:any

}

const initialState: OrdersState = {
  listReservations: [],
  selectedOrder:null,
  totalPages:null,
  currentpage:null
};

export const ReservationSlice = createSlice({
  name: "Reservation",
  initialState,
  reducers: {
    SelectReservation: (
      state,
      action: PayloadAction<{
        order_id: string;
      }>
    ) => {
      const { order_id } = action.payload;
      const order = state.listReservations.find((item) => item._id === order_id);
      if (!order) {
        return state;
      }

      return {
        ...state,
        selectedOrder: order.order,
      };
    },
  
  },

  extraReducers: (builder) => {
 
    builder.addCase(findReservationsByOrganisationApi.fulfilled, (state, action) => {
   
      return {
        ...state,
        listReservations: action.payload.data,
        totalPages:action.payload.totalPages,
        currentpage:action.payload.currentPage,
        
      };
    });
   
  },
});
export const { SelectReservation } =
ReservationSlice.actions;
export const ListReservations = (state: RootState) => state.reservation.listReservations;
export const currentPage = (state: RootState) => state.reservation.currentpage;
export const totalPages = (state: RootState) => state.reservation.totalPages;


export default ReservationSlice.reducer;
