import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ListCartes } from "../../_App/Redux/Slices/cartes/carteSlice";
import {
  findCategoriesByCarteApi,
  ListCategories,
  ListCategoriesByCarte,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import { CategorieSousCategories, ListSousCategories } from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import { findArticleByBarCode } from "../../_App/Redux/Slices/article/articleSlice";
import { includes } from "lodash";

export default function ConditionCommission({
  t,
  optionsorg,
  handleSelectChangeOrganisation,
  listorganisationselected,
  optionsUser,
  handleSelectChangeUser,
  listuserselected,
  register,
  getValue,
  setValue,
  setInputValue,
  inputValue,
  selectedCarte,
  setSelectedCarte,
  selectedCategorie,
  setSelectedCategorie,
  selectedSousCategorie,
  setSelectedSousCategorie
}: any) {
  const dispatch = useAppDispatch();


  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);

  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>([]);

  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategories);
  const sousCategories = useAppSelector(ListSousCategories);
  const [fournisseur, setFournisseur] = useState(null);
  const [article, setArticle] = useState(null);
  const [rand, setRand] = useState(true);

  const handleInputArticleChange = async (event: any) => {
    const barcode = event.target.value;
    setInputValue([barcode]);
    const articleNames = getValue("articleNames") || []; // Track article names

    // Fetch article by barcode
    const resultArticle = await dispatch(
      findArticleByBarCode(
        articleNames?.length !== 0
          ? [barcode.split(articleNames.join(","))[1]]
          : [barcode]
      )
    );
    if (resultArticle.payload) {
      // Get existing articles
      const listaricle = getValue("article") || []; // Ensure listaricle is an array

      // Update article IDs and names
      const newArticleIds = listaricle?.length
        ? [...listaricle, resultArticle?.payload?._id]
        : [resultArticle?.payload?._id];

      const newArticleNames = articleNames?.length
        ? [...articleNames, resultArticle?.payload?.titre?.fr]
        : [resultArticle?.payload?.titre?.fr];

      // Set new values
      setValue("article", newArticleIds);
      setValue("articleNames", newArticleNames);

      setArticle(resultArticle?.payload?._id);
      setFournisseur(resultArticle?.payload?.produit_ref?.fournisseurs);

      // Update input value with the new list of names
      setInputValue(newArticleNames);
    } else {
      // Handle the case where no article is found, if necessary
    }
  };
  const CartesOptions = cartes?.map((option) => ({
    value: option._id,
    label: option.libelle.fr,
  }));

  const handleSelectChangeCartes = (selectedOption: any) => {
    const cartes = selectedOption.map((item: any) => item.value);
    setSelectedCarte(cartes);
    setValue("carte", cartes);
  };
  const handleSelectChangeCategories = (selectedOption: any) => {
    const categorie = selectedOption.map((item: any) => item.value);
    setSelectedCategorie(categorie);
    setValue("categorie", categorie);
  };
  const handleSelectChangesousCategories = (selectedOption: any) => {
    const souscategorie = selectedOption.map((item: any) => item.value);
    setSelectedSousCategorie(souscategorie);
    setValue("souscategorie", souscategorie);
  };

  const handleClearInput = () => {
    setInputValue([]);
    setArticle(null);
  };

  useEffect(() => {
    if (selectedCarte?.length !== 0) {
      

      const listcategoriescarte = categories?.filter((item: any) =>
        selectedCarte?.includes(item.carte_id)
      );
      setcategoriesdOptions(
        listcategoriescarte?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [selectedCarte]);

  useEffect(() => {
    if (selectedCategorie?.length !== 0) {
      

      const ListSousCategoriescategories = sousCategories?.filter((item: any) =>
        selectedCategorie?.includes(item.categorie_id)
      );
      setsouscategoriesdOptions(
        ListSousCategoriescategories?.map((option) => ({
          value: option._id,
          label: option.libelle.fr,
        }))
      );
    }
  }, [selectedCategorie]);
  return (
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="client" title={t("Conditions organisation")}>
        <div className="row">
          <div className="col-12">
            <div>
              <label htmlFor="niveau ">
                <span style={{ color: "red" }}>*</span>
                {t("Organisation")}
              </label>
              <Select
                isMulti
                options={optionsorg}
                isSearchable={true}
                onChange={handleSelectChangeOrganisation}
                value={listorganisationselected.map((orgId: any) =>
                  optionsorg?.find((org: any) => org.value === orgId)
                )}
              />
            </div>
          </div>
        </div>
      </Tab>
      <Tab eventKey="user" title="Conditions user">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="montant">
                {" "}
                <span style={{ color: "red" }}>*</span>
                {t("Users")}
              </label>
              <Select
                isMulti
                options={optionsUser}
                isSearchable={true}
                onChange={handleSelectChangeUser}
                value={listuserselected.map((userid: any) =>
                  optionsUser?.find((user: any) => user.value === userid)
                )}
              />
            </div>
          </div>
        </div>
      </Tab>
      <Tab eventKey="achat" title="Condition achat">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="montant">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Montant minimum d'achat")}
              </label>
              <input
                type="number"
                className="form-control"
                id="montant"
                {...register("montant_achat")}
              />
            </div>
          </div>
          {/* <div className="col-6">
            <div className="form-group">
              <label htmlFor="cumulable">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Cumulable")}
              </label>
              <input
                className="m-2"
                //checked={cumulableachat}
                type="checkbox"
                id="cumulable"
              />
            </div>
          </div>*/}
        </div>
      </Tab>

      <Tab eventKey="produit" title="Conditions Produits">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="carte">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Carte")}
              </label>
              <Select
                isMulti
                options={CartesOptions}
                isSearchable={true}
                onChange={handleSelectChangeCartes}
                value={selectedCarte?.map((item: any) =>
                  CartesOptions?.find((cat: any) => cat.value === item)
                )}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="categorie">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Categorie")}
              </label>
              <Select
                isMulti
                options={categoriesOptions}
                isSearchable={true}
                onChange={handleSelectChangeCategories}
                value={selectedCategorie?.map((item: any) =>
                  categoriesOptions?.find((cat: any) => cat.value === item)
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="sous">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Sous categorie")}
              </label>
              <Select
                isMulti
                options={souscategoriesOptions}
                isSearchable={true}
                onChange={handleSelectChangesousCategories}
                value={selectedSousCategorie?.map((item: any) =>
                  souscategoriesOptions?.find((cat: any) => cat.value === item)
                )}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label htmlFor="Article">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Article")}
              </label>
              <div className="input-button" style={{ display: "flex" }}>
                <input
                  value={
                    inputValue && Array.isArray(inputValue)
                      ? inputValue.join(",")
                      : ""
                  }
                  onChange={(e) => {
                    handleInputArticleChange(e);
                    setRand(!rand);
                  }}
                  type="text"
                  className="form-control"
                  id="Article"
                />
                {inputValue && (
                  <button
                    onClick={handleClearInput}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="quantity">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Quantite / chiffre min")}
              </label>
              <input
                type="number"
                className="form-control"
                id="quantite"
                {...register("min")}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="quantity">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Quantite / chiffre max")}
              </label>
              <input
                type="number"
                className="form-control"
                id="quantite"
                {...register("max")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label>
                Quantité <span style={{ color: "red" }}></span>
              </label>
              <input
                className="m-2"
                type="radio"
                id="quantite1"
                name="quantite"
                value={"quantite"}
                {...register("type")}
              />
              <label htmlFor="quantite1">
                Chiffre <span style={{ color: "red" }}></span>
              </label>
              <input
                className="m-2"
                type="radio"
                id="quantite2"
                name="quantite"
                value={"chiffre"}
                {...register("type")}
              />
            </div>
          </div>

          {/* <div className="col-4">
            <div className="form-group">
              <label htmlFor="cumulable">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Cumulable")}
              </label>
              <input className="m-2" type="checkbox" id="cumulable" />
            </div>
          </div>*/}
        </div>
      </Tab>
    </Tabs>
  );
}
