import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import {
  DefaultClient,
  Panier,
  Reduction,
  addClient,
  addFreeItem,
  addToPanier,
  confirmOrder,
  decreaseQuantity,
  findDefaultClientsBySociete,
  increaseQuantity,
  removeFromPanier,
  resetOperationType,
  resetPanier,
  resetReduction,
  setClientRemise,
  setFideliteLIst,
  setInstantRemise,
  setItemInstantRemise,
  setNetPrice,
  setOperationType,
  setQuantity,
  setReduction,
  setRemises,
} from "../../../../_App/Redux/Slices/Caisse/PanierSlice";
import { selectedTable } from "../../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  ListArticles,
  findArticleByBarCode,
  updateArticleStockApi,
} from "../../../../_App/Redux/Slices/article/articleSlice";
import {
  ListOrders,
  resetRetrievedOrder,
  resetSelectedOrder,
  saveOrderApi,
} from "../../../../_App/Redux/Slices/Orders/OrderSlice";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  societeId,
} from "../../../../_App/Redux/Slices/Auth/authSlice";
import {
  findClientByBarcode,
  ListClient,
} from "../../../../_App/Redux/Slices/client/clientSlice";
import ClientType, {
  FideliteType,
} from "../../../../_App/Types/Entites/ClientType";
import { updateTableStatusApi } from "../../../../_App/Redux/Slices/table/tableSlice";
import CalculatorModal from "../../../../Components/Caisses/Calculator/Calculator";
import { useTranslation } from "react-i18next";
import { DepotDefautOrganisation } from "../../../../_App/Redux/Slices/depot/depotSlice";
import FicheClient from "../../../../Components/Client/FicheClient";
import StockGlobale from "../../../../Components/Caisses/Articles/StockGlobale";
import { parametrageCaisse } from "../../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import Swal from "sweetalert2";
import {
  allCartRemises,
  checkDiscountsByArticle,
  getGratuitesApplicable,
  getProgramFideliteApplicable,
  getRemiseApplicable,
  getTestRemiseApplicable,
} from "../../../../_App/Redux/Slices/remise/remiseSlice";
import {
  calculateRemise,
  maxRemise,
  sumGratuiteQuantity,
} from "../../../../_App/Helpers/helpers";
import { ArticleType } from "../../../../_App/Types/Entites/ArticleType";
import {
  GratuiteType,
  ProgramFideliteType,
} from "../../../../_App/Types/Entites/RemiseType";
import _ from "lodash";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { toast } from "react-toastify";
import { findBonByBarcode } from "../../../../_App/Redux/Slices/reduction/reductionSlice";

interface OrderProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hidePaymentButton: Boolean | undefined;
}
const Cart: React.FC<OrderProps> = ({ setVisible, hidePaymentButton }) => {
  const today = new Date();

  const [load, setLoad] = useState(true);
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const parametrage = useAppSelector(parametrageCaisse);
  const reduction = useAppSelector(Reduction);
  const user = useAppSelector(selectUser);
  const [refresh, setRefresh] = useState(true);
  const listClient = useAppSelector(ListClient);
  useEffect(() => {
    if (cart.items.length) {
      const remise = async () => {
        const obj = {
          order: cart.items,
          client_id: selectedClient?._id,
          achat: cart.totalNet,
          organisation_id: currentOrganisation,
        };
        const responseRemises = await dispatch(allCartRemises(obj));
        const responseGratuite = await dispatch(getGratuitesApplicable(obj));
        if (responseRemises && responseRemises.payload) {
          if (responseRemises.payload.length > 0) {
            dispatch(setRemises(responseRemises.payload));
          }
        }
        if (
          responseGratuite &&
          responseGratuite.payload &&
          responseGratuite.payload.length
        ) {
          responseGratuite?.payload?.map((item: GratuiteType) => {
            dispatch(
              addFreeItem({
                article: item.article,
                quantity: item.quantity,
              })
            );
          });
        }

        dispatch(setNetPrice());
      };
      const fidelite = async () => {
        const obj = {
          order: cart.items,
          client_id: selectedClient?._id,
          achat: cart.totalNet,
          organisation_id: currentOrganisation,
        };
        const response = await dispatch(getProgramFideliteApplicable(obj));
        if (response && response.payload) {
          dispatch(setFideliteLIst(response.payload));
        }
      };
      remise();
      fidelite();
    }
  }, [refresh]);

  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const boxClass = parametrage && parametrage.barcodeOnly ? " light-box" : "";
  const bgClass =
    parametrage && parametrage.barcodeOnly ? " light-background" : "";

  const handleKeyPress = () => {
    const currentElement = document.activeElement;
    if (currentElement && currentElement.tagName.toLowerCase() !== "input") {
      const myInputElement = document.getElementById("code_input");

      if (myInputElement) {
        myInputElement.focus();
      }
    }
    
  };

  const handleSetTypeOperation = async (amount: any, refunded: any) => {
    Swal.fire({
      title: "Voulez vous vraiment retourner ces articles ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setOperationType({ type: "Retour", refunded: refunded }));
        dispatch(setNetPrice());
        refunded.map((item: any) => {
          dispatch(
            updateArticleStockApi({
              id: item.article._id,
              depotId: currentDepot._id,
              soldQuantity: -item.quantity,
            })
          );
        });
      }
    });
  };

  const selectedtable = useAppSelector(selectedTable);
  const dispatch = useAppDispatch();

  const cart = useAppSelector(Panier);

  const orders = useAppSelector(ListOrders);
  const defaultClient = useAppSelector(DefaultClient);
  const societe_id = useAppSelector(societeId);
  const journal = useAppSelector(selectJournalCaisse);

  useEffect(() => {
    if (societe_id) dispatch(findDefaultClientsBySociete(societe_id));
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [dispatch, load]);

  const [refundedItems, setRefundedItems] = useState<any>([]);
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<ClientType | undefined>(
    defaultClient
  );

  const [openCalculator, setOpenCalculator] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalStock, setShowModalStock] = useState(false);
  const [selectedArticle, setSelectedArticles] = useState(null);

  useEffect(() => {
    if (societe_id) dispatch(findDefaultClientsBySociete(societe_id));
    if (cart.client_id === defaultClient._id) setSelectedClient(defaultClient);
   
   
  }, [dispatch]);
  useEffect(()=>{
     if (typeof cart.client_id === "string") {
      const findclient = listClient?.find(
        (item) => item._id === cart.client_id
      );
      setSelectedClient(findclient);
    } else {
      setSelectedClient(cart.client_id);
    }
  },[ cart.client_id ])

  const articles = useAppSelector(ListArticles);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const resetCartToDefault = async () => {
    if (defaultClient) {
      dispatch(resetPanier());
      dispatch(resetReduction());
      dispatch(addClient(defaultClient._id));
      setSelectedClient(defaultClient);
    }
  };
  const [searchTerm, setSearchTerm] = useState<string>("");

  const addToCart = async (item: any) => {
    if (item?.code_a_barre?.length && item) {
      const resAdd = await dispatch(
        addToPanier({
          article: item,
          quantity: cart.calculatedQuantity,
          prix_unitaire: item.prix_vente_ttc,
          prix_total: item.prix_vente_ttc * cart.calculatedQuantity,
          paid_quantity: 0,
          remise: cart.InstantRemise,
          remiseClient: cart.clientRemise,
          prixNet: item.prix_vente_ttc * cart.calculatedQuantity,
        })
      );
      await dispatch(setNetPrice());
      await dispatch(setQuantity(1));
      return item;
    } else {
      console.log("error occurred");
    }
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    const resultArticle = await dispatch(findArticleByBarCode(inputValue));
    const resultClient = await dispatch(findClientByBarcode(inputValue));
    const resultBon = await dispatch(findBonByBarcode(inputValue));

    if (resultArticle.payload) {
      // Add to cart first
      const resAdd = await addToCart(resultArticle.payload);
      if (resAdd) {
        // Fetch remises only after item is added to the cart
        setRefresh(!refresh);
      }

      setSearchTerm("");
    } else if (resultClient.payload) {
      setSearchTerm("");
      setSelectedClient(resultClient.payload);
      if (resultClient.payload.note) {
        toast.info(resultClient.payload.note);
      }
      await dispatch(addClient(resultClient.payload._id));
      setRefresh(!refresh);
      await dispatch(setClientRemise(resultClient.payload.remise));
      await dispatch(setNetPrice());
    } else if (resultBon.payload) {
      setSearchTerm("");

      const expireDate = new Date(resultBon.payload.dateEcheance);

      const expireDateLocal = new Date(
        expireDate.getTime() + expireDate.getTimezoneOffset() * 60000
      );
      if (expireDateLocal < today) {
        ToastWarning("Ce bon de réduction est expiré !");
      } else {
        dispatch(setReduction(resultBon.payload.amount));
        setRefresh(!refresh);
      }
    }
  };

  const removeFromCart = async (item: any) => {
    dispatch(removeFromPanier(item._id));
    setRefresh(!refresh);
    dispatch(setNetPrice());
  };

  const removeSingleProductFromCart = async (article: any) => {
    dispatch(decreaseQuantity(article._id));
    dispatch(setNetPrice());
    const item = cart.items.find((item) => item.article._id === article._id);

    setRefresh(!refresh);
    dispatch(setNetPrice());
  };

  const addSingleProductToCart = async (article: any) => {
    dispatch(increaseQuantity(article._id));
    dispatch(setNetPrice());
    const item = cart.items.find((item) => item.article._id === article._id);

    setRefresh(!refresh);
    dispatch(setNetPrice());
  };

  function generateOrderNumber(): string {
    let uniqueId: string;
    const existingOrderNumbers = orders.map((order) => order.order_number);
    do {
      uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
    } while (
      existingOrderNumbers.some((orderNumber) => orderNumber === uniqueId)
    );
    return uniqueId;
  }

  const [isLoading, setIsLoading] = useState(false);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const handleConfirmOrder = async (data: any, status: string) => {
    let order = { ...data };
    setIsLoading(true);
    let response;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total;
    order.totalNet = cart.totalNet;

    order.order_number = generateOrderNumber();
    order.status = status;
    order.client_id = selectedClient ? selectedClient._id : null;
    order.journal_id = journal?._id;
    order.depots_id = currentDepot._id;
    order.vendeur = null;
    order.user_id = user?._id;
    order.societe_id = societe_id;
    try {
      response = await dispatch(saveOrderApi(order));
      order.items.forEach((value: any) => {
        dispatch(
          updateArticleStockApi({
            id: value.article._id,
            depotId: currentDepot._id,
            soldQuantity: value.quantity,
          })
        );
      });
      if (response.payload.success) {
        dispatch(confirmOrder());
        resetCartToDefault();
        if (selectedtable) {
          dispatch(
            updateTableStatusApi({
              table_id: selectedtable._id,
              status: "Occupée",
            })
          );
        }
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetCart = async () => {
    await dispatch(resetSelectedOrder());
    await dispatch(resetRetrievedOrder());
    await dispatch(setQuantity(1));
    await dispatch(resetOperationType());
    await dispatch(setInstantRemise(0));
  };

  return (
    <>
      <CalculatorModal
        ShowModal={openCalculator}
        setShowModal={setOpenCalculator}
      />
      <StockGlobale
        article={selectedArticle}
        setSelectedArticles={setSelectedArticles}
        ShowModal={showModalStock}
        setShowModal={setShowModalStock}
      />
      {selectedClient && selectedClient !== defaultClient ? (
        <FicheClient
          client={selectedClient}
          ShowModalClient={showModal}
          setShowModalClient={setShowModal}
        />
      ) : null}
      <div className="cart-header">
        <div className="widget-message" style={{ padding: "10px" }}>
          <div className="widget-title mb-10">
            <h5>{t("cart")}</h5>
            <button
              className="delete-button"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              onClick={() => {
                resetCartToDefault();
                resetCart();
                setLoad(!load);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col-2"
            style={{ display: "flex", alignItems: "center", width: "auto" }}
          >
            <button
              className="delete-button btn-primary"
              onClick={(e) => setOpenCalculator(true)}
              style={{ width: "50px", height: "50px", fontSize: "26px" }}
            >
              <i className="fas fa-calculator"></i>
            </button>
            <p
              style={{
                fontSize: "1.5em",
                margin: "0",
                color:
                  parametrage && parametrage.barcodeOnly ? "#3e3e3e" : "white",
              }}
            >
              {cart.calculatedQuantity}
            </p>
          </div>
          <div className="col-5">
            {!cart.orderConfirmed ? (
              <div className="navbar-item flex-row navbar-dropdown">
                <div className="nav-item align-self-center search-animated">
                  <i className="fas fa-barcode"></i>
                  <form
                    className="form-inline search-full form-inline search"
                    role="search"
                    onSubmit={handleSubmit}
                  >
                    <div className="search-bar">
                      <input
                        type="text"
                        className={
                          "form-control search-form-control ml-lg-auto" +
                          boxClass
                        }
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder={t("Enter product code")}
                        autoFocus
                        id="code_input"
                        style={{ height: "40px" }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={
              parametrage && parametrage.instantRemise ? "col-3" : "col-5"
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #000",
              borderRadius: "10px",
              height: "40px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (selectedClient && selectedClient._id !== defaultClient._id)
                setShowModal(true);
            }}
          >
            {selectedClient?.nom}{" "}
            {selectedClient?.remise
              ? " | " + selectedClient?.remise + "%"
              : null}{" "}
            <br></br>
            {cart?.programmesFidelite?.length &&
            cart.client_id.toString() !== defaultClient._id
              ? " + " +
                cart.programmesFidelite?.reduce(
                  (acc, item) => acc + item.points,
                  0
                ) +
                " points"
              : null}
          </div>
          {/* <button onClick={() => alert(calculateRemise(cart.items[0]))}>
            test
          </button> */}
          {parametrage && parametrage.instantRemise ? (
            <div className="col-2">
              <div className="navbar-item flex-row navbar-dropdown">
                <div className="nav-item align-self-center search-animated">
                  <i className="fas fa-percentage"></i>
                  <form
                    className="form-inline search-full form-inline search"
                    role="search"
                  >
                    <div className="search-bar">
                      <input
                        type="number"
                        className={
                          "form-control search-form-control ml-lg-auto" +
                          boxClass
                        }
                        value={cart.InstantRemise}
                        max={100}
                        onChange={(e) => {
                          let value = Number(e.currentTarget.value);
                          if (value > 100) value = 100;
                          dispatch(setInstantRemise(value));
                          dispatch(setNetPrice());
                          setLoad(!load);
                        }}
                        style={{ height: "40px" }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {parametrage && cart.items && cart.items.length > 0 ? (
        parametrage.barcodeOnly ? (
          <div className="widget-content searchable-container list">
            <div className="searchable-items list">
              <div className="items items-header-section item-content">
                <div className="user-email col-2">
                  <h4>Article</h4>
                </div>
                <div className="user-email col-2">
                  <h4>Prix</h4>
                </div>
                <div className="user-email col-2">
                  <h4> Quantité</h4>
                </div>
                <div className="user-email col-2">
                  <h4>Remise</h4>
                </div>
                <div className="user-email col-2">
                  <h4> Total</h4>
                </div>
                <div className="user-email col-2">
                  <h4></h4>
                </div>
              </div>
              {cart.items.map((item: any, index) => (
                <div className="items item-content" key={index}>
                  <div className="user-profile col-2 p-0">
                    {/* <img
                      src={
                        item.article.image && item.article.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.article.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="avatar"
                      className="ml-0"
                    /> */}
                    <Zoom>
                      <img
                        alt=""
                        src={
                          item.article.image && item.article.image[0]
                            ? process.env.REACT_APP_API_PUBLIC_URL +
                              "article/" +
                              item.article.image[0]
                            : "/assets/assets/img/no-image.jpg"
                        }
                        width="100"
                      />
                    </Zoom>
                    <div
                      className="user-meta-info"
                      onClick={(e) => {
                        setSelectedArticles(item.article);
                        setShowModalStock(true);
                        console.log(calculateRemise(item));
                      }}
                    >
                      <p className="user-name" data-name="Alan Green">
                        {item.article.titre.fr}
                      </p>
                      <p className="user-work" data-occupation="Web Developer">
                        {item.article.caracteristiques
                          .map(
                            (caracteristique: any) =>
                              caracteristique.value + " "
                          )
                          .join("")}
                      </p>
                    </div>
                  </div>
                  <div className="user-email col-2">
                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <p>{item.prix_unitaire}</p>
                    </span>
                  </div>
                  <div className="user-email col-2">
                    <div
                      className="w-profile-links"
                      style={{
                        alignItems: "center",
                        justifyContent: "right",
                        display: "flex",
                        padding: 0,
                      }}
                    >
                      <button
                        className={"minus-button" + btnClass + bgClass}
                        onClick={() => {
                          removeSingleProductFromCart(item.article);
                        }}
                        disabled={cart.orderConfirmed || item.prix_total === 0}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <span className={"w-p-link" + btnClass}>
                        <p>{item.quantity}</p>
                      </span>
                      <button
                        className={"minus-button" + btnClass + bgClass}
                        onClick={() => {
                          addSingleProductToCart(item.article);
                        }}
                        disabled={cart.orderConfirmed || item.prix_total === 0}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="user-email col-2">
                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <form
                        className="form-inline search-full form-inline search"
                        role="search"
                      >
                        <div className="search-bar">
                          <input
                            type="number"
                            className={
                              "form-control search-form-control ml-lg-auto" +
                              boxClass
                            }
                            value={item.remise}
                            max={100}
                            disabled={parametrage && !parametrage.instantRemise}
                            onChange={(e) => {
                              // Ensure the value does not exceed 100
                              let value = Number(e.currentTarget.value);
                              if (value > 100) value = 100;
                              if (value < 0) value = 0;
                              dispatch(
                                setItemInstantRemise({
                                  id: item.article._id,
                                  remise: value,
                                })
                              );
                              dispatch(setNetPrice());
                              setLoad(!load);
                            }}
                            style={{ height: "40px", width: "inherit" }}
                          />
                        </div>
                      </form>
                    </span>
                  </div>

                  <div className="user-email col-2">
                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <p className={item.prixNet > 0 ? "" : "text-success"}>
                        {item.prixNet.toFixed(3) +
                          " " +
                          parametrage.defaultCurrency}
                      </p>
                    </span>
                  </div>
                  <div className="user-email col-2">
                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <button
                        className={"delete-button" + btnClass}
                        onClick={() => removeFromCart(item.article)}
                        disabled={cart.orderConfirmed}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="cart-footer">
              {cart && cart.operationType !== "Retour" ? (
                <button
                  className={"btn btn-success mb-2" + btnClass}
                  style={{ width: "100%", background: "#009688" }}
                  onClick={(e) => {
                    handleSetTypeOperation(
                      cart.totalNet,
                      cart.items.length === 1 ? [cart.items[0]] : refundedItems
                    );
                  }}
                >
                  Echange / Retour
                </button>
              ) : null}
              {!hidePaymentButton && reduction === 0 ? (
                <>
                  <Button
                    variant="success"
                    className={"mb-2" + btnClass}
                    style={{
                      width: "100%",
                      background: isLoading ? "#ddd" : "#009688",
                    }}
                    onClick={() => {
                      handleConfirmOrder(cart, "En attente");
                    }}
                    disabled={cart.orderConfirmed}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                        Loading...
                      </>
                    ) : (
                      t("put_on_hold")
                    )}
                  </Button>
                  {parametrage && !parametrage.barcodeOnly ? (
                    <div
                      className="widget-calendar-lists-scroll"
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="success"
                        className="mb-2"
                        style={{
                          width: "100%",
                          background: isLoading ? "#ddd" : "#009688",
                        }}
                        onClick={() => {
                          handleConfirmOrder(cart, "Nouvelle");
                        }}
                        disabled={cart.orderConfirmed}
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="mr-2"
                            />
                            Loading...
                          </>
                        ) : (
                          t("confirm")
                        )}
                      </Button>
                      <button
                        className="btn btn-success mb-2"
                        style={{ width: "100%", background: "#009688" }}
                        onClick={(e) => {
                          // handleConfirmOrder(cart, "Nouvelle");
                          setVisible(true);
                        }}
                      >
                        {t("pay")}
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            {cart.items.map((item: any) => (
              <div className="widget-profile" key={item.article._id}>
                <div className={"w-profile-view" + boxClass}>
                  <div
                    className="col-6"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setSelectedArticles(item.article);
                      console.log("pressed");
                      setShowModalStock(true);
                    }}
                  >
                    <div
                      className="w-profile-content"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={
                          item.article.image && item.article.image[0]
                            ? process.env.REACT_APP_API_PUBLIC_URL +
                              "article/" +
                              item.article.image[0]
                            : "/assets/assets/img/no-image.jpg"
                        }
                        alt="admin-profile"
                        className="img-fluid"
                      />
                      <div>
                        <h6
                          style={{
                            fontSize:
                              parametrage && parametrage.barcodeOnly
                                ? "30px"
                                : "14px",
                            fontWeight: "lighter",
                            marginRight: "10px",
                          }}
                        >
                          {item.article.titre.fr}
                        </h6>
                        {item.article.caracteristiques.length ? (
                          <p>
                            {item.article.caracteristiques.map(
                              (caracteristique: any) => caracteristique.value
                            )}
                          </p>
                        ) : null}
                      </div>
                      {parametrage && parametrage.barcodeOnly ? (
                        <p className="card-price">
                          {item.prix_unitaire +
                            " " +
                            parametrage.defaultCurrency}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      alignItems: "center",
                      justifyContent: "right",
                      display: "flex",
                      padding: 0,
                    }}
                  >
                    <div
                      className="w-profile-links"
                      style={{
                        padding: "0",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className={"minus-button" + btnClass + bgClass}
                        onClick={() => {
                          if (item.quantity > 1) {
                            removeSingleProductFromCart(item.article);
                          } else {
                            removeFromCart(item.article);
                          }
                        }}
                        disabled={cart.orderConfirmed}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <span className={"w-p-link" + btnClass}>
                        <p>{item.quantity}</p>
                      </span>
                      <button
                        className={"minus-button" + btnClass + bgClass}
                        onClick={() => {
                          console.log(typeof item.quantity);
                          addSingleProductToCart(item.article);
                        }}
                        disabled={cart.orderConfirmed}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                      <span
                        className={"w-p-link" + btnClass}
                        style={{ width: "auto", justifyContent: "center" }}
                      >
                        <p>
                          {item.prixNet.toFixed(3) +
                            " " +
                            parametrage.defaultCurrency}
                        </p>
                      </span>
                      <button
                        className={"delete-button" + btnClass}
                        onClick={() => removeFromCart(item.article)}
                        disabled={cart.orderConfirmed}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="cart-footer">
              <button
                className={"btn btn-success mb-2" + btnClass}
                style={{ width: "100%", background: "#009688" }}
                onClick={(e) => {
                  handleSetTypeOperation(
                    cart.totalNet,
                    cart.items.length === 1 ? [cart.items[0]] : refundedItems
                  );
                }}
              >
                Echange / Retour
              </button>
              {!hidePaymentButton && reduction === 0 ? (
                <>
                  <Button
                    variant="success"
                    className={"mb-2" + btnClass}
                    style={{
                      width: "100%",
                      background: isLoading ? "#ddd" : "#009688",
                    }}
                    onClick={() => {
                      handleConfirmOrder(cart, "En attente");
                    }}
                    disabled={cart.orderConfirmed}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                        Loading...
                      </>
                    ) : (
                      t("put_on_hold")
                    )}
                  </Button>
                  {parametrage && !parametrage.barcodeOnly ? (
                    <div
                      className="widget-calendar-lists-scroll"
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="success"
                        className="mb-2"
                        style={{
                          width: "100%",
                          background: isLoading ? "#ddd" : "#009688",
                        }}
                        onClick={() => {
                          handleConfirmOrder(cart, "Nouvelle");
                        }}
                        disabled={cart.orderConfirmed}
                      >
                        {isLoading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="mr-2"
                            />
                            Loading...
                          </>
                        ) : (
                          t("confirm")
                        )}
                      </Button>
                      <button
                        className="btn btn-success mb-2"
                        style={{ width: "100%", background: "#009688" }}
                        onClick={(e) => {
                          // handleConfirmOrder(cart, "Nouvelle");
                          setVisible(true);
                        }}
                      >
                        {t("pay")}
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </>
        )
      ) : (
        <div className="w-profile-view" style={{ padding: "12px" }}>
          <div className="w-profile-content">
            <div>
              <p>{t("your_cart_is_empty")}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Cart;
