import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListProductCaracteristiques,
  findProductsCaracteristiquesApiBySociete,
} from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import MatriceType from "../../../_App/Types/Entites/MatriceType";
import Matrice from "../Products/ProductsForm/Matrice";

export default function CreationMatrice({ t }: any) {
  const societe = useAppSelector(societeId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (societe) dispatch(findProductsCaracteristiquesApiBySociete(societe));
  }, [dispatch, societe]);

  const list_PCaracteristiques = useAppSelector(ListProductCaracteristiques);
  const [list_caracteristiques, setlist_caracteristiques] = useState<any[]>([]);
  const [ShowModalMatrice, setShowModalMatrice] = useState(false);
  const matricedata: MatriceType = {
    societe: "",
    dimension1: "",
    dimension2: "",
    dimension3: "",
    list_dimension1: [],
    list_dimension2: [],
    list_dimension3: [],
    checked: [],
    name_matrice: "",
  };
  const [matriceselected, setmatriceselected] = useState(matricedata);

  const onselectcaracteristique = (item: any) => {
    // check item is checked or not
    const checked = list_caracteristiques.find(
      (element: any) => element === item._id
    );
    if (checked) {
      //remove
      const newcarateristique = list_caracteristiques.filter(
        (element: any) => element !== item._id
      );
      setlist_caracteristiques(newcarateristique);
    } else {
      if (list_caracteristiques.length === 3) {
        ToastWarning("la matrice maximum a 3 dimension ");
      } else {
        setlist_caracteristiques((prev: any) => [...prev, item._id]);
        /*    const listmatriceselected = listmatrice.filter(
              (item) =>
                item.dimension1 === list_caracteristiques[0] ||
                item.dimension2 === list_caracteristiques[1] ||
                item?.dimension3 === list_caracteristiques[2]
            );
            setlist_filtermatrice(listmatriceselected);*/
      }
    }
  };

  const showmatrice = () => {
    if (list_caracteristiques.length < 2) {
      ToastWarning("matrice doit au moin comprends 2 dimmension");
    } else {
      const matricedata: MatriceType = {
        societe: "",
        dimension1: "",
        dimension2: "",
        dimension3: "",
        list_dimension1: [],
        list_dimension2: [],
        list_dimension3: [],
        checked: [],
        name_matrice: "",
      };
      setmatriceselected(matricedata);
      setShowModalMatrice(true);
    }
  };
  return (
    <div className="col-lg-12  mt-5   bg-white " style={{padding:'20px'}}>
      {" "}
      <div>
        <div className="col-md-12">
          {[...list_PCaracteristiques]
            .reduce((rows: any[], item, index) => {
              if (index % 6 === 0) {
                rows.push([]);
              }
              rows[rows.length - 1].push(item);
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div className="row" key={`row-${rowIndex}`}>
                {row.map((colItem: any, colIndex: any) => (
                  <div className="col-md-2" key={colIndex}>
                    <button
                      key={colIndex}
                      disabled={
                        !list_caracteristiques.find(
                          (element) => element === colItem._id
                        ) && list_caracteristiques.length === 3
                      }
                      className={`btn-form-sm ${
                        list_caracteristiques.includes(colItem._id)
                          ? "active"
                          : ""
                      }`}
                      type="button"
                      style={{ width: "200px" }}
                      onClick={() => onselectcaracteristique(colItem)}
                    >
                      {colItem.libelle}
                    </button>
                  </div>
                ))}
              </div>
            ))}
        </div>

        <span className="c-primary pointer" onClick={showmatrice}>
          <i className="fas fa-plus-circle fa-lg"></i> {t("créer matrice")}
        </span>
      </div>
      <Matrice
        setmatriceselected={setmatriceselected}
        ShowModal={ShowModalMatrice}
        setShowModal={setShowModalMatrice}
        caracteristique={list_caracteristiques}
        matrice={matriceselected}
      />
    </div>
  );
}
