import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReceptionTable from "../../Components/Reception/ReceptionTable";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../_App/Redux/hooks";
import ReceptionForm from "../../Components/Reception/ReceptionForm";
import TicketArticle from "../../Components/TicketArticle/TicketArticle";

export default function ReceptionPage() {
  const { t } = useTranslation();
  const societe_id = useAppSelector(societeId);
  const [showModalReception, setshowReception] = useState(false);
  const [reception, setreception] = useState(null);
  const [showModelTicket, setshowModelTicket] = useState(false);
  const [listTicket, setlistTicket] = useState([]);
  useEffect(() => {
    if (!showModalReception) {
      setreception(null);
    }
  }, [showModalReception]);
  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-end mb-3">
        <button
          onClick={() => {
            setshowReception(true);
          }}
          className="btn btn-primary-app"
        >
          {t("Ajouter Reception")}
        </button>{" "}
      </div>
      <ReceptionForm
        ShowModal={showModalReception}
        setShowModal={setshowReception}
        reception={reception}
        setshowModelTicket={setshowModelTicket}
        showModelTicket={showModelTicket}
        setlistTicket={setlistTicket}
      />
      <ReceptionTable
        t={t}
        societeId={societe_id}
        setshowReception={setshowReception}
        setreception={setreception}
        setshowModelTicket={setshowModelTicket}
        showModelTicket={showModelTicket}
        setlistTicket={setlistTicket}
        listTicket={listTicket}
      />
      <TicketArticle
        t={t}
        ShowModal={showModelTicket}
        setShowModal={setshowModelTicket}
        tickets={listTicket}
        stateQte={false}
      />
    </div>
  );
}
