export const calculTTc = (prixht: number, tva: number) => {
  return prixht + (prixht * tva) / 100;
};

export const calculHt = (prixttc: number, tva: number) => {
  return (100 * prixttc) / (100 + tva);
};

export const calculTTC_Remise = (
  prix_ttc: number,
  quantite: number,
  remise: number
) => {
  const totalBeforeRemise = quantite * prix_ttc;
  const remiseAmount = (totalBeforeRemise * remise) / 100;
  const totalAfterRemise = totalBeforeRemise - remiseAmount;

  const round = (value: number, decimalPlaces: number): number => {
    const factor = 10 ** decimalPlaces;
    return Math.round(value * factor) / factor;
  };

  return round(totalAfterRemise, 3);
};

export const formadate = (date: any) => {
  const inputDate = new Date(date);

  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const formatdate = (date: any) => {
  const inputDate = new Date(date);

  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
export const generateRandomEAN = (length: any) => {
  const randomDigits = Array.from({ length }, () =>
    Math.floor(Math.random() * 10)
  ).join("");
  return calculateEANCheckDigit(randomDigits, length);
};

const calculateEANCheckDigit = (digits: any, length: any) => {
  if (digits.length !== length) {
    throw new Error(`Invalid number of digits for EAN-${length}`);
  }

  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    const digit = parseInt(digits[i], 10);
    sum += i % 2 === 0 ? digit : digit * 3;
  }

  const checkDigit = (10 - (sum % 10)) % 10;

  return `${digits}${checkDigit}`;
};
export const areArraysEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
export const handleKeyPress = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }

  // Ensure that only one minus sign is allowed at the beginning
  if (charCode === 45 && inputText.indexOf("-") !== -1) {
    event.preventDefault();
  }
};
export const formatDateAndTime = (timestamp: Date) => {
  const dateObject = new Date(timestamp);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");

  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  const seconds = dateObject.getSeconds().toString().padStart(2, "0");

  const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateAndTime;
};
export const handleKeyPress_number = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }
};
export const formatTime = ({ date }: { date: string }) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Date(date).toLocaleTimeString(undefined, options);
};
export const ajouterDureeToDate = (dateString: any, dureeEnJours: any) => {
  var date = new Date(dateString);
  date.setDate(date.getDate() + dureeEnJours);
  return date.toISOString();
};

export const calculateDiscountedPrice = (
  originalPrice: number,
  discountPercentage: number
) => {
  let discountAmount = (originalPrice * discountPercentage) / 100;
  let discountedPrice = originalPrice - discountAmount;
  return discountedPrice;
};

export const calculateRemise = (item: any) => {
  let montantRemiseArticle = 0;
  let montantRemiseClient = 0;
  let totalRemise = 0;

  const finalQte = item.quantity;
  let netRemise = finalQte * item.prix_unitaire;

  if (item.remise > 0) {
    montantRemiseArticle = (finalQte * item.prix_unitaire * item.remise) / 100;
    netRemise -= montantRemiseArticle; // Apply article discount
  }

  if (item.remiseClient > 0) {
    montantRemiseClient = (netRemise * item.remiseClient) / 100;
  }

  totalRemise = montantRemiseArticle + montantRemiseClient;
  return { totalRemise, montantRemiseArticle, montantRemiseClient };
};

export const maxRemise = (list: any) => {
  const maxRemise = list?.reduce((max: any, remise: any) => {
    return remise?.percentage > max?.percentage ? remise : max;
  }, list[0]);

  return maxRemise?.percentage;
};

export const sumGratuiteQuantity = (list: any) => {
  if (Array.isArray(list)) {
    const sum = list.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue.quantity,
      0
    );
    return sum;
  }
};

export const  formatFloat=(number:any) =>{
  if(number){
   
      if (typeof number === 'string') {
          number = parseFloat(number);
      }
      
      let formattedNumber = number.toString();
      if (formattedNumber.indexOf('.') !== -1) {
          formattedNumber = parseFloat(number).toFixed(3);
      } else {
          formattedNumber += '.000';
      }
  
      return formattedNumber;
  } 
  
}